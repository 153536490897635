import { Injectable } from '@angular/core';
import { AssetRequestService } from '../../../service/rest/asset-request.service';
import { PouchDbAssetRequestCenterAdapter } from '../../../service/pouchdb/instance/asset-request-center/pouchdb-asset-request-center-adapter.service';
import { PageSettingsModel } from '../../../model/structure/AbstractListModel';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { BackendProcessQueueActionEnum } from './backend-process-queue.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { TicketStateAction } from '../ticket/ticket.actions';
import { AssetRequestUtilService } from '../../../service/util/asset-request-util.service';

@Injectable()
export class BackendProcessQueueEffects {
	// detail

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackendProcessQueueActionEnum.LOAD),
			mergeMap((action: PageSettingsModel) => from(this.exportTicket(action))),
			map(backendProcessQueue => TicketStateAction.update(backendProcessQueue)),
			catchError(err => of({ err, type: BackendProcessQueueActionEnum.ERROR }))
		)
	);

	constructor(
		private actions$: Actions,
		private assetRequestService: AssetRequestService,
		private pouchDbAssetRequestAdapter: PouchDbAssetRequestCenterAdapter,
		private assetRequestUtilService: AssetRequestUtilService
	) {}

	exportTicket(pageSettings: PageSettingsModel): Promise<any> {
		const pageSettingsRemap: PageSettingsModel = JSON.parse(JSON.stringify(pageSettings));

		pageSettingsRemap.filters.status_list = this.assetRequestUtilService.returnStatusValueNumberList(
			pageSettingsRemap.filters.status_list
		);

		const selector: PouchDB.Find.Selector = this.pouchDbAssetRequestAdapter.assetRequestCenterPouch.getFilterSelector(
			pageSettingsRemap
		);
		const sort: {
			[key: string]: 'asc' | 'desc';
		}[] = this.pouchDbAssetRequestAdapter.assetRequestCenterPouch.getSortSelector(pageSettingsRemap.sort);

		// console.log('selector: ', selector);
		// console.log('sort: ', sort);

		return this.assetRequestService.downloadExportTicket(
			{ selector, sort },
			{ type: 'b2b-asset-request-center-download-ticket' },
			res => {
				console.log(res);
			},
			err => {
				console.log(err);
			}
		);
	}
}
