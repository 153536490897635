import { PageEvent } from "@angular/material/paginator";
import { ITdDataTableSortChangeEvent } from '@covalent/core/data-table';
import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';

export abstract class AbstractListModel<D> {
	pagination?: Pagination;
	filters?: Filters;
	sort?: any[];
	data?: D;
}

export class Pagination {
	page_current: number;
	page_size: number;
	total_element?: number;
}

export class Filters {
	_id?: string;
	text?: string;
	types?: string[];
	order_states?: string[];
	company_states?: string[];
	date?: string[];
	due_date?: string[];
	tot?: string[];
	business_name?: string;
	commercialAreas?: string[];
	years?: number[];
	brandList?: string[];
	brandApprovationStatusList?: string[];
	status?: string;
	company?: string;
	status_list?: any[];
	asset_type?: number;
	urgent?: boolean;
	context_code?: ContextApplicationItemCodeEnum;
	lineList?: string[];
	date_request?: any; // SatDatepickerRangeValue<number>;
	date_due?: any; // SatDatepickerRangeValue<number>;
	exclude_code?: (string | number)[];

	// account balance
	date_document?: any; // SatDatepickerRangeValue<number>;
	date_expiration?: any; // SatDatepickerRangeValue<number>;

	// local search
	localSearchText?: {
		value: string,
		key_list: string[]
	};
}

export interface Sort {
	sort_type?: string;
	sort_direction?: string;
}

export interface PageSettingsModel {
	pageName?: string;
	pagination?: Pagination;
	filters?: Filters;
	sort?: any[];
}

export abstract class AbstractQueryListModel<D> {
	query?: PageSettingsModel;
	data?: D;
}

export interface ListPageBaseApplyFilterParam {
	pagination: PageEvent;
	sort?: ITdDataTableSortChangeEvent;
	text?: string;
}

export interface ListPageBaseLocalPagination extends PageEvent {
	totalFilteredItemCount: number;
}

export interface ListPageModel<D> {
	data: D[];
	dataSubset: D[];
	pageName: string;
	filters: Filters;
	pagination: ListPageBaseLocalPagination;
	sort: ITdDataTableSortChangeEvent;
}
