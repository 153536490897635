import { Routes } from '@angular/router';

import { FramePageComponent } from '../frame/admin/admin.component';
import { NotFoundComponent } from '../page/not-found/not-found.component';
import { AuthTokenGuard } from '../service/guard/auth-token.guard';

export const routes: Routes = [
	{
		path: 'authentication',
		children: [
		]
	},
	{
		path: '',
		canActivate: [AuthTokenGuard],
		component: FramePageComponent,
		children: []
	},
	{
		path: '**',
		canActivate: [AuthTokenGuard],
		component: NotFoundComponent,
		children: []
	}
];
