<breadcrumb-project-instance
	*ngIf="platformType == 'backoffice'"
	[title]="chat.chat?.name"
	[subtitle]="'Home / Chat / ' + chat.chat?.name"
	[backButton]="true"
	[backButtonRoute]="['backoffice','chat']"
>
</breadcrumb-project-instance>
<app-chat-thread
	[sender]="sender"
	[chat]="chat.chat"
	[threadHeight]="
		platformType == 'backoffice' ? mediaReplayService.deviceH - 110 - 82 - 16 : mediaReplayService.deviceH - 126
	"
	(onReachTop)="loadChatPage(chat.chat.thread.pagination.page_current + 1)"
	(onSendMessage)="onChatSendMessage($event)"
></app-chat-thread>
