import { Injectable } from '@angular/core';

// model
import { UserDetailModel, ContextCodeAssociationItem, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

@Injectable({
	providedIn: 'root'
})
export class PermissionUtilService {

	returnUserBackofficeContextCodeAssociated(
		user: UserDetailModel,
		context_application_item_code: ContextApplicationItemCodeEnum,
		organizationList: OrganizationPouchModel[]
	): string[] {
		const context_code_list: string[] = [];
		const user_context_code_association: ContextCodeAssociationItem =
			user.current_permission.context_code.context_code_association_list
			.find(i => i.code === context_application_item_code);
		if (
			user_context_code_association &&
			user_context_code_association.context_code_list &&
			user_context_code_association.context_code_list.length > 0
		) {
			for (let i = 0; i < user_context_code_association.context_code_list.length; i++) {
				const organization =
					organizationList.find(o => o.code_item === user_context_code_association.context_code_list[i].code);
				if (organization) {
					context_code_list.push(organization.code_erp);
				}
			}
		}
		return context_code_list;
	}

}
