import { createAction, props } from '@ngrx/store';
// model
import { PageSettingsModel } from '../../model/structure/AbstractListModel';

export const update = createAction(
  '[Page Settings] Update',
  props<PageSettingsModel>()
);

export const reset = createAction(
  '[Page Settings] Reset'
);
