<form *ngIf="form" #resetTag="ngForm" [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<div class="row" *ngIf="formHasNumberField">
		<div class="col-6"></div>
		<div class="col-6">
			<h4>Amount converted to EUR ({{ currency.eurRate }})</h4>
		</div>
	</div>
	<div class="row" *ngFor="let field of fields">
		<div class="col-6">
			<mat-form-field class="outlined" [style.display]="field.type == 'file' ? 'none' : 'block'">
				<input
					matInput
					type="text"
					autocomplete="off"
					[formControlName]="field.name"
					placeholder="{{ utilService.formatPlaceholder(field.name) | titlecase }}"
					(input)="onType(field)"
					[ngClass]="{ 'text-right': field.type == 'number' }"
				/>
				<mat-hint *ngIf="field.note">{{field.note}}</mat-hint>
			</mat-form-field>
			<ng-container *ngIf="field.type == 'file'">
				<app-attachment-resolver
					[multiple]="false"
					[disabled]="disabled"
					[uploadButtonLabel]="'Upload File'"
					(fileEmitter)="onFileChange(field, $event)"
				></app-attachment-resolver>
				<mat-hint *ngIf="field.note">{{field.note}}</mat-hint>
			</ng-container>
		</div>
		<div class="col-6">
			<mat-form-field *ngIf="field.type == 'number'" class="outlined">
				<input
					matInput
					disabled
					type="text"
					[ngModel]="eurConvertedValues[field.name]"
					[ngModelOptions]="{ standalone: true }"
					class="text-right"
				/>
			</mat-form-field>
			<div class="files-download-container" *ngIf="field.type == 'file'">
				<button
					*ngIf="field.file_template"
					mat-raised-button
					class="APPROVED"
					(click)="onDownloadFileTemplateButtonClick()"
					type="button"
				>
					Download Template
				</button>
				<mat-list *ngIf="field.file_latest" class="file-latest-list" dense>
					<div mat-subheader>See your last updated file:</div>
					<mat-list-item>
						<mat-icon mat-list-icon [svgIcon]="iconUtilSerice.getFileIcon(utilService.getFileExtension(field.file_latest.name))"></mat-icon>
						<a
							mat-line
							class="opacity-50"
							href="javascript:void(0)"
							(click)="onDownloadLatest.emit(field.file_latest.link)"
							[matTooltip]="field.file_latest.name"
						>
							{{
								field.file_latest.name.length > 20
									? (utilService.formatPlaceholder(field.file_latest.name.substring(0, 25) + '...')
									| titlecase)
									: (utilService.formatPlaceholder(field.file_latest.name) | titlecase)
							}}
						</a>
					</mat-list-item>
				</mat-list>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">SUBMIT</button>
		</div>
	</div>
</form>
