<breadcrumb-project-instance
	[title]="'@' + survey.client_code"
	[subtitle]="
		('Survey' | titlecase) +
		' / ' +
		(surveyConf ? (utilService.formatPlaceholder(surveyConf.type) | titlecase) : 'All survey')
	"
	[backButton]="platformType == 'backoffice'"
	[backButtonRoute]="[
		'backoffice',
		'survey-center',
		surveyConf ? (utilService.formatPlaceholder(surveyConf.type) | titlecase) : 'All survey'
	]"
></breadcrumb-project-instance>

<div class="container">
	<div class="row">
		<div class="col-lg-8">
			<ng-scrollbar
				[autoWidthDisabled]="true"
				[autoHeightDisabled]="false"
				[style.max-height.px]="mediaReplayService.deviceH - 34"
			>
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{
								surveyConf ? (utilService.formatPlaceholder(surveyConf.type) | titlecase) : 'All survey'
							}}
							| @{{ survey.client_code }}
							{{ survey.year || survey.commercial_area ? ' | ' : '' }}
							{{ survey.year && survey.commercial_area ? survey.year + ' | ' : '' }}
							{{ survey.year && !survey.commercial_area ? survey.year : '' }}
							{{ survey.commercial_area ? survey.commercial_area?.descrizioneEstesa : '' }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<div class="row">
							<div class="col-md-4">
								<brand-list
									[brandList]="survey.brand_list"
									(brandSelectedChange)="brandSelectedChange($event)"
								></brand-list>
							</div>
							<div class="col-md-8">
								<frontoffice
									*ngIf="
										this.user.current_permission.context_application ===
										ContextApplicationItemCodeEnum.B2B
									"
									[survey]="survey"
									(onSurveyCurrencyChange)="updateSurvey({ currency_name: $event })"
									(onSurveyFormChange)="dialogUpdateSurveyFormConfirm($event)"
									(onDownloadTemplate)="downloadTemplateFile($event)"
									(onDownloadLatest)="onDownloadLatest($event)"
								></frontoffice>
								<backoffice
									*ngIf="
										(user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE) ||
										(user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN)
									"
									[survey]="survey"
									(onSurveyValidationChange)="dialogUpdateSurveyValidationConfirm($event)"
									(onDownloadLatest)="onDownloadLatest($event)"
								></backoffice>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</ng-scrollbar>
		</div>
		<div class="col-lg-4">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row>
						Comments
					</mat-toolbar-row>
				</mat-toolbar>
				<mat-card-content>
					<app-chat-thread
						[chat]="chat"
						[disabled]="disabled"
						[threadHeight]="mediaReplayService.deviceH - 110 - 64 - 18 - 18 - 16 - 16 - 16"
						(onSendMessage)="updateSurveyValidationThread($event)"
					></app-chat-thread>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
