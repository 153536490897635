import { Injectable } from "@angular/core";
import { BaseStateModel, BaseState, RestBaseMessageError } from "@saep-ict/angular-core";
import { ChatPdfExportService } from "./chat-pdf.service";


export interface ChatPdfDataModel {
	status?: string;
	message?: string;
	error?: boolean;
	data?: {
		messages?: ChatMessage[];
   };
}
export interface ChatMessage {
        username?: string;
        msg?: string;
        ts?: string;
}


@Injectable({
	providedIn: 'root'
})

export class ChatPdfUtilService {
    constructor( public chatPdfService: ChatPdfExportService ) {}

    // Creo Promise per chiamata API al servizio nest per ricevere payload 
    async getChatPdf(room_name: string): Promise<BaseStateModel<ChatPdfDataModel>> {
        return this.chatPdfService.getChatPdf({room_name: room_name})
        .then(res => {
            console.log(res.data)
            return new BaseState(res.data);
            })
            .catch((err: RestBaseMessageError) => {
                throw new Error(err.body.detail);
            });
    }

}

