<div class="align-items-center">
  <div class="wrapper_page">
      <mat-card>
          <div class="wrapper_header">

              <div class="header_spinotto">
                  <img class="logo" src="../../../../assets/img/logo-spin8-generic-icon.svg">
              </div>
              <div class="header_cliente">
                  <img class="logo" src="../../../../assets/img/logo/logo.png">
              </div>

          </div>
          <mat-card-content>
            <div id="notfound">
              <div class="notfound">
                <div class="notfound-404"></div>
                <h1>404</h1>
                <h2>Oops! Page Not Be Found</h2>
                <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                <a (click)="goToHome()">Back to homepage</a>
              </div>
            </div>
          </mat-card-content>
      </mat-card>
  </div>
</div>