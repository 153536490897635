import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Resource, ResourceParams } from '@ngx-resource/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import { TokenPayload } from '../../model/login.model';
import { Agente, LinkCodeModel } from '../../model/user.model';
import { LoginContextCodeCompanyStateAction } from '../../state/common/login/context-code/b2b/login-context-code-company.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { LoaderService } from '../structure/loader.service';

@Injectable()
@ResourceParams({
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
	}
})
export class AuthService extends Resource {
	user: Agente = {
		clienti: ['1_000007', '1_000010', '1_000011'],
		codice: '385',
		descrizione: 'S.I.CON. SNC DI RANERI F.& C.'
	};

	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;

	@LocalStorage('authenticationToken')
	authenticationToken: string;

	@LocalStorage('clientCode')
	clientCode: string;

	@LocalStorage('surveyType')
	surveyType: string;

	@LocalStorage('link_code')
	private link_code: LinkCodeModel;

	@LocalStorage('springAuthenticationToken')
	springAuthenticationToken: string;

	private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
	tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

	constructor(
		private router: Router,
		private localStorageService: LocalStorageService,
		public translate: TranslateService,
		public snackBar: MatSnackBar,
		protected loaderService: LoaderService,
		private appConfig: CustomerAppConfig,
		private store: Store<any>
	) {
		super();
		this.appConfig.config$.subscribe(config => {
			if (config && config.urlConfig) {
				super.$setPathPrefix(config.urlConfig.api);
			}
		});
	}

	logout() {
		// pulizia localstorage
		this.localStorageService.clear('link_code');
		this.localStorageService.clear('payload');
		this.localStorageService.clear('authenticationToken');
		this.localStorageService.clear('springAuthenticationToken');

		// pulizia ngrx
		this.store.dispatch(UserStateAction.reset());
		this.store.dispatch(LoginContextCodeCompanyStateAction.reset());
		
		this.deleteRouting();
	}

	get tokenPayload(): TokenPayload {
		if (!this._tokenPayload) {
			return undefined;
		}
		return new TokenPayload(this._tokenPayload);
	}

	set tokenPayload(tk: TokenPayload) {
		if (tk) {
			this._tokenPayload = new TokenPayload(tk);
		} else {
			this._tokenPayload = null;
		}
		this.tokenPayloadChange.next(this._tokenPayload);
	}

	deleteRouting(){
		const routes = this.router.config;
		routes[1].children = [];
		this.router.resetConfig(routes);
		this.router.navigate(['/authentication/login']);
	}
}
