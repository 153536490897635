<div class="row">
  <div class="col-6 align-self-center">
    {{survey.currency_visible ? 'Currency: ' + survey.currency?.isoCode : ''}}
  </div>
  <div class="col-6 chips-mini-container">
    <div *ngIf="survey.validation" class="chips-mini">
      <span
        [ngClass]="brandSelected.status"
        matTooltip="{{brandSelected.brand?.nome_brand}} status: {{brandSelected.status | titlecase}}"
      >{{brandSelected.brand?.nome_brand_ridotto}}</span>
    </div>
  </div>
</div>
<survey-checker
  [currency]="survey.currency"
  [brand]="brandSelected"
  [enableValidation]="survey.validation"
  (onSaveSurveyValidation)="onSaveSurveyValidation($event)"
  (onDownloadLatest)="onDownloadLatest.emit($event)"
></survey-checker>