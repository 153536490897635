import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { AssetRequestCategory } from '../../../model/asset-request/asset-request-category-list.model';
import { TicketAttachmentListManagerConfigurationModel, TicketBaseModel, TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { UtilService } from '../../../service/util/util.service';
import * as fromState from '../../../state/index';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '../../../model/state/base-state.model';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { TicketStateAction } from '../../../state/asset-request/ticket/ticket.actions';
import { RestAttachmentListManagerService } from '@saep-ict/angular-core';
import * as RequestCenterAttachmentListManagerBase from '../../../constant/request-center/request-center-attachment-list-manager-configuration-base.constant';

@Directive({
	selector: '[requestCenterDialogBase]'
})
export class RequestCenterDialogBaseDirective<DIALOG, ASSET extends TicketModel.AssetItem.Base> {
	userType: RequestCenterEnum.User.type;
	requestType: RequestCenterEnum.type;
	asset: ASSET;
	ticket: TicketStateModel<ASSET>;
	assetIndex: number;
	form: FormGroup;
	ticketStatus;
	ticketStatusEnum = RequestCenterEnum.Status.base;
	requestCenterEnum = RequestCenterEnum;
	lineList: AssetRequestCategory[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
	user: UserDetailModel;

	attachmentListManagerConfiguration: TicketAttachmentListManagerConfigurationModel = {
		upload_frontoffice: null,
		upload_agency: null,
		upload_euroitalia: null
	};

	attachmentTypeList: RequestCenterEnum.Asset.AttachmentType[];

	// metodi in override nelle classi figlie
	prepareSaveForm() {}

	constructor(
		public dialogRef: MatDialogRef<DIALOG>,
		public utilService: UtilService,
		public store: Store,
		public utilStoreService: StoreUtilService,
		public restAttachmentListManagerService: RestAttachmentListManagerService
	) {
		this.loadStaticData();
	}

	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
		});
	}

	assignDataToDialog(data) {
		this.userType = data.context;
		this.asset =
			data.asset ?
			data.asset.row :
			{ code_item: this.utilService.guid() };
		this.ticket = data.ticket;
		this.assetIndex = data.asset ? data.asset.index : null;
		this.ticketStatus = data.ticketStatus;
	}

	// form
	compareItemCode(a, b): boolean {
		return a && b ? a.code === b.code : a === b;
	}

	compareItemId(a: TicketBaseModel<any>, b: TicketBaseModel<any>): boolean {
		return a && b ? a.id === b.id : a === b;
	}

	onFormSubmit() {
		if (this.form.valid) {
			const asset = this.prepareSaveForm();
			this.dialogRef.close({
				asset: this.utilService.deleteEmptyProperties(asset),
				index: this.assetIndex
			});
		}
	}

	setFormAttachmentTypeList(asset: ASSET) {
		for (const attachmentType of this.attachmentTypeList) {
			asset[attachmentType] = this.asset[attachmentType];
		}
	}

	setAttachmentListManagerConfigurationBase() {
		for (const attachmentType of this.attachmentTypeList) {
			this.attachmentListManagerConfiguration[attachmentType] =
				_.cloneDeep(RequestCenterAttachmentListManagerBase.configuration);
			this.attachmentListManagerConfiguration[attachmentType].localListHandler.data =
				this.asset[attachmentType] ?
				this.asset[attachmentType] :
				[];
			this.attachmentListManagerConfiguration[attachmentType].pathQueryParam =
				`${this.requestType}/${this.ticket.code_item}/${this.asset.code_item}/${attachmentType}`;
			this.attachmentListManagerConfiguration[attachmentType].customHeaders = {
				'context-code': this.user.current_permission.context_code.code,
				'context-application': this.user.current_permission.context_application
			};
			this.attachmentListManagerConfiguration[attachmentType].upload.fileNameOnBucketCreate =
				(fileName): string => {
					return `${RequestCenterEnum.Asset.AttachmentNameOnBucketType[attachmentType]}_${fileName}`;
				};
		}
	}

	async attachmentListManagerChange(e: AttachedFile[], key: RequestCenterEnum.Asset.AttachmentType) {
		try {
			this.asset[key] = e;
			this.attachmentListManagerConfiguration[key].localListHandler.data = e;
			this.attachmentListManagerConfiguration = _.cloneDeep(this.attachmentListManagerConfiguration);
			if (this.ticket.asset_list && this.ticket.asset_list.length > 0) {
				const assetPreExisting: ASSET = this.ticket.asset_list.find(i => i.code_item === this.asset.code_item);
				if (assetPreExisting) {
					// l'asset esisteva già prima dell'apertura della dialog, le azioni di aggiunta/rimozione di allegati
					// devono essere dunque portate anche sul documento ticket in remoto e di conseguenza sulla schermata
					// di dettaglio. Questo previene eventuiali disallineamenti dovuti al click su `CANCEL`
					assetPreExisting[key] = e;
					this.store.dispatch(TicketStateAction.save(new BaseState(this.ticket)));
				}
			}
			if (this.asset[key].length === 0) {
				// in caso la lista di allegati risulti vuota viene eliminata la cartella del livello più annidato relativa
				// a `key`
				await this.restAttachmentListManagerService.delete(
					{ pathList: [`${this.requestType}/${this.ticket.code_item}/${this.asset.code_item}/${key}/`] },
					null,
					{ path: this.attachmentListManagerConfiguration[key].pathUrl }
				);
			}
		} catch(err) {
			throw new Error(err);
		}
	}

	async onCancelHandlaer() {
		try {
			if (
				!(
					this.ticket.asset_list &&
					this.ticket.asset_list.length > 0 &&
					this.ticket.asset_list.find(i => i.code_item === this.asset.code_item)
				)
			) {
				for (const key of Object.keys(this.attachmentListManagerConfiguration)) {
					if (this.attachmentListManagerConfiguration[key]) {
						await this.restAttachmentListManagerService.delete(
							{ pathList: [`${this.requestType}/${this.ticket.code_item}/${this.asset.code_item}/`] },
							null,
							{ path: this.attachmentListManagerConfiguration[key].pathUrl }
						);
					}
				}
			}
			this.dialogRef.close(false);
		} catch(err) {
			this.dialogRef.close(false);
			throw new Error(err);
		}
	}

	async onDeleteHandlaer() {
		try {
			for (const key of Object.keys(this.attachmentListManagerConfiguration)) {
				if (this.attachmentListManagerConfiguration[key]) {
					await this.restAttachmentListManagerService.delete(
						{ pathList: [`${this.requestType}/${this.ticket.code_item}/${this.asset.code_item}/`] },
						null,
						{ path: this.attachmentListManagerConfiguration[key].pathUrl }
					);
				}
			}
			this.dialogRef.close({ index: this.assetIndex });
		} catch(err) {
			this.dialogRef.close({ index: this.assetIndex });
			throw new Error(err);
		}
	}

}