import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb-project-instance',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() badge: string;
  @Input() options: boolean;
  @Input() backButton: boolean;
	@Input() backButtonRoute: string[] = [];

  constructor(
    public location: Location,
    private router: Router
  ) { }
  ngOnInit() { }

	onClickBackButton() {
		this.backButtonRoute.length ? this.router.navigate(this.backButtonRoute) : this.location.back();
	}
}
