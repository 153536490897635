<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<div class="col-md-12">
			<h1 class="mb-0" mat-dialog-title>{{ data.asset ? 'Modify Asset' : 'Add new Asset' }}</h1>
		</div>
	</div>
	<ng-scrollbar
		[autoWidthDisabled]="true"
		[autoHeightDisabled]="false"
		[style.max-height.px]="mediaReplayService.deviceH - 250"
	>
		<!-- <mat-dialog-content> -->

		<div class="container">
			<div class="row">
				<!-- Common features -->
				<div class="col-12">
					<h2 class="subtitle"><mat-icon>fullscreen</mat-icon>Common features</h2>
				</div>

				<!-- Asset Type -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Asset type</mat-label>
						<mat-select formControlName="type" [compareWith]="compareItemId">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('type').value"
							>
								{{
									utilService.formatPlaceholder(form.get('type').value.description)
										| titlecase
								}}
							</mat-option>
							<mat-option
								*ngFor="
									let item of requestCenterEnum.Asset.Type.AdvertisingMaterial | enumIterator
								"
								[value]="item"
							>
								{{ utilService.formatPlaceholder(item.description) | titlecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- version -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-select
							formControlName="version"
							placeholder="Asset Version"
							[compareWith]="compareItemId"
						>
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('version').value"
							>
								{{
									utilService.formatPlaceholder(form.get('version').value.description)
										| titlecase
								}}
							</mat-option>
							<ng-container *ngFor="let item of assetVersionEnum | enumIterator">
								<mat-option [value]="item" *ngIf="showAssetVersion(item)">
									{{ utilService.formatPlaceholder(item.description) | titlecase }}
								</mat-option>
							</ng-container>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Brand -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Brand</mat-label>
						<mat-select formControlName="brand" [compareWith]="compareItemCode">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('brand').value"
							>
								{{ form.get('brand').value.description }}
							</mat-option>
							<mat-option
								*ngFor="let brand of assetRequestUtilService.categoryList?.category_list"
								[value]="{ code: brand.code, description: brand.description }"
							>
								{{ brand.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Line -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Line</mat-label>
						<mat-select formControlName="line" [compareWith]="compareItemCode">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('brand').value"
							>
								{{ form.get('line').value?.description }}
							</mat-option>

							<mat-option
								*ngFor="let line of lineList"
								[value]="{ code: line.code, description: line.description }"
							>
								{{ line.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- GWP code -->
				<div
					class="col-md-6"
					*ngIf="form.get('line').value?.code === requestCenterEnum.Asset.LineCodeEnum.GWP"
				>
					<mat-form-field>
						<input matInput formControlName="gwp_code" placeholder="GWP code" />
					</mat-form-field>
				</div>

				<!-- Other features -->
				<div class="col-12">
					<h2 class="subtitle"><mat-icon>fullscreen_exit</mat-icon>Other features</h2>
				</div>

				<!-- cta -->
				<div
					class="col-12"
					*ngIf="fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER"
				>
					<mat-form-field>
						<input matInput formControlName="cta" placeholder="CTA or Brand Write-up text" />
					</mat-form-field>
				</div>

				<!-- resolution -->
				<!-- <div class="col-12">
					<mat-form-field class="w-100">
						<input
							matInput
							type="number"
							min="1"
							formControlName="resolution"
							placeholder="Resolution (dpi)"
						/>
					</mat-form-field>
				</div> -->

				<!-- File Extension -->
				<div
					class="col-sm-6"
					*ngIf="fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO"
				>
					<mat-form-field class="w-100">
						<mat-select
							formControlName="file_extension"
							placeholder="File Type"
							[compareWith]="compareItemId"
						>
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('file_extension').value"
							>
								{{ form.get('file_extension').value.description }}
							</mat-option>

							<mat-option
								*ngFor="let item of formatExtensionVideoEnum | enumIterator"
								[value]="item"
							>
								{{ item.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Duration -->
				<div
					class="col-sm-6"
					*ngIf="fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO"
				>
					<mat-form-field class="w-100">
						<mat-select formControlName="duration" placeholder="Duration (sec)">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('duration').value"
							>
								{{ form.get('duration').value }}
							</mat-option>

							<mat-option *ngFor="let item of durationList" [value]="item">
								{{ item }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Digital dimensions-->
				<ng-container
					*ngIf="
						fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO ||
						fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER ||
						fieldTypeValue ===
							requestCenterEnum.Asset.Type.AdvertisingMaterial.INSTORE_VISUAL_FOR_MONITOR
					"
				>
					<div class="col-md-12">
						<p class="mb-4 mt-8">Dimensions</p>
					</div>
					<!-- digital_dimension.width -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="digital_width"
								placeholder="Width (px)"
							/>
						</mat-form-field>
					</div>

					<!-- digital_dimension.height -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="digital_height"
								placeholder="Height (px)"
							/>
						</mat-form-field>
					</div>
				</ng-container>

				<!-- Section: Dimensions -->
				<ng-container
					*ngIf="
						fieldTypeValue ===
							requestCenterEnum.Asset.Type.AdvertisingMaterial.INSTORE_VISUAL_FOR_PRINT ||
						fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.MAGAZINE
					"
				>
					<!-- Measure -->
					<div class="col-md-12">
						<mat-form-field class="w-100">
							<mat-label>Measure</mat-label>
							<mat-select formControlName="measure">
								<mat-option value="millimeters">Millimeters</mat-option>
								<mat-option value="centimeters">Centimeters</mat-option>
								<mat-option value="inches">Inches</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-12">
						<p class="mb-4 mt-8">Visible Dimension (net)</p>
					</div>
					<!-- visible_width -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="dimension_visible_width"
								placeholder="Visible width"
							/>
						</mat-form-field>
					</div>

					<!-- visible_height -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="dimension_visible_height"
								placeholder="Visible height"
							/>
						</mat-form-field>
					</div>

					<!-- Total Dimension -->
					<div class="col-md-12">
						<p class="mb-4 mt-8">Total Dimension (gross)</p>
					</div>
					<!-- total_width -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="dimension_total_width"
								placeholder="Total width"
							/>
							<mat-error *ngIf="!form.get('dimension_total_width').valid"
								>Total width can't be smaller than visible width</mat-error
							>
						</mat-form-field>
					</div>

					<!-- dimension_total_height -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input
								matInput
								type="number"
								min="1"
								formControlName="dimension_total_height"
								placeholder="Total height"
							/>
							<mat-error *ngIf="!form.get('dimension_total_height').valid"
								>Total height can't be smaller than visible height</mat-error
							>
						</mat-form-field>
					</div>
				</ng-container>

				<!-- Section: Diffusion -->
				<ng-container>
					<div class="col-md-12">
						<p class="mb-4 mt-8">Diffusion</p>
					</div>

					<!-- date_release -->
					<div
						class="col-sm-6 col-md-6"
						*ngIf="
							fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.MAGAZINE ||
							fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO
						"
					>
						<mat-form-field class="w-100">
							<mat-label>Date Release</mat-label>
							<input
								matInput
								[matDatepicker]="date_release"
								formControlName="date_release"
								[min]="dateDueMin"
							/>
							<mat-datepicker-toggle matSuffix [for]="date_release"></mat-datepicker-toggle>
							<mat-datepicker #date_release></mat-datepicker>
						</mat-form-field>
					</div>

					<!-- video_diffusion_type -->
					<div
						class="col-sm-6 col-md-6"
						*ngIf="fieldTypeValue === requestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO"
					>
						<mat-form-field class="w-100">
							<mat-select
								formControlName="video_diffusion_type"
								placeholder="Video Diffusion Type"
								[compareWith]="compareItemId"
							>
								<mat-option
									*ngIf="
										!(
											ticketStatus === ticketStatusEnum.DRAFT ||
											ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
										)
									"
									[value]="form.get('video_diffusion_type').value"
								>
									{{ form.get('video_diffusion_type').value.description | titlecase }}
								</mat-option>
								<mat-option
									*ngFor="let item of assetTypeVideoDiffusionTypeEnum | enumIterator"
									[value]="item"
								>
									{{ item.description | titlecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<!-- country -->
					<div class="col-sm-6 col-md-6">
						<mat-form-field class="w-100">
							<input matInput formControlName="country" placeholder="Country" />
						</mat-form-field>
					</div>

					<!-- city -->
					<ng-container *ngFor="let field of fieldList">
						<div class="col-sm-6 col-md-6" *ngIf="field.key === 'city'">
							<mat-form-field class="w-100">
								<input matInput formControlName="city" [placeholder]="field.label" />
							</mat-form-field>
						</div>
					</ng-container>

					<!-- client -->
					<ng-container *ngFor="let field of fieldList">
						<div class="col-sm-6 col-md-6" *ngIf="field.key === 'client'">
							<mat-form-field class="w-100">
								<input matInput formControlName="client" [placeholder]="field.label" />
							</mat-form-field>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div class="row">
				<div class="col-12">
				<!-- note -->
				<ng-container
					*ngIf="
						ticketStatus == ticketStatusEnum.DRAFT ||
						ticketStatus == ticketStatusEnum.MORE_INFO_NEEDED ||
						asset.note
					"
				>
					<div class="col-md-12">
						<h2 class="subtitle mb-0"><mat-icon>comment</mat-icon> Comment</h2>
						<mat-form-field class="w-100">
							<mat-label>Leave a comment</mat-label>
							<textarea matInput formControlName="note" rows="2" cols="40"></textarea>
						</mat-form-field>
					</div>
				</ng-container>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<!-- Section: upload_frontoffice -->
					<h2 class="subtitle mb-0"><mat-icon>attach_file</mat-icon> Examples</h2>
					<ng-container
						*ngTemplateOutlet="
							attachmentList;
							context: {
								configuration: {
									key: requestCenterEnum.Asset.AttachmentType.FRONTOFFICE,
									attachmentListManager: attachmentListManagerConfiguration[
										requestCenterEnum.Asset.AttachmentType.FRONTOFFICE
									]
								}
							}
						"
					>
					</ng-container>
				</div>
				<div class="col-md-6">
					<!-- Section: upload_agency -->
					<ng-container
						*ngIf="
							ticketStatus === ticketStatusEnum.SENT_TO_CLIENT ||
							((ticketStatus === ticketStatusEnum.PENDING_AGENCY ||
								ticketStatus === ticketStatusEnum.PENDING_EUROITALIA ||
								ticketStatus === ticketStatusEnum.COMPLETED_AGENCY) &&
								userType !== requestCenterEnum.User.type.COMPANY)
						"
					>
						<h2 class="mb-0 mt-8 subtitle"><mat-icon>check_circle</mat-icon> Assets</h2>
						<ng-container
							*ngTemplateOutlet="
								attachmentList;
								context: {
									configuration: {
										key: requestCenterEnum.Asset.AttachmentType.AGENCY,
										attachmentListManager: attachmentListManagerConfiguration[
											requestCenterEnum.Asset.AttachmentType.AGENCY
										]
									}
								}
							"
						>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-scrollbar>
	<mat-dialog-actions align="end">
		<button
			mat-stroked-button
			(click)="onCancelHandlaer()"
			color="primary"
			type="button"
		>
			CANCEL
		</button>
		<button
			mat-raised-button
			type="submit"
			color="primary"
			*ngIf="
				((ticketStatus == ticketStatusEnum.DRAFT || ticketStatus == ticketStatusEnum.MORE_INFO_NEEDED) &&
					userType == requestCenterEnum.User.type.COMPANY) ||
				(ticketStatus == ticketStatusEnum.PENDING_AGENCY && userType === requestCenterEnum.User.type.AGENCY) ||
				(ticketStatus === ticketStatusEnum.PENDING_EUROITALIA &&
					userType == requestCenterEnum.User.type.EUROITALIA)
			"
			[disabled]="!form.valid"
		>
			SAVE
		</button>
		<button
			mat-raised-button
			*ngIf="
				(ticketStatus == ticketStatusEnum.DRAFT || ticketStatus === this.ticketStatusEnum.MORE_INFO_NEEDED) &&
				userType === requestCenterEnum.User.type.COMPANY &&
				(assetIndex || assetIndex === 0)
			"
			(click)="onDeleteHandlaer()"
			type="button"
			class="REJECTED"
		>
			<mat-icon>delete</mat-icon>
		</button>
	</mat-dialog-actions>
</form>
<!-- ng-template -->
<ng-template #attachmentList let-configuration="configuration">
	<attachment-list-manager
		[configuration]="configuration.attachmentListManager"
		(attachmentListchange)="attachmentListManagerChange($event, configuration.key)"
	>
	</attachment-list-manager>
</ng-template>