<breadcrumb-project-instance [title]="'Chat | Thread List'" [subtitle]="'Home / Chat'"> </breadcrumb-project-instance>
<div app-list-wrapper #listWrapper>
	<ng-container main>
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					<span class="flex-span"></span>
					<app-table-column-toggle [columns]="chatColumnService.columns" (emitter)="toggleColumn($event)">
					</app-table-column-toggle>
				</mat-toolbar-row>
			</mat-toolbar>
			<div *ngIf="chatList && chatList.data; else noDataFound">
				<td-data-table
					#dataTable
					[data]="chatList.data"
					[columns]="chatColumnService.columns"
					[clickable]="true"
					(rowClick)="goToDetail($event)"
				>
					<ng-template tdDataTableTemplate="type" let-row="row">
						<div class="contact-container">
							<ng-container *ngFor="let contact of row.chat.partecipant_list">
								<div *ngIf="contact.id != user.id" class="contact-item">
									<div class="avatar">
										<img
											[src]="
												contact.avatar
													? contact.avatar
													: '../../../../../../assets/img/placeholder/user.png'
											"
										/>
									</div>
									<p>
										{{ (contact.company.length > 5 ? contact.company.slice(0,5) : contact.company).join(' | ') }}
										<span *ngIf="contact.company.length > 5" [matTooltip]="contact.company.join(' | ')">| ...</span>
									</p>
									<p>
										<small>
											{{ contact.email }} {{ contact.first_name }} {{ contact.last_name }}
										</small>
									</p>
								</div>
								<span
									class="badge-new-message"
									matBadgeColor="warn"
									[matBadge]="
										contact.id == user.id && contact.last_access < row.chat.last_message ? '!' : ''
									"
								>
								</span>
							</ng-container>
							<span
								*ngIf="!utilService.isElementIncluded(row.chat.partecipant_list, 'id', user.id)"
								class="badge-new-message APPROVED"
								matBadgeColor="primary"
								[matBadge]="'!'"
							>
							</span>
						</div>
					</ng-template>
				</td-data-table>
			</div>
			<ng-template #noDataFound>
				<p>{{ 'general.noResults' | translate }}</p>
			</ng-template>
		</mat-card>
	</ng-container>
</div>
