import { NgModule } from '@angular/core';

// model
import { LoginContextCodeBackofficeStateReducer } from './login-context-code-backoffice.reducer';

// store
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        StoreModule.forFeature('login-context-code-backoffice', LoginContextCodeBackofficeStateReducer.reducer)
    ]
})
export class LoginContextCodeBackofficeStateModule{}
