<form *ngIf="formCurrency" [formGroup]="formCurrency" (ngSubmit)="onFormCurrencySubmit()">
	<div class="row">
		<div class="col-4">
			<mat-form-field *ngIf="survey.currency_visible" class="outlined">
				<mat-select formControlName="currency" placeholder="Select Currency">
					<mat-option *ngFor="let item of currenciesService.surveyCurrencies" [value]="item.isoCode">
						{{ item.isoCode }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-8 chips-mini-container align-self-center">
			<div *ngIf="survey.validation" class="chips-mini">
				<span
					[ngClass]="brandSelected.status"
					matTooltip="{{ brandSelected.brand?.nome_brand }} status: {{ brandSelected.status | titlecase }}"
					>{{ brandSelected.brand?.nome_brand_ridotto }}</span
				>
			</div>
		</div>
	</div>
</form>
<survey-form
	[currency]="survey.currency"
	[brand]="brandSelected"
	[disabled]="disabled"
	(onSaveSurveyForm)="onSaveSurveyForm($event)"
	(onDownloadTemplate)="onDownloadTemplate.emit($event)"
	(onDownloadLatest)="onDownloadLatest.emit($event)"
></survey-form>
