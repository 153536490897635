import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonItemModel } from '../../model/button-list.model';

@Component({
  selector: 'button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit {

  @Input() configuration: ButtonItemModel[];
  @Output() onButtonClick = new EventEmitter<ButtonItemModel>();

  constructor() { }

  ngOnInit() {
  }

}
