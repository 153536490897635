// TODO: normalizzare il BaseStateModel in modo che accetti data & query (in modo vada d'accordo col page-settings-tate)
import { Filters } from "../structure/AbstractListModel";

export interface BaseStateModel<T> {
	data?: T;
	type?: string;
	pagination?: Pagination;
	sort?: any[]; // TODO: normalizzare per utilizzo condiviso in couch & rest
	filters?: Filters;
	status?: number;
}

export interface Pagination {
	page_current: number;
	page_size: number;
	total_element?: number;
}

export class BaseState<T> {
	data: T;
	type?: string;
	pagination?: Pagination;
	sort?: { [key: string]: 'asc' | 'desc' }[];

	constructor(innerData?: T, pagination?: Pagination, sort?: { [key: string]: 'asc' | 'desc' }[]) {
		this.data = innerData ? innerData : null;
		this.pagination = pagination ? pagination : null;
		this.sort = sort ? sort : null;
	}
}
export class BaseStateSerializerModel<T> extends BaseState<T> {
	stateSlice: {
		type: string;
		key?: string;
	};
}