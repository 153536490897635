import { Route } from '@angular/router';
import { PermissionResolver } from '../service/resolver/permission.resolver';
import { CommercialAreasResolver } from '../service/resolver/commercial-areas.resolver';
import { BrandsResolver } from '../service/resolver/brands.resolver';
import { CurrenciesResolver } from '../service/resolver/currencies.resolver';
import { SurveyListComponent } from '../page/survey-center/survey-list/survey-list.component';
import { SurveyDetailComponent } from '../page/survey-center/survey-detail/survey-detail.component';
import { ChatComponent } from '../page/chat/chat.component';
import { ChatDetailComponent } from '../page/chat/chat-detail/chat-detail.component';
import { RequestCenterComponent } from '../page/asset-request/request-center/request-center.component';
import { RequestCenterDetailComponent } from '../page/asset-request/request-center/request-center-detail/request-center-detail.component';
import { PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import { ContextApplicationItemCodeEnum } from '../enum/context-application-item-code.enum';
import { ManageExternalUrlGuard } from '../service/guard/manage-external-url.guard';
import { AssetRequestDashboardComponent } from '../page/asset-request/asset-request-dashboard/asset-request-dashboard.component';
import { AssetRequestCategoryResolver } from '../service/resolver/asset-request-category.resolver';

export namespace BackofficeRouteList {
	export const routes: Route[] = [
		/**
		 * Per la struttura originale delle routes vedere il file src/app/app-routing.module.ts
		 * Di seguito è stato riportato solo il ramo relativo (priva della rotta padre che effettua il passaggio del token
		 * che forse converrebbe piazzare temporaneamente in base-routing)
		 *
		 * C'è un po' di disordine per la lettura del parametro clientCode, che probabilmente per una questione di privilegi
		 * necessari alle chiamate rest di base viene anche salvato in authService.clientCode mediante il PermissionResolver
		 * sicuramente da deprecare
		 *
		 * */
		{
			path: '',
			canActivate: [ManageExternalUrlGuard],
			data: {
				platform: '',
				externalPath: ContextApplicationItemCodeEnum.BACKOFFICE,
				permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
			},
			resolve: {
				permission: PermissionResolver
			},
			children: []
		},
		{
			path: 'backoffice',
			data: {
				platform: 'backoffice',
				permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
			},
			children: [
				{
					path: 'survey-center/:surveyType', // il BO atterra direttamente a questo livello di annidamento
					data: {
						permissionKey: [
							PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_ALL_SURVEY,
							PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING,
							PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET
						]
					},
					children: [
						{
							path: '',
							resolve: {
								areas: CommercialAreasResolver,
								brands: BrandsResolver,
								currencies: CurrenciesResolver
							},
							data: {
								permissionKey: [
									PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_ALL_SURVEY,
									PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING,
									PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET
								]
							},
							children: [
								{
									path: '',
									component: SurveyListComponent,
									pathMatch: 'full',
									data: {
										permissionKey: [
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_ALL_SURVEY,
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING,
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET
										]
									},
								},
								{
									path: ':clientCode/:idSurvey',
									component: SurveyDetailComponent,
									pathMatch: 'full',
									data: {
										permissionKey: [
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_ALL_SURVEY,
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_MEDIA_MARKETING,
											PermissionKeyEnum.BACKOFFICE_SURVEY_CENTER_BUDGET
										]
									},
								}
							]
						}
					]
				},
				// chat
				// {
				// 	path: 'chat',
				// 	data: { permissionKey: [PermissionKeyEnum.BO_LIVE_ASSISTANCE_CENTER] },
				// 	children: [
				// 		{
				// 			path: '',
				// 			component: ChatComponent,
				// 			pathMatch: 'full',
				// 			data: { permissionKey: [PermissionKeyEnum.BO_LIVE_ASSISTANCE_CENTER] }
				// 		},
				// 		{
				// 			path: ':idChat',
				// 			component: ChatDetailComponent,
				// 			pathMatch: 'full',
				// 			data: { permissionKey: [PermissionKeyEnum.BO_LIVE_ASSISTANCE_CENTER] }
				// 		}
				// 	]
				// },
				// request-center
				// - asset
				// - print-production-flow
				{
					path: 'request-center',
					data: {
						permissionKey: [
							PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
							PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
							PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
							PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
						]
					},
					children: [
						{
							// valorizzato come RequestCenterEnum.type
							path: ':requestType',
							resolve: {
								categoryList: AssetRequestCategoryResolver
							},
							data: {
								permissionKey: [
									PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
									PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
									PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
									PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
								]
							},
							children: [
								{
									path: ':userType',
									data: {
										permissionKey: [
											PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
											PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
											PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
											PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
										]
									},
									children: [
										{
											path: '',
											component: RequestCenterComponent,
											data: {
												permissionKey: [
													PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
													PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
													PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
													PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
												]
											},
											pathMatch: 'full'
										},
										{
											path: ':ticket_id',
											component: RequestCenterDetailComponent,
											data: {
												permissionKey: [
													PermissionKeyEnum.BACKOFFICE_ASSET_REQUEST_CENTER,
													PermissionKeyEnum.BACKOFFICE_AGENCY_ASSET_REQUEST_CENTER,
													PermissionKeyEnum.BACKOFFICE_PRINT_PRODUCTION_FLOW_CENTER,
													PermissionKeyEnum.BACKOFFICE_AGENCY_PRINT_PRODUCTION_FLOW_CENTER
												]
											},
											pathMatch: 'full'
										}
									]
								}
							]
						}
					]
				},
				// Asset request dashboard
				// {
				// 	path: 'asset-request-center-dashboard',
				// 	data: { permissionKey: [PermissionKeyEnum.BO_ASSET_REQUEST_CENTER] },
				// 	children: [
				// 		{
				// 			path: ':requestType/:userType',
				// 			data: { permissionKey: [PermissionKeyEnum.BO_ASSET_REQUEST_CENTER] },
				// 			children: [
				// 				{
				// 					path: '',
				// 					component: AssetRequestDashboardComponent,
				// 					data: { permissionKey: [PermissionKeyEnum.BO_ASSET_REQUEST_CENTER] },
				// 					pathMatch: 'full'
				// 				}
				// 			]
				// 		}
				// 	]
				// }
			]
		}
	];
}
