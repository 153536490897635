import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

// model
import { TicketModel, TicketStateModel } from '../../../../model/asset-request/ticket.model';
import {
	ChatDetailStateFeatureSelectionParameterModel,
} from '../../../../model/pouch/chat.model';
// import { RemoteChangeState } from '../../../../model/remote-change.model';
import { ChatInstanceTypeEnum } from '../../../../enum/chat.enum';
import { CategoryListStateModel } from '../../../../model/category-list.model';
import { AssetRequestCategory } from '../../../../model/asset-request/asset-request-category-list.model';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { BaseStateModel, BaseState } from '../../../../model/state/base-state.model';

// store
import { Store } from '@ngrx/store';
import { TicketActionEnum, TicketStateAction } from '../../../../state/asset-request/ticket/ticket.actions';
// import { ChatDetailStateAction } from '../../../../state/chat/chat-detail/chat-detail.actions';
import * as fromState from '../../../../state/index';
// import { RemoteChangeStateAction } from '../../../../state/remote-change/remote-change.actions';
import { StateFeature } from '../../../../state/index';

// widget & utility
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map, skipWhile, filter, take } from 'rxjs/operators';
import { RestAttachmentListManagerService, SubscribeManagerService } from '@saep-ict/angular-core';
import { UtilService } from '../../../../service/util/util.service';
// import { AssetRequestChatService } from '../../../../service/rest/asset-request-chat.service';
import { AssetRequestUtilService } from '../../../../service/util/asset-request-util.service';
import { DialogRequestCenterAdvertisingMaterial } from '../../widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { TdDataTableComponent } from '@covalent/core/data-table';
import { DialogConfirmComponent } from '../../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { AssetRequestModel } from '../../../../model/asset-request/request-center.model';
import { requestCenterConfiguration } from '../../../../constant/request-center/request-center-configuration.constant';
import { RequestCenterEnum } from '../../../../enum/request-center/request-center.enum';
import { DialogRequestCenterPrintProductionFlowComponent } from '../../widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { ButtonItemModel } from '../../../../model/button-list.model';
import { Moment } from 'moment';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';
import _ from 'lodash';
import { FormValidatorCustom } from '../../../../shared/misc/form-validator';
import { CommercialAreasService } from '../../../../service/util/commercial-areas.service';
import { CustomerDivisionPouchModel } from '../../../../model/pouch_agent_models/customer-pouch.model';
import * as RequestCenterAttachmentListManagerBase from '../../../../constant/request-center/request-center-attachment-list-manager-configuration-base.constant';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { ChatPdfComponent } from '../../../../service/print-chat-pdf/chat-pdf/chat-pdf.component';
import { ChatPdfUtilService } from '../../../../service/print-chat-pdf/util-chat-pdf.service';
import { CustomerAppConfig } from '../../../../customer-app.config';
@Component({
	selector: 'app-request-center-detail',
	templateUrl: './request-center-detail.component.html',
	styleUrls: ['./request-center-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class RequestCenterDetailComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;
	subjectMaxLength = 60;

	userType = this.route.snapshot.paramMap.get('userType');
	requestType = this.route.snapshot.paramMap.get('requestType');

	// Enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	requestCenterEnum = RequestCenterEnum;

	platformType: 'frontoffice' | 'backoffice' = this.route.snapshot.data['platform'];

	requestConfiguration: AssetRequestModel.Configuration<
		DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
	> = requestCenterConfiguration.find(i => i.type === this.requestType);

	// user
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
	user: UserDetailModel;

	// context_code: company
	loginContextCodeCompanyState$: Observable<BaseStateModel<OrganizationPouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeCompanyState
	);
	loginContextCodeCompanyState: BaseStateModel<OrganizationPouchModel>;

	// category list
	categoryList$: Observable<CategoryListStateModel<AssetRequestCategory>> = this.store.select(
		StateFeature.getCategoriesState
	);
	categoryList: CategoryListStateModel<AssetRequestCategory>;

	// ticket
	ticketState$: Observable<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>> = this.store.select(StateFeature.getTicketState);
	ticket: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow> = {
		_id:
			this.route.snapshot.paramMap.get('ticket_id') !== 'new'
				? this.route.snapshot.paramMap.get('ticket_id')
				: null,
		status: 1,
		type: this.requestConfiguration.actionKey,
		asset_list: [],
		request: {
			date_request: Date.now(),
			date_due: this.requestConfiguration.pageDetail.headerField.date_due.minDate,
			company: {
				business_name: null,
				client_code: null
			},
			user: {
				id: null,
				username: null
			}
		},
		date_status_update: Date.now()
	};

	// chat
	// remoteChange$: Observable<RemoteChangeState> = this.store.select(StateFeature.getRemoteChangeState);
	// chatClientState$: Observable<ChatModel> = this.store.select(fromState.StateFeature.selectActiveAssetRequestChat, {
	// 	side: ChatInstanceTypeEnum.client
	// });
	// chatAgencyState$: Observable<ChatModel> = this.store.select(fromState.StateFeature.selectActiveAssetRequestChat, {
	// 	side: ChatInstanceTypeEnum.agency
	// });
	chatList: ChatDetailStateFeatureSelectionParameterModel[] = [
		{
			side: ChatInstanceTypeEnum.client,
			// chat: {
			// 	thread: {
			// 		pagination: {
			// 			page_current: 1,
			// 			// page_size: 15
			// 			page_size: 4
			// 		}
			// 	}
			// }
		},
		{
			side: ChatInstanceTypeEnum.agency,
			// chat: {
			// 	thread: {
			// 		pagination: {
			// 			page_current: 1,
			// 			// page_size: 15
			// 			page_size: 4
			// 		}
			// 	}
			// }
		}
	];

	form: FormGroup;

	formHeaderValidity: boolean;
	backButtonRoute: string[] = [];

	statusChangeButtonList: ButtonItemModel[] = [];
	statusChangeButtonListContainerVisible: boolean;

	room_name: string;
	messages: Object;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		public router: Router,
		private store: Store<any>,
		// private chatService: AssetRequestChatService,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: UtilService,
		private snackBar: MatSnackBar,
		public assetRequestUtilService: AssetRequestUtilService,
		private commercialAreasService: CommercialAreasService,
		private restAttachmentListManagerService: RestAttachmentListManagerService,
		private chatPdfUtilService: ChatPdfUtilService,
		public appConfig: CustomerAppConfig
	) {
		this.getStaticData();
		this.createForm();
		this.checkPermissionChatList();
		this.subscribeManagerInit();
		if (this.ticket._id) {
			this.store.dispatch(TicketStateAction.load({ id: this.ticket._id }));
		} else {
			this.setTicketNew();
		}
	}

	ngOnInit() { }

	getStaticData() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.loginContextCodeCompanyState$.pipe(take(1)).subscribe(res => {
			this.loginContextCodeCompanyState = res ? res : null;
		});
	}

	subscribeManagerInit() {
		this.subscribeManagerService.populate(this.subscribeMandatoryData().subscribe(), 'mandatory-subscribe');
		this.subscribeManagerService.populate(this.subscribeCategoryListData().subscribe(), 'category-list-subscribe');
		// this.subscribeManagerService.populate(this.subscribeChatListData(), 'chat-list-subscribe');
		this.subscribeManagerService.populate(this.subscribeOnChangeFormData(), 'change-form-subscribe');
		// this.subscribeManagerService.populate(
		// 	this.subscribeChatListRemoteChange().subscribe(),
		// 	'chat-list-remote-change-subscribe'
		// );
	}

	getBackButtonRoute() {
		this.backButtonRoute = [this.platformType, 'request-center', this.requestType, this.userType];
		if (this.userType === RequestCenterEnum.User.type.COMPANY) {
			this.backButtonRoute.push(this.ticket.request.company.client_code);
		}
	}

	// subscribe
	subscribeCategoryListData() {
		return this.categoryList$.pipe(
			skipWhile((categoryList: CategoryListStateModel<AssetRequestCategory>) => !categoryList),
			map((categoryList: CategoryListStateModel<AssetRequestCategory>) => {
				this.assetRequestUtilService.categoryList = categoryList;
			})
		);
	}

	subscribeOnChangeFormData() {
		return this.form.valueChanges.pipe(debounceTime(1000)).subscribe(async formChange => {
			let cloneTicket = _.cloneDeep(this.ticket);

			cloneTicket.request.assignee = formChange.hasOwnProperty('assignee')
				? formChange['assignee']
				: cloneTicket.request.assignee;
			cloneTicket.request.date_due =
				formChange.hasOwnProperty('date_due') && formChange['date_due']
					? (formChange['date_due'] as Moment).valueOf()
					: cloneTicket.request.date_due;
			cloneTicket.request.subject = formChange.hasOwnProperty('subject')
				? formChange['subject']
				: cloneTicket.request.subject;
			cloneTicket.request.urgent = formChange.hasOwnProperty('urgent')
				? formChange['urgent']
				: cloneTicket.request.urgent;

			cloneTicket.request = this.utilService.deleteEmptyProperties(cloneTicket.request);
			this.store.dispatch(TicketStateAction.update(new BaseState(cloneTicket)));
			if (this.form.valid) {
				this.store.dispatch(TicketStateAction.save(new BaseState(cloneTicket)));
			}
		});
	}

	subscribeMandatoryData() {
		return this.ticketState$.pipe(
			filter((e: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>) => (e && e.type ? true : false)),
			map((e: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>) => {
				switch(e.type) {
					case TicketActionEnum.ERROR:
						break;
					case TicketActionEnum.UPDATE:
					case TicketActionEnum.REMOVE:
						this.ticket = _.cloneDeep(e.data);
						this.checkTicketVisibility();
						this.setStatusChangeButtonList();
						this.loadChatList();
						this.getBackButtonRoute();
						break;
				}
			})
		);
	}

	// subscribeChatListData() {
	// 	const chatClient$ = this.chatClientState$.pipe(
	// 		skipWhile((chat: ChatModel) => !chat),
	// 		map((chat: ChatModel) => {
	// 			this.chatList[
	// 				this.utilService.getElementIndex(this.chatList, 'side', ChatInstanceTypeEnum.client)
	// 			].chat = chat;
	// 			return chat;
	// 		})
	// 	);
	// 	const chatAgency$ = this.chatAgencyState$.pipe(
	// 		skipWhile((chat: ChatModel) => !chat),
	// 		map((chat: ChatModel) => {
	// 			this.chatList[
	// 				this.utilService.getElementIndex(this.chatList, 'side', ChatInstanceTypeEnum.agency)
	// 			].chat = chat;
	// 			return chat;
	// 		})
	// 	);
	// 	return combineLatest([chatClient$, chatAgency$]).subscribe(data => {});
	// }

	// subscribeChatListRemoteChange() {
	// 	return this.remoteChange$.pipe(
	// 		skipWhile((remoteChangeState: RemoteChangeState) => !remoteChangeState.chatDetailState),
	// 		map((remoteChangeState: RemoteChangeState) => {
	// 			const chat: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(
	// 				JSON.stringify(
	// 					this.chatList[
	// 						this.utilService.getElementIndex(
	// 							this.chatList,
	// 							'side',
	// 							remoteChangeState.chatDetailState.side
	// 						)
	// 					]
	// 				)
	// 			);
	// 			if (chat.chat._id) {
	// 				this.loadChatPage(chat, 1);
	// 			} else {
	// 				chat.chat._id = remoteChangeState.chatDetailState.chat._id;
	// 				this.store.dispatch(ChatDetailStateAction.load(chat));
	// 			}
	// 		})
	// 	);
	// }

	setStatusChangeButtonList() {
		this.statusChangeButtonList = this.requestConfiguration.status.changeButtonList[this.userType](
			this.ticket,
			this.ticket.status === RequestCenterEnum.Status.base.DRAFT ? this.form.valid : true
		);
		this.statusChangeButtonListContainerVisible = false;
		for (let i = 0; i < this.statusChangeButtonList.length; i++) {
			if (this.statusChangeButtonList[i].visible(this.ticket.status)) {
				this.statusChangeButtonListContainerVisible = true;
				break;
			}
		}
	}

	/**
	 * Nel caso di DRAFT new.
	 * Setta le proprietà di base dipendenti dalle subscribe.
	 */
	setTicketNew() {
		this.ticket.request.user.id = this.user.id;
		this.ticket.request.user.username = this.user.username;
		if (this.userType === RequestCenterEnum.User.type.COMPANY) {
			this.ticket.request.company.business_name = this.loginContextCodeCompanyState.data.business_name;
			this.ticket.request.company.client_code = this.loginContextCodeCompanyState.data.code_erp;
			this.setRequestCompanyAreaCommercial();
		}
		this.ticket = this.utilService.deleteEmptyProperties(this.ticket);
		this.store.dispatch(TicketStateAction.save(new BaseState(this.ticket)));
	}

	async setRequestCompanyAreaCommercial() {
		const commercialAreas = await this.commercialAreasService.retrieveCommercialArea();
		const division: CustomerDivisionPouchModel = this.loginContextCodeCompanyState.data.division_list.find(
			item => item.division === 'A'
		);
		const area_commercial_code: string = division ? division.area_commercial : null;
		if (area_commercial_code) {
			this.ticket.request.company.area_commercial = commercialAreas.find(
				item => item.codiceElemento === area_commercial_code
			).descrizioneEstesa;
		}
	}

	/**
	 * Nei casi diversi da DRAFT new.
	 * Aggiorna interamente il ticket con i dati che giungono da remoto
	 *
	 * Altre azioni da definire in base a status.
	 *
	 * Scatena a sua volta i metodi:
	 * - setValueForm()
	 */
	setTicketFromRemoteData() {
		switch (this.ticket.status) {
			case RequestCenterEnum.Status.base.DRAFT:
				this.updateDates();
				if (this.requestType === RequestCenterEnum.type.PRINT_PRODUCTION_FLOW) {
					this.form.get('subject').setValidators(Validators.required);
				}
				break;
			case RequestCenterEnum.Status.base.PENDING_AGENCY:
				this.form.disable({ emitEvent: false });
				if (this.userType === RequestCenterEnum.User.type.AGENCY) {
					this.form.get('assignee').enable({ emitEvent: false });
				}
				break;
			default:
				this.form.disable({ emitEvent: false });
				if (
					!this.requestConfiguration.pageDetail.headerField.date_due.disable(
						this.ticket,
						RequestCenterEnum.User.type[this.userType.toUpperCase()]
					)
				) {
					this.form.get('date_due').enable({ emitEvent: false });
				}
				if (
					!this.requestConfiguration.pageDetail.headerField.urgent.disable(
						this.ticket,
						RequestCenterEnum.User.type[this.userType.toUpperCase()]
					)
				) {
					this.form.get('urgent').enable({ emitEvent: false });
				}
				break;
		}
		this.setValueForm();
	}

	checkTicketVisibility() {
		if (this.ticket._id === 'deleted') {
			this.router.navigate(['../'], { relativeTo: this.route });
		} else {
			const userGo =
				this.userType !== RequestCenterEnum.User.type.COMPANY ||
				this.ticket.request.company.client_code === this.loginContextCodeCompanyState.data.code_erp;
			const ticketStatusGo = this.assetRequestUtilService
				.returnStatusValueNumberList(this.requestConfiguration.status.filterRemap[this.userType])
				.includes(this.ticket.status);
			if (userGo && ticketStatusGo) {
				this.setTicketFromRemoteData();
			} else {
				this.openSnackBar('You are not authorised to see this ticket');
				this.router.navigate(['../'], { relativeTo: this.route });
			}
		}
	}

	// form
	createForm() {
		this.form = this.fb.group({
			assignee: [{ value: null, disabled: true }, FormValidatorCustom.email],
			date_due: [
				{
					value: null,
					disabled: this.requestConfiguration.pageDetail.headerField.date_due.disable(
						this.ticket,
						RequestCenterEnum.User.type[this.userType.toUpperCase()]
					)
				},
				Validators.required
			],
			date_request: [{ value: null, disabled: true }],
			subject: [{ value: null }, [Validators.maxLength(this.subjectMaxLength)]],
			urgent: [
				{
					value: false,
					disabled: this.requestConfiguration.pageDetail.headerField.urgent.disable(
						this.ticket,
						RequestCenterEnum.User.type[this.userType.toUpperCase()]
					)
				}
			]
		});
	}

	setValueForm() {
		this.form.patchValue(
			{
				date_request: this.ticket.request.date_request,
				date_due: this.ticket.request.date_due,
				urgent: this.ticket.request.urgent,
				subject: this.ticket.request.subject,
				assignee: this.ticket.request.assignee
			},
			{ emitEvent: false }
		);
	}

	// chat
	/**
	 * In base alla valorizzazione di userType aggiorna lo stato chat-list
	 *   - frontoffice: frontoffice <-> euroitalia
	 *   - euroitalia: frontoffice <-> euroitalia <-> agency
	 *   - agency: agency <-> euroitalia
	 */
	checkPermissionChatList() {
		if (this.requestConfiguration.type === RequestCenterEnum.type.IMAGE_VIDEO) {
			switch (this.userType) {
				case RequestCenterEnum.User.type.COMPANY:
					this.chatList.splice(this.utilService.getElementIndex(this.chatList, 'side', `agency`), 1);
					break;
				case RequestCenterEnum.User.type.AGENCY:
					this.chatList.splice(this.utilService.getElementIndex(this.chatList, 'side', `client`), 1);
					break;
			}
		}
		if (this.requestConfiguration.type === RequestCenterEnum.type.PRINT_PRODUCTION_FLOW) {
			this.chatList.splice(this.utilService.getElementIndex(this.chatList, 'side', `client`), 1);
		}
	}

	loadChatList() {
		for (const chat of this.chatList) {
			if (!chat.chat) {
				chat.chat = {};
			}
			chat.chat._id = `thread_${this.ticket._id}_${chat.side}`;
			// recupero url da environment tramite appConfig
			// Distinguere tra Image Video e Print Production nel path della chiamata
			switch(this.requestConfiguration.type) {
				case RequestCenterEnum.type.IMAGE_VIDEO: 
					chat.chat.url = `${this.appConfig.config.chat.requestCenter}${this.ticket.code_item}_${chat.side}/?layout=embedded`;
					break;
				case RequestCenterEnum.type.PRINT_PRODUCTION_FLOW:
					
					chat.chat.url = `${this.appConfig.config.chat.printProductionFlow}${this.ticket.code_item}_${chat.side}/?layout=embedded`;
					break;
			}
		}
	}

	// closeChatListConnection() {
	// 	for (let i = 0; i < this.chatList.length; i++) {
	// 		this.store.dispatch(ChatDetailStateAction.closeConnection(this.chatList[i]));
	// 	}
	// }

	// loadChatPage(e: ChatDetailStateFeatureSelectionParameterModel, page: number) {
	// 	const item: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(
	// 		JSON.stringify(this.chatList[this.utilService.getElementIndex(this.chatList, 'side', e.side)])
	// 	);
	// 	item.chat.thread.pagination.page_current = page;
	// 	this.store.dispatch(ChatDetailStateAction.loadMessageList(item));
	// }

	// onChatSendMessage(e: MessageModel, chat: ChatDetailStateFeatureSelectionParameterModel) {
	// 	const message: MessageModel = {
	// 		date_sending: Date.now(),
	// 		text: e.text,
	// 		thread_id: chat.chat._id ? chat.chat._id : `thread_${this.ticket._id}_${chat.side}`,
	// 		sender: parseInt(this.user.id, null),
	// 		type: 'message'
	// 	};
	// 	this.chatService.sendMessage(
	// 		message,
	// 		res => {
	// 			if (!chat.chat._id) {
	// 				chat.chat._id = `thread_${this.ticket._id}_${chat.side}`;
	// 				this.store.dispatch(ChatDetailStateAction.load(chat));
	// 			}
	// 		},
	// 		err => {
	// 			this.snackBar.open('An error has occurred, please try again later', 'OK', {
	// 				duration: 3000
	// 			});
	// 		}
	// 	);
	// }

	// dialog
	dialogDimensions(row?: any) {
		const dialogRef: MatDialogRef<
			DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
		> = this.dialog.open(this.requestConfiguration.pageDetail.dialog, {
			data: {
				asset: row ? _.cloneDeep(row) : null,
				ticket: this.ticket,
				context: this.userType,
				ticketStatus: this.ticket.status
			},
			panelClass: ['dialog-large', 'michelangelo-theme-dialog'],
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.ticket = this.assetRequestUtilService.handleAssetListUpdate(this.ticket, {
					asset: res.asset,
					index: res.index
				});
				this.saveTicket();
			}
		});
	}

	saveTicket() {
		this.store.dispatch(TicketStateAction.save(new BaseState(this.ticket)));
	}

	changeTicketStatus(e: ButtonItemModel) {
		if (e.value === RequestCenterEnum.Status.base.DELETED) {
			this.deleteTicket();
		} else {
			let text = `Do you really want to change the status to '${this.utilService.formatPlaceholder(
				RequestCenterEnum.Status.base[e.value]
			)}'?`;
			switch (this.userType) {
				case RequestCenterEnum.User.type.COMPANY:
					text = 'Do you really want to send the request?';
					break;
				case RequestCenterEnum.User.type.AGENCY:
					text = 'Do you really want to complete the request?';
					break;
			}
			const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
				data: {
					title: 'Change Status',
					text: text
				},
				panelClass: ['dialog-small', 'michelangelo-theme-dialog']
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					if (this.ticket.status === RequestCenterEnum.Status.base.DRAFT) {
						this.updateDates();
					}
					if (e.value === RequestCenterEnum.Status.base.PENDING_AGENCY && !this.ticket.request.user_owner) {
						// User owner info
						this.ticket.request.user_owner = <UserDetailModel>{};
						this.ticket.request.user_owner.id = this.user.id;
						this.ticket.request.user_owner.last_name = this.user.last_name;
					}
					this.ticket.status = e.value;
					this.ticket.date_status_update = Date.now();
					this.saveTicket();
				}
			});
		}
	}

	updateDates() {
		this.ticket.request.date_request = Date.now();
		this.ticket.request.date_due =
			this.ticket.request.date_due < this.requestConfiguration.pageDetail.headerField.date_due.minDate
				? this.requestConfiguration.pageDetail.headerField.date_due.minDate
				: this.ticket.request.date_due;
	}

	deleteTicket() {
		try {
			const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
				data: {
					title: 'Delete ticket',
					text: `Do you really want to DELETE this ticket?`
				},
				panelClass: ['dialog-small', 'michelangelo-theme-dialog']
			});
			dialogRef.afterClosed().subscribe(async res => {
				if (res) {
					this.store.dispatch(TicketStateAction.remove(new BaseState(this.ticket)));
					await this.restAttachmentListManagerService.delete(
						{ pathList: [`${this.requestType}/${this.ticket.code_item}/`] },
						null,
						{ path: RequestCenterAttachmentListManagerBase.configuration.pathUrl }
					);
				}
			});
		} catch(err) {
			throw new Error(err);
		}
	}

	// snackbar
	openSnackBar(message: string, action = 'OK', duration = 5000) {
		this.snackBar.open(message, action, { duration: duration });
	}

	toggleColumn(e) {
		for (let i = 0; i < this.requestConfiguration.pageDetail.columnList[this.userType].length; i++) {
			if (this.requestConfiguration.pageDetail.columnList[this.userType][i].name === e.name) {
				this.requestConfiguration.pageDetail.columnList[this.userType][i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	async generatePDF(chat_side: string): Promise<any> {
		switch(this.requestConfiguration.type) {
			case RequestCenterEnum.type.IMAGE_VIDEO: 
			this.room_name = this.room_name = `asset-request-center_${this.ticket.code_item}_${chat_side}`;
				break;
			case RequestCenterEnum.type.PRINT_PRODUCTION_FLOW:
				this.room_name = this.room_name = `print-production-flow_${this.ticket.code_item}_${chat_side}`;;
				break;
		};	
		// Effettuo chiamata api per recuperare i messaggi chat
		this.messages = await this.chatPdfUtilService.getChatPdf(this.room_name);
		
		this.dialog.open(ChatPdfComponent, {
			data: {
				title: 'Print PDF',
				side: chat_side,
				ticket: this.ticket._id,
				userType: this.userType,
				// payload dei messaggi da passare 
				chat: this.messages 
			},
			panelClass: ['dialog-small', 'michelangelo-theme-dialog'],
			disableClose: false
		});
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(TicketStateAction.reset());
		// this.closeChatListConnection();
		// this.store.dispatch(RemoteChangeStateAction.reset());
	}

	// rocket chat iframe test
	goToGeneral(){
		document.querySelector('iframe').contentWindow.postMessage({
		  externalCommand: 'go',
		  path: '/channel/general'
		}, '*');
	};
	
	loginWithKeycloak(){
		document.querySelector('iframe').contentWindow.postMessage({
		  externalCommand: 'call-custom-oauth-login',
		  service: 'keycloak'
		}, '*');
	};
	
	logout(){
		document.querySelector('iframe').contentWindow.postMessage({
		  externalCommand: 'logout'
		}, '*');
	};
}
