import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
@Injectable()
export class AppAttachmentResolverService {
  private emptyAR = new Subject<any>();
  emptyAR$ = this.emptyAR.asObservable();

  constructor(){}

  public empty() {
    this.emptyAR.next(null);
  }
}