import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PouchUtilService } from '@saep-ict/pouch-db';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { filter, map, skipWhile, take, takeUntil } from 'rxjs/operators';
import { TokenPayload } from '../../model/login.model';
import { RestBasePk } from '../../model/rest-base.model';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import {
	LinkCodeModel,
	LinkDetailModel
} from '../../model/user.model';
import { StateFeature } from '../../state';
import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import { UserService } from '../rest/user.service';
import { ContextType, UtilGuardService } from './util-guard/util-guard.service';
import { PouchDbCommonsUserAdapter } from '../pouchdb/instance/commons/pouchdb-commons-user-adapter.service';
import {
	UserDetailModel,
	UserTypeContextModel,
	CurrentContextPermission,
	ContextApplicationItemCodeEnum,
	ContextCodeAssociation
} from '@saep-ict/angular-spin8-core';
import { OrganizationListStateAction } from '../../state/organization-list/organization-list.actions';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { noSqlDocSeparator } from '../../constant/structure.constant';

@Injectable()
export class AuthTokenGuard implements CanActivate {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	@LocalStorage('springAuthenticationToken')
	springAuthenticationToken: string;

	@LocalStorage('link_code') link_code: LinkCodeModel;

	authState: boolean;
	destroy$: Subject<boolean> = new Subject<boolean>();
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	organizatioList$: Observable<BaseStateModel<OrganizationPouchModel[]>> =
		this.store.select(StateFeature.getOrganizationList);
	organizatioList: OrganizationPouchModel[];

	contextState$: Observable<BaseStateModel<ContextType>>;

	constructor(
		private router: Router,
		private authService: AuthService,
		private pouchUtilService: PouchUtilService,
		private store: Store<any>,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		private utilGuardService: UtilGuardService,
		private userService: UserService,
		private pouchDbCommonsUserAdapter: PouchDbCommonsUserAdapter
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		let userState: UserDetailModel;
		let contextState: ContextType;
		let currentContext: UserTypeContextModel;
		try {
			if (route.queryParams.token) {
				this.authenticationToken = route.queryParams.token
			}
			if (route.queryParams.context_application && route.queryParams.context_code) {
				this.link_code = {
					context_application: route.queryParams.context_application,
					context_code: route.queryParams.context_code
				};
			}
			this.user$.pipe(take(1)).subscribe(res => { userState = res ? res.data : null; });
			if (userState) {
				if (userState.current_permission) {
					currentContext = this.utilGuardService.retrieveContextPermission(userState.current_permission.context_application);
					this.contextState$ = this.store.select(currentContext.state);
					this.contextState$.pipe(take(1)).subscribe(res => {
						contextState = res ? res.data : null;
					});
				}
			}
		} catch (err) {
			console.log(err);
			this.authService.logout();
			return false;
		}
		if (userState && contextState) {
			return true;
		}
		const tk_decoded: any = jwt_decode(this.authenticationToken);
		const tokenPayload = {
			exp: tk_decoded.exp,
			orig_iat: tk_decoded.orig_iat,
			user_id: tk_decoded.user_id,
			username: tk_decoded.email,
			signature : ''
		};
		const tk= new TokenPayload(tokenPayload);
		this.authService.tokenPayload = tk
		return this.checkUserPermission(state);
	}

	async checkUserPermission(state: RouterStateSnapshot): Promise<boolean> {
		const userDetailRequest: RestBasePk = { id: this.authService.tokenPayload.user_id };
		try {
			this.authState = false;
			this.user = (await this.userService.getUserDetail(userDetailRequest)).data;
			await this.pouchUtilService.explicitInitCouch();
			await this.prepareUserContext(state);
			return this.authState;
		} catch (err) {
			console.log(err);
			this.authService.logout();
			return false;
		}
	}

	prepareUserContext(state: RouterStateSnapshot) {
		const currentContext = this.utilGuardService.retrieveContextPermission(this.link_code.context_application);
		const contextLink = this.utilGuardService.checkUserContext(this.user, this.link_code.context_application);
		const currentLink = contextLink.currentContext.context_code_list.find((link: LinkDetailModel) => {
			return link.code === this.link_code.context_code;
		});
		if (!currentLink) {
			throw new Error('current code is invalid');
		}
		return this.setUserContext(currentContext, state.url, this.link_code.context_code);
	}

	async setUserContext(context: UserTypeContextModel, url: string, explicitLink?: string): Promise<boolean> {
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		this.user.current_permission = await this.returnUserCurrentPermission(
			this.link_code.context_application,
			this.link_code.context_code,
			currentPermission
		);
		if (!this.utilGuardService.checkUserPermission(this.user)) {
			this.snackBar.open(this.translate.instant('login.general.authentication_failed_permissions'), 'OK', { duration: 3000 });
			throw new Error('default permission code is invalid');
		}
		this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
		return new Promise((resolve, reject) => {
			this.utilGuardService.dispatchUserContext(context, this.user, explicitLink);
			this.initObservableState(context.type).subscribe(
				async (res: ContextType) => {
					const contextLink = this.utilGuardService.checkUserContext(this.user, context.type);
					this.link_code = {
						context_application: context.type,
						context_code: !explicitLink
							? contextLink.currentContext.context_code_list[0].code.toString()
							: explicitLink
					};
					if (this.link_code.context_application === ContextApplicationItemCodeEnum.B2B) {
						this.store.dispatch(OrganizationListStateAction.loadDetail(
							new BaseState({_id: `organization${noSqlDocSeparator}${this.link_code.context_code}`}))
						);
					} else {
						this.store.dispatch(OrganizationListStateAction.load());
					}
					await this.mandatoryStore();
					this.destroy$.next(true);
					this.setRouteTree(context, url);
					resolve(true);
				},
				error => {
					throw new Error(error);
				}
			);
		});
	}

	setRouteTree(permission: UserTypeContextModel, url: string) {
		// todo: puntate ad una chiave rispetto all'indice dell'array
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		const currentRoutList = this.utilGuardService.filterRouteWithPermission(permission.route, currentPermission);
		this.router.config[1].children = currentRoutList;
		this.router.resetConfig(this.router.config);
		this.authState = true;

		// rimozione query param (context_code context_application token) che
		// vengono in alcune casistiche mergiati con l'ultimo route param
		const index = url.indexOf('?');
		const urlWithNoQueryParam = index > -1 ? url.slice(0, index) : url;

		this.router.navigate([urlWithNoQueryParam]);
	}

	initObservableState(key: ContextApplicationItemCodeEnum): Observable<ContextType> {
		this.contextState$ = this.store.select(this.utilGuardService.retrieveContextPermission(key).state);
		return this.contextState$.pipe(
			skipWhile((contextState: BaseStateModel<ContextType>) => !(contextState && contextState.data)),
			takeUntil(this.destroy$),
			map((contextState: BaseStateModel<ContextType>) => {
				return contextState.data;
			})
		);
	}

	async returnUserCurrentPermission(
		context_application: ContextApplicationItemCodeEnum,
		context_code: string,
		permission: string[]
	): Promise<CurrentContextPermission> {
		const currentContextPermission = <CurrentContextPermission>{
			context_code: {
				code: context_code
			},
			context_application: context_application,
			permission: permission
		};
		let context_code_association: ContextCodeAssociation;
		try {
			context_code_association = await this.pouchDbCommonsUserAdapter.basePouch.getDetail<ContextCodeAssociation>(
				`context_code_association${noSqlDocSeparator}${context_code}`
			);
		} catch (err) {
			console.log(err);
		}
		if (
			context_code_association &&
			context_code_association.context_code_association_list &&
			context_code_association.context_code_association_list.length > 0
		) {
			currentContextPermission.context_code.context_code_association_list =
				context_code_association.context_code_association_list;
		}
		return currentContextPermission;
	}

	mandatoryStore(): Promise<void> {
		return new Promise(resolve => {
			try {
				this.organizatioList$.pipe(
					filter(e => !!(e && e.data)),
					take(1)
				).subscribe(e => resolve());
			} catch(err) {
				throw new Error(err);
			}
		});
	}
}
