import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// model
import { SurveyModel, BrandItemModel } from '../../../../model/survey-center/survey.model';
import { Brand } from '../../../../model/brand.model';

// widget
import { UtilService } from '../../../../service/util/util.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { Subscription } from 'rxjs';
import { CurrenciesService } from '../../../../service/util/survey-center/currencies.service';
import { SurveyMessageService } from '../../../../service/util/survey-center/survey-message.service';
import moment from 'moment';

@Component({
	selector: 'frontoffice',
	templateUrl: './frontoffice.component.html',
	styleUrls: ['./frontoffice.component.scss']
})
export class FrontofficeComponent implements OnInit {
	private _survey: SurveyModel = new SurveyModel();
	@Input() set survey(s: SurveyModel) {
		this._survey = JSON.parse(JSON.stringify(s));
		this.disabled = this._survey.date_end && moment(this.survey.date_end).diff(moment()) <= 0 ? true : false;
		if (s.currency) {
			// form - currencies
			this.createAndSubscribeForChangesFormCurrency();
		}
		if (s.brand_list && s.brand_list.length > 0) {
			this.brandSelected = this.utilService.brandSelected(s.brand_list);
		}
	}
	get survey(): SurveyModel {
		return this._survey;
	}

	disabled = true;

	@Output() onSurveyCurrencyChange = new EventEmitter();
	@Output() onSurveyFormChange = new EventEmitter();
	@Output() onDownloadTemplate = new EventEmitter<Brand>();
	@Output() onDownloadLatest = new EventEmitter<string>();

	formCurrency: FormGroup;
	formCurrencySubscribe: Subscription;

	brandSelected: BrandItemModel;

	constructor(
		public utilService: UtilService,
		private surveyMessageService: SurveyMessageService,
		private dialog: MatDialog,
		public currenciesService: CurrenciesService
	) {}

	ngOnInit() {}

	// form - currencies
	createAndSubscribeForChangesFormCurrency() {
		const controls = {
			currency: new FormControl({ value: this.survey.currency.isoCode, disabled: this.disabled }, [Validators.required])
		};
		this.formCurrency = new FormGroup(controls);
		this.formCurrencySubscribe = this.formCurrency.valueChanges.subscribe(() => {
			this.onFormCurrencySubmit();
		});
	}

	prepareSaveFormCurrency(): string {
		const formModel = this.formCurrency.value;
		const saveForm = formModel.currency as string;
		return saveForm;
	}

	// events
	onFormCurrencySubmit() {
		if (this.formCurrency.valid) {
			this.dialogUpdateSurveyCurrencyConfirm();
		}
	}

	onSaveSurveyForm(brand: BrandItemModel) {
		const survey = this.survey;
		survey.brand_list[this.utilService.getBrandIndex(survey.brand_list, brand.brand.codice_brand)] = brand;
		this.onSurveyFormChange.emit(survey);
	}

	// dialog
	/**
	 * La richiesta di conferma per il cambio currency scatta in questo contesto in modo da rendere meno complesso il reset della
	 * modifica in caso di rifiuto da parte dell'utente
	 */
	dialogUpdateSurveyCurrencyConfirm() {
		const currencyName = this.prepareSaveFormCurrency();
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: `Update of
					${this.surveyMessageService.returnSurveyConfirmationDialogTitle(
						this.survey.survey_type,
						this.survey.year,
						this.survey.commercial_area
					)}
					Currency`,
				text: 'Do You want to change Currency to ' + currencyName + '? The action affects all Brands.'
			},
			panelClass: ['dialog-small', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const survey = this.survey;
				survey.currency.isoCode = currencyName;
				this.onSurveyCurrencyChange.emit(survey.currency.isoCode);
			} else {
				this.formCurrencySubscribe.unsubscribe();
				this.createAndSubscribeForChangesFormCurrency();
			}
		});
	}
}
