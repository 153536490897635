import { Inject, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

import { TokenPayload } from './model/login.model';
import { ConfigCouchModel, IPouchAppConfig, IPouchConfigModel } from '@saep-ict/pouch-db';
import { IAppConfig, IConfigModel } from '@saep-ict/env-configurator';
import { CompanyConfigModel, UrlConfigModel } from './model/structure/environment-config.model';
import { BehaviorSubject } from 'rxjs';
import { ISaepIctAngularCoreConfigModel } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

@Injectable({
	providedIn: 'root'
})
export class CustomerAppConfig implements
	IPouchAppConfig<CustomerAppConfigModel>,
	IAppConfig<CustomerAppConfigModel>
	// , IMediaCenterAppConfig
	{

	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;

	@LocalStorage('authenticationToken')
	authenticationToken: string;

	// private _mc_config: IMediaCenterConfigModel;

	get username(): string {
		return this._tokenPayload && this._tokenPayload.username;
	}
	get signature(): string {
		return this._tokenPayload && this._tokenPayload.signature;
	}
	get config(): CustomerAppConfigModel {
		return this.configSource.getValue();
	}
	set config(val: CustomerAppConfigModel) {
		this.configSource.next(val);
	}
	configSource = new BehaviorSubject<CustomerAppConfigModel>(undefined as CustomerAppConfigModel);
	config$ = this.configSource.asObservable();
	populateConfig(configuration: CustomerAppConfigModel) {
		this.config = configuration;
	}

	// lib
	libToInitialize: string[] = ['saepIctAngularCore'];
	libInitialized: string[] = [];
	libInitializationDone = new BehaviorSubject<boolean>(false);
	libInitializationDone$ = this.libInitializationDone.asObservable();
	isEveryLibInizialized(lib: string) {
		this.libInitialized.push(lib);
		if (this.libToInitialize.every(i => this.libInitialized.includes(i))) {
			this.libInitializationDone.next(true);
		}
	}

	// lib - saep-ict-angular-core
	private _saepIctAngularCore_config: ISaepIctAngularCoreConfigModel;
	set saepIctAngularCore_config(val: ISaepIctAngularCoreConfigModel) {
		this._saepIctAngularCore_config = val;
	}
	get saepIctAngularCore_config(): ISaepIctAngularCoreConfigModel {
		return this._saepIctAngularCore_config;
	}
	initializeSaepIctAngularCore(config: ISaepIctAngularCoreConfigModel): void {
		this.saepIctAngularCore_config = config;
		this.isEveryLibInizialized('saepIctAngularCore');
	}

	constructor(@Inject('env-config') public envConfig: CustomerAppConfigModel) {
		if (this.envConfig) {
			this.populateConfig(this.envConfig);
		}
	}

	get token(): string {
		return this.authenticationToken;
	}

// 	initializeConfig(media_center_config: IMediaCenterConfigModel): void {
// 		this.mc_config = media_center_config;
// 	}
}

export class CustomerAppConfigModel implements IPouchConfigModel, IConfigModel
// , IMediaCenterConfig
{
	couch: ConfigCouchModel[];
	company: CompanyConfigModel;
	permissionContext: ContextApplicationItemCodeEnum[];
	externalUrlContext: PermissionContextDetail[];
	environment: string;
	urlConfig: UrlConfigModel;
	bucketManager: {
		be_url: string;
	};
	surveyCenter: {
		be_url: string;
	};
	assetRequestCenter: {
		be_url: string;
	}
	// media_center: IMediaCenterConfigModel;
	chat: {
		url: string;
		pdf: string;
		requestCenter: string;
		printProductionFlow: string;
	}
}

export interface PermissionContextDetail {
	codeItem: ContextApplicationItemCodeEnum;
	path: string;
}
