// model
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ChatModel, MessageModel } from '../../../model/pouch/chat.model';
import { AbstractListModel, Pagination } from '../../../model/structure/AbstractListModel';
import { reject } from 'lodash';

export class ChatCommonPouch extends AbstractPouchdbMethod {
	getDetail(idThread: string, messagePagination: Pagination, idUser: number): Promise<any> {
		if (idThread) {
			/**
			 * Backoffice: recupera il thread sfruttando direttamente il thread ID
			 */
			return new Promise(resolve => {
				this.get(idThread)
					.then(thread => {
						resolve(thread);
					})
					.catch(err => {
						console.log(err);
						reject(err);
					});
			});
		} else {
			/**
			 * Frontoffice: in mancanza dell'ID del thread viene cercato l'ID utente nella proprietà partecipant_list
			 * Questo presuppone l'impossibilità, per gli utenti frontoffice di avere più thread attivi
			 */
			return new Promise(resolve => {
				const selector = {
					$and: [
						{
							type: 'thread'
						},
						{
							partecipant_list: {
								$elemMatch: {
									$and: [
										{
											id: idUser
										}
									]
								}
							}
						}
					]
				} as PouchDB.Find.Selector;
				this.getWithIndexFilter('thread-list', selector, messagePagination)
					.then((res: any) => {
						if (res['docs'].length) {
							resolve(res['docs'][0]);
						}
					})
					.catch(err => {
						console.log(err);
					});
			});
		}
	}

	getMessageFilteredList(thread_id: string, pagination?: Pagination, filter?: any): Promise<any> {
		return new Promise(resolve => {
			const filters = {
				$and: [
					{
						type: 'message'
					},
					{
						thread_id: thread_id
					}
				]
			} as PouchDB.Find.Selector;
			const sort: { [key: string]: 'asc' | 'desc' }[] = [
				{
					date_sending: 'desc'
				}
			];
			this.getWithIndexFilter('message-list', filters, pagination, sort)
				.then((res: any) => {
					const messageList: AbstractListModel<MessageModel[]> = {
						data: pagination.page_current === 1 ? res['docs'].reverse() : res['docs']
					};
					resolve(messageList);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	putUserLastAccess(body: ChatModel): Promise<any> {
		return new Promise(resolve => {
			this.put<ChatModel>(body, body._id, false)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
