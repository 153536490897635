<mat-sidenav-container
  #offsetAnchor
  (backdropClick)="sidenav.close()"
  [style.min-height]="height"
>
  <mat-sidenav
    #sidenav
    position="end"
    [opened]="filterOpened"
    [mode]="mode"
    (keydown.escape)="sidenav.close()"
  >
    <ng-scrollbar
      [autoWidthDisabled]="true"
      [autoHeightDisabled]="false"
      [style.max-height.px]="1200"
    >
      <mat-toolbar>
        <mat-toolbar-row>
          Filters
        </mat-toolbar-row>
      </mat-toolbar>
      <ng-content select="[side]"></ng-content>
    </ng-scrollbar>
  </mat-sidenav>
  <mat-sidenav-content [style.min-height]="height">
    <ng-scrollbar
      [autoWidthDisabled]="true"
      [autoHeightDisabled]="false"
      [style.max-height.px]="1200"
    >
      <ng-content select="[main]"></ng-content>
    </ng-scrollbar>
  </mat-sidenav-content>
</mat-sidenav-container>