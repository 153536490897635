import { PipeTransform, Pipe } from '@angular/core';
import { PipeFilterOption } from '../../model/structure/pipe-filter.model';

@Pipe({
	name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {
	transform(items: any[], filterOption: PipeFilterOption) {
		if (
			!items ||
			!filterOption ||
			!filterOption.value ||
			!filterOption.propertyName
		) {
            return items;
		}
		const regex = new RegExp( filterOption.value, "i");
		return items.filter(item =>
			regex.test(item[filterOption.propertyName])
		);
	}
}
