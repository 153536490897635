import { Route } from '@angular/router';

import { ContextApplicationItemCodeEnum } from '../enum/context-application-item-code.enum';
import { RequestCenterDetailComponent } from '../page/asset-request/request-center/request-center-detail/request-center-detail.component';
import { RequestCenterComponent } from '../page/asset-request/request-center/request-center.component';
import { ChatDetailComponent } from '../page/chat/chat-detail/chat-detail.component';
import { SurveyDetailComponent } from '../page/survey-center/survey-detail/survey-detail.component';
import { SurveyListComponent } from '../page/survey-center/survey-list/survey-list.component';
import { ManageExternalUrlGuard } from '../service/guard/manage-external-url.guard';
import { BrandsResolver } from '../service/resolver/brands.resolver';
import { CommercialAreasResolver } from '../service/resolver/commercial-areas.resolver';
import { CurrenciesResolver } from '../service/resolver/currencies.resolver';
import { PermissionResolver } from '../service/resolver/permission.resolver';
import { PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import { AssetRequestCategoryResolver } from '../service/resolver/asset-request-category.resolver';

export namespace B2BRouteList {
	export const routes: Route[] = [
		/**
		 * Per la struttura originale delle routes vedere il file src/app/app-routing.module.ts
		 * Di seguito è stato riportato solo il ramo relativo (priva della rotta padre che effettua il passaggio del token
		 * che forse converrebbe piazzare temporaneamente in base-routing)
		 *
		 * C'è un po' di disordine per la lettura del parametro clientCode, che probabilmente per una questione di privilegi
		 * necessari alle chiamate rest di base viene anche salvato in authService.clientCode mediante il PermissionResolver
		 * sicuramente da deprecare
		 *
		 * */
		{
			path: '',
			canActivate: [ManageExternalUrlGuard],
			data: {
				platform: '',
				externalPath: ContextApplicationItemCodeEnum.B2B,
				permissionKey: [PermissionKeyEnum.B2B_DASHBOARD]
			},
			resolve: {
				permission: PermissionResolver
			},
			children: []
		},
		{
			path: 'frontoffice',
			data: {
				platform: 'frontoffice',
				permissionKey: [PermissionKeyEnum.B2B_DASHBOARD]
			},
			children: [
				// survey
				{
					path: 'survey-center/:surveyType',
					data: {
						permissionKey: [
							PermissionKeyEnum.B2B_SURVEY_CENTER_BUDGET,
							PermissionKeyEnum.B2B_SURVEY_CENTER_MEDIA_MARKETING
						]
					},
					children: [
						{
							path: ':clientCode', // il FO atterra direttamente a questo livello di annidamento
							resolve: {
								clientCode: PermissionResolver,
								areas: CommercialAreasResolver,
								brands: BrandsResolver,
								currencies: CurrenciesResolver
							},
							data: {
								permissionKey: [
									PermissionKeyEnum.B2B_SURVEY_CENTER_BUDGET,
									PermissionKeyEnum.B2B_SURVEY_CENTER_MEDIA_MARKETING
								]
							},
							children: [
								{
									path: '',
									component: SurveyListComponent,
									pathMatch: 'full',
									data: {
										permissionKey: [
											PermissionKeyEnum.B2B_SURVEY_CENTER_BUDGET,
											PermissionKeyEnum.B2B_SURVEY_CENTER_MEDIA_MARKETING
										]
									},
								},
								{
									path: ':idSurvey',
									component: SurveyDetailComponent,
									pathMatch: 'full',
									data: {
										permissionKey: [
											PermissionKeyEnum.B2B_SURVEY_CENTER_BUDGET,
											PermissionKeyEnum.B2B_SURVEY_CENTER_MEDIA_MARKETING
										]
									},
								}
							]
						}
					]
				},
				// chat
				// {
				// 	path: 'chat-detail',
				// 	component: ChatDetailComponent,
				// 	data: {permissionKey: [PermissionKeyEnum.B2B_LIVE_ASSISTANCE_CENTER] }
				// },
				// asset-request-center
				{
					path: 'request-center',
					data: { permissionKey: [PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER] },
					children: [
						{
							path: ':requestType/:userType/:clientCode', // il FO atterra direttamente a questo livello di annidamento
							resolve: {
								clientCode: PermissionResolver,
								categoryList: AssetRequestCategoryResolver
							},
							data: { permissionKey: [PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER] },
							children: [
								{
									path: '',
									component: RequestCenterComponent,
									pathMatch: 'full',
									data: { permissionKey: [PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER] }
								},
								{
									path: ':ticket_id',
									component: RequestCenterDetailComponent,
									data: { permissionKey: [PermissionKeyEnum.B2B_ASSET_REQUEST_CENTER] }
								}
							]
						}
					]
				}
			]
		}
	];
}
