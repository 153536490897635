import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Injectable } from "@angular/core";

@Injectable()
export class BrandListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		{
			name: "logo",
			label: "Logo"
		}
	];
}