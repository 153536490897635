import {Injectable} from "@angular/core";
import { ILoaderService } from "@saep-ict/angular-core";
import {BehaviorSubject} from "rxjs";
import { Loader } from "../../model/loader.model";

@Injectable()
export class LoaderService implements ILoaderService {
  private loaderSource = new BehaviorSubject<boolean>(false);
  private groupLoader: Loader[] = [];
  private cpyStatus: boolean = false;
  loader$ = <any>this.loaderSource.asObservable();
  changeLoader(status: boolean) {
    this.cpyStatus = status;
    this.loaderSource.next(status);
  }

  populateLoader(content: string) {
    const guid = this.guid();
    const loader: Loader = {
      "status": true,
      "content": content,
      "id": guid
    };
    this.groupLoader.push(loader);
    if (this.groupLoader.length === 1){
      this.changeLoader(true);
    }
    return guid;
  }

  changeSingleLoader(id) {
    const index = this.groupLoader.findIndex((result) => {
      return result.id === id;
    });
    this.groupLoader.splice(index, 1);
    if (this.groupLoader.length > 0){
      if (this.cpyStatus !== true){
        this.changeLoader( true);
      }
    }else{
      this.changeLoader( false);
    }
  }

  // generation guid
  guid(){
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }
}
