<div
  *ngFor="let row of brandList"
  class="brand"
  [ngClass]="{'selected': row.selected}"
  (click)="brandSelectedChange.emit(row.brand.codice_brand)"
>
  <!-- <img [src]="appConfig.config.urlConfig.api + '/bo/linea/' + row.brand.codice_brand + '/logo'"/> -->

  <h2>{{ row.brand.nome_brand }}</h2>

  <ng-container *ngIf="row.date_last_update else notYetSent">
    Last update: {{row.date_last_update | date:'dd/MM/yyyy'}}
  </ng-container>
  <ng-template #notYetSent>To do</ng-template>
</div>