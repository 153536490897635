import { Injectable } from '@angular/core';

// model
import { AbstractListModel } from '../../../../../model/structure/AbstractListModel';
import { ChatDetailStateFeatureSelectionParameterModel } from '../../../../../model/pouch/chat.model';

// store
import { Store } from '@ngrx/store';
import * as fromState from '../../../../../state/index';

// widget & utility
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';
import { ChatCommonPouch } from '../../../pouch-common-function/chat-common.pouch';
import { Observable, Subscription } from 'rxjs';
import { ChatAssistencePouch } from './pouch-function/chat-assistance.pouch';
import { CustomerAppConfig } from '../../../../../customer-app.config';
import { LoaderService } from '../../../../structure/loader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PouchDbChatAdapter extends PouchDbAdapter {
	baseDatabaseTemplate: 'chat';
	database = 'chat';
	chatCommonPouch: ChatCommonPouch;
	chatAssistencePouch: ChatAssistencePouch;

	chatList$: Observable<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>> = this.store.select(
		fromState.StateFeature.getChatListState
	);
	_chatList: Subscription;
	chatListRemoteChange: PouchDB.Core.Changes<{}>;
	chatList: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>;

	constructor(
		private store: Store<any>,
		protected config: CustomerAppConfig,
		private loaderService: LoaderService,
		private deviceDetectorService: DeviceDetectorService,
		private httpClient: HttpClient,
		offlineDeviceService: OfflineDeviceService,
		private transmissionService: TransmissionService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.chatCommonPouch = new ChatCommonPouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
		this.chatAssistencePouch = new ChatAssistencePouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
	}
}
