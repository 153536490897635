import { Injectable } from "@angular/core";
import { BrandItemModel } from "../../../model/survey-center/survey.model";
import { SurveyService } from "../../rest/survey-center/survey.service";


@Injectable()
export class BrandsService {

    brandsList: BrandItemModel[] = [];

    constructor(private surveyService: SurveyService) { }

    getBrandList(env: string) {
        this.surveyService.getBrandList({env: env === 'backoffice' ? 'bo' : ''})
            .then(res => {
                this.brandsList = Array.from(res).map(linea => {
                    return {
                        brand: {
                            codice_brand: linea.codiceElemento,
                            nome_brand: linea.descrizioneEstesa,
                            nome_brand_ridotto: linea.descrizioneEstesa.substring(0,2)
                        }
                    };
                });
                // meta brand
                const multibrand_01 = {
                    brand: {
                        codice_brand: "mb_01",
                        nome_brand: "MULTIBRAND",
                        nome_brand_ridotto: "MB"
                    }
                };
                this.brandsList.push(multibrand_01);
            })
            .catch(err => {
                console.log(err);
            });
    }
}