import { createAction, props } from '@ngrx/store';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
// model
import { RestBasePk } from '../../model/rest-base.model';
import { BaseStateModel } from '../../model/state/base-state.model';
import { UserPasswordUpdateModel } from '../../model/structure/password.model';

export enum UserActionEnum {
	UPDATE = '[User] Update',
	LOAD = '[User] Load',
    ERROR = '[User] Error',
    PASSWORD_CHANGE = '[user] Passwor_Change',
    PASSWORD_RECOVERY = '[user] Passwor_Recovery',
    COMPLETED = '[user] Completed',
    RESET = '[user] Reset',
}

export namespace UserStateAction {
	export const load = createAction(
        UserActionEnum.LOAD,
        props<RestBasePk>()
    );
    export const update = createAction(
        UserActionEnum.UPDATE,
        props<BaseStateModel<UserDetailModel>>()
    );
	export const password_change = createAction(
        UserActionEnum.PASSWORD_CHANGE,
        props<BaseStateModel<UserPasswordUpdateModel>>()
    );
    export const password_recovery = createAction(
        UserActionEnum.PASSWORD_RECOVERY,
        props<BaseStateModel<{email: string}>>()
    );
    export const completed = createAction(
        UserActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        UserActionEnum.RESET
    );
    export const error = createAction(
        UserActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
