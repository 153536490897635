import { enableProdMode } from '@angular/core';
// import { platformBrowser } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import 'gsap';

if (environment.production) {
  enableProdMode();
}

// platformBrowser().bootstrapModule(AppModule)
//   .catch(err => console.log(err));

// riprende l'ambiente (profilo) con cui l'applicazione è stata inizializzata (production, local, test, ecc...)
const env = Object.keys(environment).find(k => !!environment[k]);

/**
 * metodo che effettua la chiamata al server node per recuperare il file di environment
 * onSuccess -> funzione pura che viene effettuata una volta terminata la chiamata
 * onError -> funzione pure che viene effettuata se la chiamata va in errore (caso in cui il file non c'è)
 * return: void
 *
 */
function getEnvironment(onSuccess, onError?) {
	const requester = new XMLHttpRequest();
	const rnd = new Date().getTime();
	requester.open('GET', `env-${env}/environment.json?rnd=` + rnd);
	requester.responseType = 'json';
	requester.onload = onSuccess;
	requester.onerror = onError;
	requester.send();
}

/**
 * prima di effettuare il boostrap dell'applicazione angular (lato browser) effettua una chiamata al server per recuperare l'environment
 * una volta recuperato effettua il boostrap inserendo un provider di default con all'interno il json delle configurazioni che verrà poi ripreso
 * nell'AppConfig (RachelloAppConfig in questo caso) per poter popolare l'oggetto con le configurazioni
 */
document.addEventListener('DOMContentLoaded', () => {
	getEnvironment(
		(res: ProgressEvent) => {
			const resConfig = (res.target as XMLHttpRequest).response;
			platformBrowserDynamic([
				{
					provide: 'env-config',
					useValue: resConfig
				}
			])
				.bootstrapModule(AppModule)
				.catch(err => console.error(err));
		},
		error => {
			console.error(error);
		}
	);
});