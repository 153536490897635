import { Injectable } from '@angular/core';

// model
import { BaseStateModel } from '../../model/state/base-state.model';

// pouch instance
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { Observable, skipWhile, take, tap } from 'rxjs';
import { PouchAdapterEnum } from '../../enum/pouch-adapter.enum';
import { PouchDbCommonsOrganizationAdapter } from '../pouchdb/instance/commons/pouchdb-commons-organization.service';
import { PouchDbCommonsBackofficeAdapter } from '../pouchdb/instance/commons/pouchdb-commons-backoffice.service';

@Injectable({
	providedIn: 'root'
})
export class StoreUtilService {
    constructor(
		private pouchDbCommonsOrganizationAdapter: PouchDbCommonsOrganizationAdapter,
		private pouchDbCommonsBackofficeAdapter: PouchDbCommonsBackofficeAdapter,
	) {}
    async getCouchDetailAndReturnInDetailState<D>(
		action: BaseStateModel<BasePouchModel>,
		adapter: PouchAdapterEnum
	): Promise<BaseStateModel<D>> {
		try {
			const actionReturn = <BaseStateModel<D>> action;
			actionReturn.data = await this[adapter].basePouch.getDetail<D>(action.data._id);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw { error: err.error, reason: err.reason, status: err.status };
		}
	}

	async getCouchDetailAndReturnInListState<D>(
		action: BaseStateModel<BasePouchModel>,
		adapter: PouchAdapterEnum
	): Promise<BaseStateModel<D[]>> {
		try {
			const actionReturn = <BaseStateModel<D[]>> action;
			actionReturn.data = [await this[adapter].basePouch.getDetail<D>(action.data._id)];
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	retrieveSyncState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			take(1),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}
}
