import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MediaReplayService } from "@saep-ict/angular-core";
import { Subscription } from "rxjs";
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: "ms-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FramePageComponent implements OnInit, OnDestroy {
  // learn about `static` here: https://angular.io/guide/static-query-migration
  @ViewChild("sidenav") sidenav;

  private _mediaSubscription: Subscription;
  private _routerEventsSubscription: Subscription;

  sidenavOpen = false;
  sidenavMode = "over";
  isMobile = false;

  constructor(
    private router: Router,
    public mediaReplayService: MediaReplayService,
    private cdr: ChangeDetectorRef
  ) {
    this.mediaReplayService.deviceH = document.body.clientHeight;
    this.mediaReplayService.deviceW = document.body.clientWidth;
  }

  ngOnInit() {
    this.cdr.detectChanges();
    this._mediaSubscription =
      this.mediaReplayService.media.pipe().subscribe(
      (change: BreakpointState) => {
        const isMobile = this.mediaReplayService.deviceW < 1281;
        this.isMobile = isMobile;
        this.sidenavOpen = !isMobile;
        this.sidenavMode = isMobile ? 'over': 'side';
      }
    );
    this._routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isMobile) {
        if (this.sidenav) {
          this.sidenav.close();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this._mediaSubscription) {
      this._mediaSubscription.unsubscribe();
    }
  }
}
