import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetRequestCategory } from '../model/asset-request/asset-request-category-list.model';
import { BackendProcessQueueModel } from '../model/asset-request/backend-queue-process.model';
import { TicketModel, TicketStateModel } from '../model/asset-request/ticket.model';
import { CategoryListStateModel } from '../model/category-list.model';
import { ChatDetailStateFeatureSelectionParameterModel, ChatDetailStateModel } from '../model/pouch/chat.model';
import { BaseStateModel } from '../model/state/base-state.model';
import { BodyTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { RemoteChangeState } from '../model/remote-change.model';
import { AbstractListModel, AbstractQueryListModel, PageSettingsModel } from '../model/structure/AbstractListModel';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

// model
export namespace StateFeature {
	// user & context
	export const getUserState = createFeatureSelector<BaseStateModel<UserDetailModel>>('user');
	export const getLoginContextCodeBackofficeState = createFeatureSelector<BaseStateModel<BodyTablePouchModel>>(
		'login-context-code-backoffice'
	);
	export const getLoginContextCodeCompanyState = createFeatureSelector<BaseStateModel<OrganizationPouchModel>>(
		'login-context-code-company'
	);
	// page-settings
	export const getPageSettingsState = createFeatureSelector<PageSettingsModel>('page-settings');
	// remote-change
	export const getRemoteChangeState = createFeatureSelector<RemoteChangeState>('remote-change');
	// category list
	export const getCategoriesState = createFeatureSelector<CategoryListStateModel<AssetRequestCategory>>('categories');
	// chat
	// assistance
	export const getChatListState = createFeatureSelector<
		AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>
	>('chat');
	// assistance | asset-request-center
	export const getAssetRequestChatState = createFeatureSelector<ChatDetailStateModel>('chat-detail');
	export const selectActiveAssetRequestChat = createSelector(
		getAssetRequestChatState,
		(state, param: ChatDetailStateFeatureSelectionParameterModel) => {
			return state[param.side];
		}
	);
	// asset-request-center
	export const getTicketState = createFeatureSelector<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>('ticket');
	export const getTicketListState = createFeatureSelector<AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>>(
		'request-center'
	);
	export const getBackendProcessQueue = createFeatureSelector<BackendProcessQueueModel>('backend-process-queue');
	// organization
	export const getOrganizationList = createFeatureSelector<BaseStateModel<OrganizationPouchModel[]>>('organization-list');
}
