import { Pipe, PipeTransform } from '@angular/core';

export interface EnumIteratorModel {
	id: number;
	description: string;
}

@Pipe({ name: 'enumIterator' })
export class EnumIteratorPipe implements PipeTransform {
	transform(value): EnumIteratorModel[] {
		return Object.keys(value)
			.filter(f => !isNaN(+f))
			.map(m => {
				return {
					description: value[m],
					id: +m
				};
			});
	}
}
