import { Injectable } from '@angular/core';
import { BaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBasePk, RestBaseResponse } from '../../model/rest-base.model';
import { RestGetUserAvatarResponse } from '../../model/user.model';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable()
export class UserService extends BaseApiService {

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/private/users/{!id}/detail'
	})
	getUserDetail: IResourceMethodPromise<RestBasePk, RestBaseResponse<UserDetailModel>>;

	@ResourceAction({
        path: '/users/{!id}/user-icon',
        method: ResourceRequestMethod.Get
    })
    getUserAvatar: IResourceMethodPromise<{id: number | string}, RestGetUserAvatarResponse>;

}
