import { AbstractListModel } from "../structure/AbstractListModel";
import { UserDetailModel } from "@saep-ict/angular-spin8-core";

export class ThreadModel extends AbstractListModel<ThreadItemModel[]>{}

export class ChatModel {
  id?: number;
  thread: ThreadModel;
}

export class ThreadItemModel {
  _id?: string;
  survey_id?: string;
  sender?: string;
  sender_user_info?: UserDetailModel;
  text?: string;
  date_sending?: string;
  type: string = 'chat';
}