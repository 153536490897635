// model
import { createAction, props } from '@ngrx/store';

// store
import { BaseStateModel } from '../../../../../model/state/base-state.model';
import { BodyTablePouchCustomModel } from '../../../../../model/pouch/base-pouch.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

export enum LoginContextCodeBackofficeActionEnum {
	UPDATE = '[login-context-code-backoffice] Update List',
	LOAD = '[login-context-code-backoffice] Load',
	RESET = '[login-context-code-backoffice] Reset',
	ERROR = '[login-context-code-backoffice] Error',
}

export namespace LoginContextCodeBackofficeStateAction {
	export const update = createAction(LoginContextCodeBackofficeActionEnum.UPDATE, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const load = createAction(LoginContextCodeBackofficeActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const reset = createAction(LoginContextCodeBackofficeActionEnum.RESET);
	export const error = createAction(LoginContextCodeBackofficeActionEnum.ERROR, props<BaseStateModel<null>>());
}
