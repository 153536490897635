import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketModel } from '../../../../../model/asset-request/ticket.model';
import { combineLatest } from 'rxjs';
import { UtilService } from '../../../../../service/util/util.service';
import { AssetRequestUtilService } from '../../../../../service/util/asset-request-util.service';
import { skipWhile, map, startWith } from 'rxjs/operators';
import { RestAttachmentListManagerService, SubscribeManagerService } from '@saep-ict/angular-core';
import { MediaReplayService } from '@saep-ict/angular-core';
import { RequestCenterDialogBaseDirective } from '../../../misc/request-center-dialog-base.directive';
import { RequestCenterEnum } from '../../../../../enum/request-center/request-center.enum';
import { StoreUtilService } from '../../../../../service/util/store-util.service';
import { Store } from '@ngrx/store';
import _ from 'lodash';

@Component({
	selector: 'app-dialog-request-center-print-production-flow',
	templateUrl: './dialog-request-center-print-production-flow.component.html',
	styleUrls: ['./dialog-request-center-print-production-flow.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogRequestCenterPrintProductionFlowComponent
	extends RequestCenterDialogBaseDirective<
		DialogRequestCenterPrintProductionFlowComponent,
		TicketModel.AssetItem.PrintProductionFlow
	>
	implements OnInit, OnDestroy {

	hideButton: boolean;	
	constructor(
		/**
		 * @param data contain the following properties
		 * - `asset: AssetState`
		 * - `ticket: TicketStateModel<ASSET>`
		 * - `userType: string` the value must be on the following: 'company', 'backoffice', 'agency'
		 * - `ticketStatus: RequestCenterEnum.Status.base`
		 */
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DialogRequestCenterPrintProductionFlowComponent>,
		private fb: FormBuilder,
		public utilService: UtilService,
		public assetRequestUtilService: AssetRequestUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public mediaReplayService: MediaReplayService,
		public store: Store,
		public utilStoreService: StoreUtilService,
		public restAttachmentListManagerService: RestAttachmentListManagerService
	) {
		super(dialogRef, utilService, store, utilStoreService, restAttachmentListManagerService);
		this.assignDataToDialog(this.data);
		this.requestType = RequestCenterEnum.type.PRINT_PRODUCTION_FLOW;
		this.setAttachmentListManagerConfiguration();
		this.createForm();

		this.subscribeManagerService.populate(this.subscribeBrandAndLineFormChange().subscribe(), 'brand-form-change');

		this.setForm();
		this.hideButton = (this.ticketStatus == this.ticketStatusEnum.PENDING_AGENCY && this.userType == this.requestCenterEnum.User.type.AGENCY )|| 
		(this.ticketStatus == this.ticketStatusEnum.PENDING_EUROITALIA && this.userType == this.requestCenterEnum.User.type.EUROITALIA)

	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribeBrandAndLineFormChange() {
		const brand$ = this.form
			.get('brand')
			.valueChanges.pipe(startWith(this.asset.brand && this.asset.brand ? this.asset.brand : null));
		const line$ = this.form
			.get('line')
			.valueChanges.pipe(startWith(this.asset.line && this.asset.line ? this.asset.line : null));
		return combineLatest(brand$, line$).pipe(
			skipWhile(([brand, line]) => !(brand || line)),
			map(([brand, line]) => {
				this.updateLine(brand ? brand.code : null, line ? line.code : null);
			})
		);
	}

	// widget & utility
	// form
	createForm() {
		// TODO: verificare condizione per il disable
		const isDisabled =
			this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
			this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED
				? false
				: true;

		this.form = this.fb.group({
			type: [{ value: null, disabled: isDisabled }, Validators.required],
			brand: [{ value: null, disabled: isDisabled }, Validators.required],
			line: [{ value: null, disabled: isDisabled }, Validators.required],
			note: [{ value: null, disabled: isDisabled }],

			component_code: [{ value: null, disabled: isDisabled }],
			order_code: [{ value: null, disabled: isDisabled }],
			product_code: [{ value: null, disabled: isDisabled }]
		});
	}

	/**
	 * Aggiorna le option selezionabili di line e duration, invalidandone eventualmente i valori dei campi
	 *
	 * @param {string} [brandFieldValue]
	 * @param {string} [lineFieldValue]
	 * @memberof DialogRequestCenterAdvertisingMaterial
	 */
	updateLine(brandFieldValue?: string, lineFieldValue?: string) {
		this.lineList = [];
		if (brandFieldValue) {
			const brandDetail = this.assetRequestUtilService.categoryList.category_list.find(
				item => item.code === brandFieldValue
			);
			if (brandDetail) {
				this.lineList = brandDetail.category_list;
				if (this.lineList && this.lineList.length > 0) {
					const lineDetail = this.lineList.find(i => i.code === lineFieldValue);
					if (!lineDetail) {
						this.form.get('line').setValue(null, { emitEvent: false });
					}
				} else {
					this.form.get('line').setValue(null, { emitEvent: false });
				}
			}
		} else {
			this.form.get('line').setValue(null, { emitEvent: false });
		}
	}

	/**
	 * Aggiorna il setup generale della form. Il metodo scatta sia in modifica che in creazione dell'asset
	 *
	 * @memberof DialogRequestCenterPrintProductionFlowComponent
	 */
	setForm() {
		const asset: TicketModel.AssetItem.PrintProductionFlow = this.asset;

		this.form.patchValue(
			{
				brand: asset.brand || null,
				component_code: asset.component_code || null,
				line: asset.line || null,
				note: asset.note || null,
				order_code: asset.order_code || null,
				product_code: asset.product_code || null,
				type: asset.type || null
			},
			{ emitEvent: false }
		);
	}

	prepareSaveForm(): TicketModel.AssetItem.PrintProductionFlow {
		const formModel = this.form.getRawValue();
		const newAsset: TicketModel.AssetItem.PrintProductionFlow = {
			code_item: this.asset.code_item,
			brand: formModel.brand || null,
			component_code: formModel.component_code || null,
			line: formModel.line || null,
			note: formModel.note || null,
			order_code: formModel.order_code || null,
			product_code: formModel.product_code || null,
			type: formModel.type || null
		};
		this.setFormAttachmentTypeList(newAsset);
		return newAsset;
	}

	setAttachmentListManagerConfiguration() {
		this.attachmentTypeList =
			[RequestCenterEnum.Asset.AttachmentType.AGENCY, RequestCenterEnum.Asset.AttachmentType.EUROITALIA];
		this.setAttachmentListManagerConfigurationBase();
		// upload_euroitalia
		this.attachmentListManagerConfiguration[RequestCenterEnum.Asset.AttachmentType.EUROITALIA].privilege = {
			add:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
					this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED
				) &&
				this.userType == RequestCenterEnum.User.type.EUROITALIA,
			delete:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
					this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED
				) &&
				this.userType === RequestCenterEnum.User.type.EUROITALIA,
			download: true
		};
		// upload_agency
		this.attachmentListManagerConfiguration[RequestCenterEnum.Asset.AttachmentType.AGENCY].privilege = {
			add:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_EUROITALIA &&
					this.userType === RequestCenterEnum.User.type.EUROITALIA
				) ||
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_AGENCY &&
					this.userType === RequestCenterEnum.User.type.AGENCY
				),
			delete:
				(
					this.ticketStatus == RequestCenterEnum.Status.base.PENDING_EUROITALIA &&
					this.userType === RequestCenterEnum.User.type.EUROITALIA
				) ||
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_AGENCY &&
					this.userType === RequestCenterEnum.User.type.AGENCY
				),
			download: true
		};
	}

	closeDialog() {
		this.dialogRef.close(true);
	}	
}
