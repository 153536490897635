import { TitleCasePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { DateMomentService } from '../../../../service/util/date-moment.service';
import { AssetRequestIdFormatterPipe } from '../../../../shared/pipes/asset-request-id-formatter.pipe';

const injector = Injector.create({
	providers: [
		{ provide: AssetRequestIdFormatterPipe, deps: [] },
		{ provide: TitleCasePipe, deps: [] },
		{ provide: DateMomentService, deps: [] }
	]
});

const assetRequestIdFormatterPipe = injector.get(AssetRequestIdFormatterPipe);
const titleCasePipe = injector.get(TitleCasePipe);
const dateMomentService = injector.get(DateMomentService);

export namespace RequestCenterColumnListPageListBase {
	export const base: ITdDataTableColumn[] = [
		{
			name: '_id',
			label: 'ID',
			sortable: false,
			width: 100,
			format: (v: string) => {
				return assetRequestIdFormatterPipe.transform(titleCasePipe.transform(v).toLocaleUpperCase());
			}
		},
		{
			name: 'status',
			label: 'Status'
		},
		{
			name: 'asset_list_brand',
			label: 'Brand',
			width: 120
		},
		{
			name: 'asset_list_line',
			label: 'Line',
			width: 120
		},
		{
			name: 'asset_list_diffusion_country',
			label: 'Country',
			width: 120
		},
		{
			name: 'asset_list_diffusion_client',
			label: 'Location',
			width: 120
		},
		{
			name: 'request.subject',
			label: 'Subject'
		},
		{
			name: 'request.assignee',
			label: 'Assignee'
		},
		{
			name: 'request.company.client_code',
			label: 'Client Code',
			width: 100,
			numeric: true,
			sortable: true
		},
		{
			name: 'request.company.area_commercial',
			label: 'Commercial Area'
		},
		{
			name: 'request.company.business_name',
			label: 'Business Name',
			sortable: true
		},
		{
			name: 'asset_list_number',
			label: 'Requests',
			width: 80
		},
		{
			name: 'asset_list_type',
			label: 'Type',
			width: 120
		},
		{
			name: 'date_status_update',
			label: 'Status update',
			numeric: true,
			width: 120,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY'),
			sortable: true
		},
		{
			name: 'request.date_request',
			label: 'Requested date',
			numeric: true,
			width: 120,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY'),
			sortable: true
		},
		{
			name: 'request.date_due',
			label: 'Due date',
			numeric: true,
			width: 120,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY'),
			sortable: true
		},
		{
			name: 'request.urgent',
			label: null,
			width: 40
		}
	];
}
