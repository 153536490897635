import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'shorten'
})
export class ShortenPipe implements PipeTransform {
	transform(value: any, limit: number, showEnding: boolean = false) {
		if (value.length > limit) {
			if (showEnding) {
				return `${value.substring(value.length - limit, value.length)}`;
			} else {
				return `${value.substr(0, limit)}...`;
			}
		} else {
			return value;
		}
	}
}
