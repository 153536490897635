import { createAction, props } from '@ngrx/store';
// model
import { ChatDetailStateFeatureSelectionParameterModel } from '../../../model/pouch/chat.model';

export enum ChatDetailActionEnum {
	UPDATE = '[Chat Detail] Update',
	LOAD = '[Chat Detail] Load',
	LOAD_MESSAGE_LIST = '[Chat Detail] Load Message List',
	UPDATE_REMOTE_LAST_ACCESS = '[Chat Detail] Update Remote Last Access',
	CLOSE_CONNECTION = '[Chat Detail] Close Connection',
	RESET = '[Chat Detail] Reset',
	ERROR = '[Chat Detail] Error',
}

export namespace ChatDetailStateAction {
	export const update = createAction(
		ChatDetailActionEnum.UPDATE,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const load = createAction(
		ChatDetailActionEnum.LOAD,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const loadMessageList = createAction(
		ChatDetailActionEnum.LOAD_MESSAGE_LIST,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const updateRemoteLastAccess = createAction(
		ChatDetailActionEnum.UPDATE_REMOTE_LAST_ACCESS,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const closeConnection = createAction(
		ChatDetailActionEnum.CLOSE_CONNECTION,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const reset = createAction(
		ChatDetailActionEnum.RESET,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const error = createAction(
		ChatDetailActionEnum.ERROR,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
}
