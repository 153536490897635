import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'dateTranformFormatter'
})
export class DateTranformFormatter implements PipeTransform {
	transform(value: any) {
        const dateFormatted = new DatePipe('en-US').transform(value, 'dd/MM/yyyy, HH:mm')
		return dateFormatted
	}
}