import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
// model
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';
import { CategoryListStateModel } from '../../model/category-list.model';
import { AssetRequestCategory } from '../../model/asset-request/asset-request-category-list.model';

export const initialState: CategoryListStateModel<AssetRequestCategory> = null;

const _reducer = createReducer(
	initialState,
	on(CategoryListAction.update, (state, categories) => categories),
	on(CategoryListAction.loadBrandAndLine, state => state),
	on(CategoryListAction.loadLine, state => state),
	on(CategoryListAction.reset, (state, categories) => null)
);

export function reducer(
	state: CategoryListStateModel<AssetRequestCategory>,
	action: TypedAction<CategoryListActionEnum>
) {
	return _reducer(state, action);
}
