import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BrandItemModel } from '../../model/survey-center/survey.model';
import { ThousandPipe } from '../../shared/pipes/thousand-separator.pipe';
import { BaseStateModel } from '../../model/state/base-state.model';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { PermissionKeyEnum } from '../../router/permission-key.enum';
import { isArray } from 'lodash';
import { AngularCoreUtilService, SentencecasePipe } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export class FormatPlaceholderSpecificCase {
	from: string;
	to: string;
}

@Injectable({
	providedIn: 'root'
})
export class UtilService extends AngularCoreUtilService {
	constructor(
		private thousandPipe: ThousandPipe,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		public sentencecasePipe: SentencecasePipe
	) {
		super(snackBar, translate, sentencecasePipe)
	}

	replaceAll(valueToTest: string, regExp: RegExp, replacer: string = '') {
		if (!valueToTest) {
			return;
		}
		if (replacer) {
			replacer = '';
		}

		let res: string = valueToTest;

		while (regExp.test(res)) {
			res = res.replace(regExp, replacer);
		}

		return res.trim();
	}

	getEnumValue(index: number, enumType) {
		const enumValueArray = Object.keys(enumType).map(enumKey => enumType[enumKey]);
		if (index > enumValueArray.length - 1) {
			return undefined;
		}
		const val = enumValueArray[index];
		return val;
	}

	/**
	 * Get an object from Enum
	 * @param args enum with this structure `CONSTANT = number`
	 */
	getObjectfromEnum(arg): { index: number; name: string }[] {
		return Object.keys(arg)
			.filter(e => !isNaN(+e))
			.map(o => {
				return { index: +o, name: arg[o] };
			});
	}

	getElementIndex(array: any[], property: string, value: any) {
		value = typeof value === 'number' ? value.toString() : value;
		for (let i = 0; i < array.length; i++) {
			const propToCheck =
				typeof array[i][property] === 'number' ? array[i][property].toString() : array[i][property];
			if (propToCheck === value) {
				return i;
			}
		}
		return 0;
	}

	isElementIncluded(array: any[], property: string, value: any) {
		value = typeof value === 'number' ? value.toString() : value;
		for (let i = 0; i < array.length; i++) {
			const propToCheck =
				typeof array[i][property] === 'number' ? array[i][property].toString() : array[i][property];
			if (propToCheck === value) {
				return true;
			}
		}
		return false;
	}

	/**
	 *
	 * Permette la selezione delle options di una mat-select nel caso in cui venga bindato nel mat-option [value] un oggetto
	 * e non un semplice ID. Le condizioni dell'if dovrebbero diventare parametriche.
	 *
	 * @param options array che genera le options
	 * @param values valori da selezionare derivanti ad esempio da una response
	 */
	compareObjectForMatSelect(options, values) {
		let a = [];
		options.forEach(o => {
			values.forEach(v => {
				if (
					o.organization === v.organization &&
					o.channel === v.channel &&
					o.group_customer === v.group_customer
				) {
					a.push(o);
				}
			});
		});
		return a;
	}

	/**
	 * Dato un Array di brand, restituisce l'elemento avente proprietà selected: true
	 *  */
	brandSelected(array) {
		let brand: BrandItemModel;

		if (array) {
			array.filter(i => {
				if (i.selected) {
					brand = i;
				}
			});
			return brand || array[0];
		}
	}

	getBrandIndex(array, code) {
		for (let i = 0; i < array.length; i++) {
			if (array[i].brand.codice_brand == code) {
				return i;
			}
		}
	}

	/**
	 * Formatta il placeholder del mat-inpu
	 *
	 * 1 sostituisce "and" con "_&_"
	 * 2 sostituisce "_" e "-" con " "
	 * (3 nel template agisce la pipe titlecase)
	 *
	 * @param field la proprietà passata anche al formControlName
	 * @param handleSpecificCase se si vogliono gestire casistiche particolari
	 */
	formatPlaceholder(field: string, handleSpecificCase?: FormatPlaceholderSpecificCase[]) {
		if (field) {
			if (handleSpecificCase && handleSpecificCase.length > 0) {
				handleSpecificCase.forEach(specificCase => {
					field = field.replace(specificCase.from, specificCase.to);
				});
			}

			field = field.replace(/\_and_/g, '_&_');
			field = field.replace(/[_-]/g, ' ');
			return field;
		}
	}

	/**
	 * Formatta e converte il valore editato nel form e popola la colonna che ospita la conversione in EUR
	 *
	 * @param value valore espresso dall'utente nella valuta scelta (vedere CurrencyModel)
	 */
	getEurConvertedValue(value, exchange_rate: number) {
		let n = Math.round((parseInt(value) / exchange_rate) * 1000) / 1000;
		let s = n.toString();
		let a = s.split('.');
		let formattedValue = this.thousandPipe.transform(a[0]);
		// if (a[1]) {
		//   formattedValue = formattedValue + "," + a[1]
		// }
		return formattedValue;
	}

	downloadBase64(f) {
		const base64 = f['data'].split('base64,');
		const byteCharacters = atob(base64[1]);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, { type: base64[0].replace('data:', '') });
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && (<any>window.navigator).msSaveOrOpenBlob) {
			(<any>window.navigator).msSaveOrOpenBlob(blob);
			return;
		}
		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = data;
		link.download = f['name'];
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
		setTimeout(() => {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	}

	/**
	 * Restituisce l'oggetto privo delle proprietà settate a null, undefined o array vuoto
	 * @param object any
	 */
	deleteEmptyProperties(object) {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				if (
					object[key] === null ||
					object[key] === undefined ||
					object[key].length === 0 ||
					(Object.keys(object[key]).length === 0 && object[key].constructor === Object)
				) {
					delete object[key];
				}
			}
		}
		return object;
	}

	// permission
	hasAtLeastOnePermission(user: BaseStateModel<UserDetailModel>, permission: PermissionKeyEnum[]) {
		if (user.data && user.data.current_permission && user.data.current_permission.permission) {
			for (let i = 0; i < permission.length; i++) {
				if (user.data.current_permission.permission.find(p => p === permission[i])) {
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * Get the extensions declared at the end of the end of the filename
	 * @param fileName it include also the extension of the file
	 */
	getFileExtension(fileName: string): string {
		let fileExtension = '';
		if (fileName) {
			const fileNameArray = fileName.split('.');
			fileExtension = fileNameArray[fileNameArray.length - 1];
		}
		return fileExtension;
	}

	// form
	/**
	 * Setta l'eventuale validatore required
	 * @param v prop. required dell'elemento nella configurazione del form dinamico
	 */
	setRequired(v: boolean) {
		if (v) {
			return [Validators.required];
		}
	}

	concatArrays(...arrayList: object[][]) {
		let finalArray: object[] = [];
		for (const array of arrayList) {
			if (array && isArray(array)) {
				finalArray = finalArray.concat(array);
			}
		}
		return finalArray;
	}
}
