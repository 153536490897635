import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { AuthService } from '../service/rest/auth.service';
import { UserService } from '../service/rest/user.service';
import { ChatService } from '../service/rest/chat.service';
import { AssetRequestChatService } from '../service/rest/asset-request-chat.service';
import { AssetRequestService } from '../service/rest/asset-request.service';
import { RequestCenterDashboardColumnService } from '../service/td-data-table/implementation/asset-request/request-center.service';
// survey-center
import { SurveyListComponent } from './survey-center/survey-list/survey-list.component';
import { SurveyDetailComponent } from './survey-center/survey-detail/survey-detail.component';
import { BrandListComponent } from './survey-center/survey-detail/brand-list/brand-list.component';
import { FrontofficeComponent } from './survey-center/survey-detail/frontoffice/frontoffice.component';
import { SurveyFormComponent } from './survey-center/survey-detail/frontoffice/survey-form/survey-form.component';
import { BackofficeComponent } from './survey-center/survey-detail/backoffice/backoffice.component';
import { SurveyCheckerComponent } from './survey-center/survey-detail/backoffice/survey-checker/survey-checker.component';
import { SurveyListColumnService } from '../service/td-data-table/implementation/survey-center/survey-list-column.service';
import { BrandListColumnService } from '../service/td-data-table/implementation/survey-center/brand-list-column.service';
import { CheckerListColumnService } from '../service/td-data-table/implementation/survey-center/survey-checker-list-column.service';

// chat-center
import { ChatComponent } from './chat/chat.component';
import { ChatDetailComponent } from './chat/chat-detail/chat-detail.component';
import { ChatColumnService } from '../service/td-data-table/implementation/chat.service';

// asset-request
import { RequestCenterComponent } from './asset-request/request-center/request-center.component';
import { RequestCenterDetailComponent } from './asset-request/request-center/request-center-detail/request-center-detail.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AssetRequestDashboardComponent } from './asset-request/asset-request-dashboard/asset-request-dashboard.component';

@NgModule({
    imports: [WidgetModule, SharedModule],
    declarations: [
        // survey-center
        SurveyListComponent,
        SurveyDetailComponent,
        BrandListComponent,
        FrontofficeComponent,
        SurveyFormComponent,
        BackofficeComponent,
        SurveyCheckerComponent,
        // chat-center
        ChatComponent,
        ChatDetailComponent,
        // asset-request
        RequestCenterComponent,
        RequestCenterDetailComponent,
        AssetRequestDashboardComponent,
        NotFoundComponent
    ],
    providers: [
        AuthService,
        UserService,
        // data
        ChatService,
        AssetRequestChatService,
        AssetRequestService,
        // td-data-table columns
        ChatColumnService,
        SurveyListColumnService,
        BrandListColumnService,
        CheckerListColumnService,
        RequestCenterDashboardColumnService,
        NotFoundComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
