import { Injectable } from "@angular/core";
import { BaseApiService } from "./baseApi.service";
import { ResourceAction, ResourceRequestMethod, IResourceMethod } from "@ngx-resource/core";
import { MessageModel } from "../../model/pouch/chat.model";

@Injectable()
export class ChatService extends BaseApiService {

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/chat/send-message'
  })
  sendMessage: IResourceMethod<MessageModel, void>;

}
