import { Injectable } from '@angular/core';

// model
import { MessageModel, PartecipantList, ChatDetailStateFeatureSelectionParameterModel } from '../../model/pouch/chat.model';
import { AbstractListModel } from '../../model/structure/AbstractListModel';
import { RestGetUserAvatarResponse } from '../../model/user.model';
import { RemoteChangeState } from '../../model/remote-change.model';
import { WatchRemoteChangesConfiguraton } from '../../model/pouch/base-pouch.model';
import { ChatInstanceTypeEnum } from '../../enum/chat.enum';
import { PouchAdapterEnum } from '../../enum/pouch-adapter.enum';
import { BaseStateModel } from '../../model/state/base-state.model';

// pouch adapter
import { PouchDbChatAdapter } from '../pouchdb/instance/chat/assistance/pouchdb-chat-adapter.service';
import { PouchDbChatAssetRequestAdapter } from '../pouchdb/instance/chat/asset-request/pouchdb-chat-asset-request-adapter.service';

// service
import { UserService } from '../rest/user.service';
import { UtilService } from './util.service';

// store
import { StateFeature } from '../../state/index';
import { Store } from '@ngrx/store';
import { RemoteChangeStateAction } from '../../state/remote-change/remote-change.actions';
import { ChatStateAction } from '../../state/chat/chat.actions';
import { ChatDetailStateAction } from '../../state/chat/chat-detail/chat-detail.actions';

// widget & utility
import { Observable, Subscription } from 'rxjs';
import { RestBaseMessageError, RestBaseResponse } from '../../model/rest-base.model';
import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

@Injectable({
	providedIn: 'root'
})
export class ChatFunctionService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	_user: Subscription;
	user: BaseStateModel<UserDetailModel>;
	chatList$: Observable<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>> = this.store.select(StateFeature.getChatListState);
	_chatList: Subscription;
	chatList: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>;

	chatDetailRemoteChange = {};

	constructor(
		private pouchDbChatAdapter: PouchDbChatAdapter,
		private pouchDbChatAssetRequestAdapter: PouchDbChatAssetRequestAdapter,
		private userService: UserService,
		public store: Store<any>,
		private utilService: UtilService,
	) {
		this._user = this.user$.subscribe((res) => {
			if (res) {
				this.user = res;
			}
		});
		this._chatList = this.chatList$.subscribe((res) => {
			if (res && res.data) {
				this.chatList = res;
			}
		});
	}

	/**
	 * Pouch: effettuata la getDetail e attiva il listener del DB remoto per i documenti recuperati
	 *
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @returns {Promise<ChatDetailStateFeatureSelectionParameterModel>}
	 * @memberof ChatFunctionService
	 */
	load(action: ChatDetailStateFeatureSelectionParameterModel): Promise<ChatDetailStateFeatureSelectionParameterModel> {
		const watchRemotConfiguration: WatchRemoteChangesConfiguraton = {
			adapter: PouchAdapterEnum[action.side],
			id_reference: null,
			selector: {
				thread_id: null
			},
			response_handler: this.remoteChangeResponseHandlerDetail,
			data: action
		};
		// attivazione del listener per i documenti che potrebbero non ancora esistere su couch
		// ma dei quali si conosce l'ID
		if (action.chat._id) {
			watchRemotConfiguration.id_reference = action.chat._id;
			watchRemotConfiguration.selector.thread_id = action.chat._id;
			this.remoteChangeListener(watchRemotConfiguration);
		}
		const actionReturn: ChatDetailStateFeatureSelectionParameterModel = {
			side: action.side
		};
		return this[PouchAdapterEnum[action.side]].chatCommonPouch.getDetail(action.chat._id, action.chat.thread.pagination, this.user.data.id)
			.then(res => {
				actionReturn.chat = res;
				actionReturn.chat.thread = action.chat.thread;
				// attivazione del listener per i documenti restituiti dalla getDetail()
				if (!action.chat._id) {
					watchRemotConfiguration.id_reference = actionReturn.chat._id;
					watchRemotConfiguration.selector.thread_id = actionReturn.chat._id;
					this.remoteChangeListener(watchRemotConfiguration);
				}
				return actionReturn;
			})
			.catch((error) => {
				this.store.dispatch(ChatDetailStateAction.error({side: action.side}));
				throw {error: error, side: action.side};
			});
	}

	/**
	 * Pouch: effettua getList() e formatta il risultato per restituire chatListState
	 *
	 * @returns {Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>>}
	 * @memberof ChatFunctionService
	 */
	loadList(): Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>> {
		const watchRemotConfiguration: WatchRemoteChangesConfiguraton = {
			adapter: PouchAdapterEnum.assistance,
			id_reference: ChatInstanceTypeEnum.assistance_list,
			selector: {
				type: "thread"
			},
			response_handler: this.remoteChangeResponseHandlerList
		};
		this.remoteChangeListener(watchRemotConfiguration);
		return this[PouchAdapterEnum.assistance].chatAssistencePouch.getList()
			.then(res => {
				const chatState: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]> = {
					data: []
				};
				res.docs.forEach(i => {
					chatState.data.push(
						{
							side: ChatInstanceTypeEnum.assistance,
							chat: i
						}
					);
				});
				return chatState;
			});
	}

	/**
	 * Pouch: effettua getMessageFilteredList
	 *
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @returns {Promise<ChatDetailStateFeatureSelectionParameterModel>}
	 * @memberof ChatFunctionService
	 */
	loadMessageFilteredList(action: ChatDetailStateFeatureSelectionParameterModel): Promise<ChatDetailStateFeatureSelectionParameterModel> {
		const actionReturn: ChatDetailStateFeatureSelectionParameterModel = {
			side: action.side,
			chat: action.chat
		};
		return this[PouchAdapterEnum[action.side]].chatCommonPouch.getMessageFilteredList(action.chat._id, action.chat.thread.pagination, action.chat.thread.filters)
			.then(res => {
				// questa casistica scatta quando viene mandato o ricevuto un nuovo messaggio
				// va sostituito con un metodo che permetta di riallineare la paginazione dello stato applicativo
				// con quella dei dati serviti da remoto
				if (actionReturn.chat.thread.pagination.page_current === 1) {
					actionReturn.chat.thread.data = [];
				}
				for (const m of res.data) {
					const message: MessageModel = JSON.parse(JSON.stringify(m));
					// aggiunge le informazioni dell'utente in caso non si tratti dell'utente autenticato
					// TODO: eliminare tutti i toString() una volta stabilizzato user.id: number
					if (message.sender.toString() !== this.user.data.id.toString()) {
						message.sender_user_info = actionReturn.chat.partecipant_list.filter(i => i.id.toString() === message.sender.toString())[0];
					}
					if (actionReturn.chat.thread.pagination.page_current !== 1) {
						actionReturn.chat.thread.data.unshift(message);
					} else {
						actionReturn.chat.thread.data.push(message);
					}
				}
				return actionReturn;
			});
	}

	/**
	 * Rest: effettua getUserInfoById incorporando i dati in partecipant_list, formatta il nome della chat
	 *
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @returns {Promise<ChatDetailStateFeatureSelectionParameterModel>}
	 * @memberof ChatFunctionService
	 */
	async loadMetaInfo(action: ChatDetailStateFeatureSelectionParameterModel): Promise<ChatDetailStateFeatureSelectionParameterModel> {

		for(let i = 0; i < action.chat.partecipant_list.length; i++){
			if(!action.chat.partecipant_list[i].username){
				await this.userService
					.getUserDetail({ id: action.chat.partecipant_list[i].id })
					.then((res: RestBaseResponse<UserDetailModel>) => {
						// Bonifica dati mancanti
						action.chat.partecipant_list[i].username = res.data.username;
						action.chat.partecipant_list[i].first_name = res.data.first_name;
						action.chat.partecipant_list[i].last_name = res.data.last_name;

						// Recupero la lista dei Company Code
						const B2bCtxApp = res.data.context_application_list.find(ctx_app => ctx_app.code === ContextApplicationItemCodeEnum.B2B);
						action.chat.partecipant_list[i].company = B2bCtxApp ? B2bCtxApp.context_code_list.map(B2bCtxCode => B2bCtxCode.code) : [];
					})
					.catch((err: RestBaseMessageError) => {
						throw new Error(err.body.detail);
					});
			}

			// Recupero l'avatar
			if(!action.chat.partecipant_list[i].avatar){
				await this.userService.getUserAvatar({id: action.chat.partecipant_list[i].id})
					.then((res: RestGetUserAvatarResponse) => {
						action.chat.partecipant_list[i].avatar = res.icon ? res.icon : null
					})
			}

		}

		action.chat.name = this.returnChatName(action.chat.partecipant_list);
		return action;

	}

	/**
	 * Rest: effettua un'iterazione di loadMetaInfo() nel contesto di lista
	 *
	 * @param {AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>} chatList
	 * @returns {Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>>}
	 * @memberof ChatFunctionService
	 */
	loadListMetaInfo(chatList: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>): Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>> {
		const promises = [];
		const chatListState: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]> = {
			data: []
		};
		for (let i = 0; i < chatList.data.length; i++) {
			promises.push(
				this.loadMetaInfo(chatList.data[i])
					.then(res => {
						chatListState.data[i] = res;
					})
			);
		}
		
		return Promise.all(promises).then(() => {
			return chatListState;
		});
	}

	/**
	 * Pouch: effettua l'aggiornamento dell'ultimo accesso al thread da parte dell'utente che apre in visualizzazione
	 * il dettaglio
	 *
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @returns {Promise<ChatDetailStateFeatureSelectionParameterModel>}
	 * @memberof ChatFunctionService
	 */
	putUserLastAccess(action: ChatDetailStateFeatureSelectionParameterModel): Promise<ChatDetailStateFeatureSelectionParameterModel> {
		const actionReturn: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(JSON.stringify(action));
		delete actionReturn.chat.thread;
		return this[PouchAdapterEnum[action.side]].chatCommonPouch.putUserLastAccess(actionReturn.chat)
			.then(res => {
				actionReturn.chat.partecipant_list = res.partecipant_list;
				actionReturn.chat.thread = action.chat.thread;
				return actionReturn;
			});
	}

	/**
	 * Rest: quando scatta il listener di lista, aggiorna chatListState dopo aver recuperato le nuove informazioni
	 * (loadMetaInfo()) dell'elemento modificato in remoto
	 *
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @returns {Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>>}
	 * @memberof ChatFunctionService
	 */
	updateListItem(action: ChatDetailStateFeatureSelectionParameterModel): Promise<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>>{
		const chatList: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]> = JSON.parse(JSON.stringify(this.chatList));
		return this.loadMetaInfo(action).then(res => {
			chatList.data[this.utilService.getElementIndex(this.chatList.data, '_id', action.chat._id)] = res;
			return chatList;
		});
	}

	/**
	 * Pouch Listener: notifica le modifiche rilevate sul DB remoto per i documenti che rispettano la query della
	 * prop. filter. Ogni listener è salvato in un oggetto referenziato dall'ID del thread.
	 * @param {ChatDetailStateFeatureSelectionParameterModel} action
	 * @memberof ChatFunctionService
	 */
	remoteChangeListener(config: WatchRemoteChangesConfiguraton) {
		if (!this.chatDetailRemoteChange[config.id_reference]) {
			const instance: PouchDB.Core.Changes<{}> = this[config.adapter].chatCommonPouch.db.changes({
				since: 'now',
				live: true,
				include_docs: true,
				selector: config.selector
			})
			.on('change', change => {
				config.response_handler(this.store, change, config.data);
			});
			this.chatDetailRemoteChange[config.id_reference] = instance;
		}
	}

	remoteChangeResponseHandlerDetail(
		store: Store<any>,
		change,
		action: ChatDetailStateFeatureSelectionParameterModel
	) {
		const remoteChange: RemoteChangeState = {
			chatDetailState: {
				side: action.side,
				chat: {
					_id: change.doc['thread_id'],
					thread: {
						data: [
							change.doc
						]
					}
				}
			}
		};
		store.dispatch(RemoteChangeStateAction.update(remoteChange));
	}

	remoteChangeResponseHandlerList(
		store: Store<any>,
		change,
		action: ChatDetailStateFeatureSelectionParameterModel
	) {
		const remoteChange: ChatDetailStateFeatureSelectionParameterModel = {
			side: ChatInstanceTypeEnum.assistance,
			chat: change.doc
		};
		store.dispatch(ChatStateAction.updateListItem(remoteChange));
	}

	/**
	 * Pouch:rimuove il listener salvato da remoteChangeListener()
	 *
	 * @param {string} id_reference
	 * @memberof ChatFunctionService
	 */
	cancelListener(id_reference: string) {
		if (this.chatDetailRemoteChange[id_reference]) {
			this.chatDetailRemoteChange[id_reference].cancel();
			delete this.chatDetailRemoteChange[id_reference];
		}
	}

	/**
	 * Util: crea il nome della chat concatenando gli username dei partecipanti al thread
	 *
	 * @param {PartecipantList[]} partecipantUserInfo
	 * @returns
	 * @memberof ChatFunctionService
	 */
	returnChatName(partecipantUserInfo: PartecipantList[]) {
		let chatName: string;
		for (let i = 0; i < partecipantUserInfo.length; i++) {
			if (partecipantUserInfo[i].id !== this.user.data.id) {
				chatName =
					chatName ?
					chatName  + ', ' + partecipantUserInfo[i].username :
					partecipantUserInfo[i].username;
			}
		}
		return chatName;
	}

}
