import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {
	// debugger;
	languages = ['en', 'it'];
	fallbackLang = this.languages[0];

	constructor(
		public translate: TranslateService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.setLanguage();
		this.registerIcons();
	}

	setLanguage() {
		// translate configuration
		this.translate.addLangs(this.languages);
		this.translate.setDefaultLang(this.languages[0]);
    this.translate.use(this.languages[0]);
	}

	registerIcons() {
		this.matIconRegistry.addSvgIcon(
			'custom-hamburger',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/icon-hamburger.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-xlsx',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/xlsx_icon.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-pdf',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/pdf_icon.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-ppt',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/ppt_icon.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-doc',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/doc_icon.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'custom-generic',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/generic_icon.svg')
		);
	}
}
