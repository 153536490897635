import { Injectable } from '@angular/core';

export class IconMapClass {
	icon: string;
	extension: string[];
}

@Injectable({
	providedIn: 'root'
})
export class IconUtilService {
	iconMap: IconMapClass[] = [
		{
			icon: 'custom-xlsx',
			extension: ['xlsx', 'xls', 'csv']
		},
		{
			icon: 'custom-pdf',
			extension: ['pdf']
		},
		{
			icon: 'custom-ppt',
			extension: ['ppt', 'pptx']
		},
		{
			icon: 'custom-doc',
			extension: ['doc', 'docx', 'txt', 'rtf', 'odt']
		}
	];

	getFileIcon(fileExtension: string): string {
		fileExtension = fileExtension.toLowerCase();
		let iconName = 'custom-generic';
		this.iconMap.forEach(i => {
			if (i.extension.includes(fileExtension)) {
				iconName = i.icon;
			}
		});
		return iconName;
	}
}
