import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Injectable } from "@angular/core";

@Injectable()
export class CheckerListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		{
            name: 'name',
            label: 'Name'
          },
          {
            name: 'value',
            label: 'Value',
            numeric: true
          },
          {
            name: 'value_eur',
            label: 'Value (EUR)',
            numeric: true
          },
          {
            name: 'file_latest',
            label: 'Latest file',
            numeric: true
          }
	];
}