import { ITdDataTableColumn } from '@covalent/core/data-table';
import { RequestCenterEnum } from '../../../../enum/request-center/request-center.enum';

export namespace RequestCenterColumnListPageDetailBase {
	export const base: ITdDataTableColumn[] = [
		{
			name: 'index',
			label: 'No.',
			width: 50
		},
		{
			name: 'brand.description',
			label: 'Brand',
			width: { min: 100 }
		},
		{
			name: 'line.description',
			label: 'Line',
			width: { min: 100 }
		},
		// {
		// 	name: 'note',
		// 	label: 'Comment'
		// },
		{
			name: 'product_code',
			label: 'Product code'
		},
		{
			name: 'component_code',
			label: 'Component code'
		},
		{
			name: 'order_code',
			label: 'PO number'
		},
		// {
		// 	name: 'upload_frontoffice',
		// 	label: 'Examples',
		// 	width: 80
		// },
		{
			name: RequestCenterEnum.Asset.AttachmentType.AGENCY,
			label: 'Attachment',
			width: 100
		},
		{
			name: 'type',
			label: 'Type',
			width: { min: 100 }
		}
	];
}
