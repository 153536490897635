import { createAction, props } from '@ngrx/store';
// model
import { CategoryListStateModel } from '../../model/category-list.model';
import { AssetRequestCategory } from '../../model/asset-request/asset-request-category-list.model';

export enum CategoryListActionEnum {
    UPDATE  = '[Category List] Update',
    LOAD_BRAND_AND_LINE = '[Category List] Load Brand',
    LOAD_LINE = '[Category List] Load Line',
    RESET   = '[Category List] Reset',
    ERROR   = '[Category List] Error'
}

export namespace CategoryListAction {
	export const update = createAction(
        CategoryListActionEnum.UPDATE,
        props<CategoryListStateModel<AssetRequestCategory>>()
    );
    export const loadBrandAndLine = createAction(
        CategoryListActionEnum.LOAD_BRAND_AND_LINE,
        props<CategoryListStateModel<AssetRequestCategory>>()
    );
    export const loadLine = createAction(
        CategoryListActionEnum.LOAD_LINE,
        props<CategoryListStateModel<AssetRequestCategory>>()
    );
    export const reset = createAction(
        CategoryListActionEnum.RESET
    );
}
