import { Injectable } from '@angular/core';
import { AssetRequestUtilService } from '../util/asset-request-util.service';
import { AssetRequestModel } from '../../model/asset-request/request-center.model';
import { DialogRequestCenterAdvertisingMaterial } from '../../page/asset-request/widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../../page/asset-request/widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { requestCenterConfiguration } from '../../constant/request-center/request-center-configuration.constant';
import { PageSettingsModel } from '../../model/structure/AbstractListModel';
import { PouchDbAssetRequestCenterAdapter } from '../pouchdb/instance/asset-request-center/pouchdb-asset-request-center-adapter.service';
import { ExportExcelConstants } from '../../constant/request-center/export-excel/export-excel.constants';

@Injectable({
	providedIn: 'root'
})
export class AssetRequestExportExcelService {
	userType: string;
	requestType: string;
	requestConfiguration: AssetRequestModel.Configuration<
		DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
	>;

	constructor(
		public assetRequestUtilService: AssetRequestUtilService,
		private pouchDbAssetRequestAdapter: PouchDbAssetRequestCenterAdapter
	) {}

	download(pageSettings, userType: string, requestType: string) {
		let export_config: ExportExcelConstants.ExportConfig = this.setConfig(userType, requestType);
		const filterSettings: PageSettingsModel = this.setFilterSettings(pageSettings);

		this.pouchDbAssetRequestAdapter.assetRequestCenterPouch
			.loadTicketFilteredList(filterSettings)
			.then(ticketList => {
				ExportExcelConstants.exportExcel(ticketList.docs, export_config);
			});
	}

	private setFilterSettings(pageSettings: any) {
		let settings = Object.assign({}, pageSettings);
		settings.pagination = { page_current: 1, page_size: 50000 };
		const pageSettingsRemap: PageSettingsModel = JSON.parse(JSON.stringify(settings));
		pageSettingsRemap.filters.status_list = this.assetRequestUtilService.returnStatusValueNumberList(
			pageSettingsRemap.filters.status_list
		);
		return pageSettingsRemap;
	}

	private setConfig(userType: string, requestType: string) {
		this.userType = userType;
		this.requestType = requestType;
		this.requestConfiguration = requestCenterConfiguration.find(i => i.type === this.requestType);
		let export_config: ExportExcelConstants.ExportConfig = {
			userType: this.userType,
			requestType: this.requestType,
			requestConfiguration: this.requestConfiguration,
			assetRequestUtilService: this.assetRequestUtilService
		};
		return export_config;
	}
}
