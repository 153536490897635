import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SurveyConfiguration } from '../../model/survey-center/survey.model';

@Injectable({
	providedIn: 'root'
})
export class SurveyListUtilService {
	private surveyListConfiguration: SurveyConfiguration[] = [
		{
			type: 'budget',
			commercial_area: true,
			duration_month: true,
			notifications_year: 12,
			template: false,
			currency: true,
			validation: true,
			multibrand: false
		},
		{
			type: 'media-marketing',
			commercial_area: true,
			duration_month: true,
			notifications_year: 12,
			template: true,
			currency: true,
			validation: true,
			multibrand: false
		},
		{
			type: 'recommended-retail-prices',
			commercial_area: true,
			duration_month: false,
			notifications_year: 6,
			template: true,
			currency: false,
			validation: false,
			multibrand: false,

		},
		{
			type: 'rankings',
			commercial_area: true,
			duration_month: false,
			notifications_year: 4,
			template: false,
			currency: false,
			validation: false,
			multibrand: false
		},
		{
			type: 'sales-reports',
			commercial_area: true,
			duration_month: false,
			notifications_year: 3,
			template: true,
			currency: false,
			validation: false,
			multibrand: false
		},
		{
			type: 'visibility',
			commercial_area: true,
			duration_month: false,
			notifications_year: 12,
			template: false,
			currency: false,
			validation: false,
			multibrand: false
		},
		{
			type: 'travel-retail-visibility',
			commercial_area: false,
			duration_month: false,
			notifications_year: 12,
			template: false,
			currency: false,
			validation: false,
			multibrand: true
		}
	];

	public getSurveyConf(type: string): SurveyConfiguration {
		return _.find(this.surveyListConfiguration, { type: type });
	}
}
