import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UtilDownload } from '@saep-ict/angular-core';
import { CustomerAppConfig } from '../../customer-app.config';

@Injectable({
	providedIn: 'root'
})
export class BucketManagerService {
	constructor(
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private appConfig: CustomerAppConfig
	) {}

	bucketManagerDownloadTemplate(pathUrl: string, pathQueryParam: string) {
		if (UtilDownload.isPopupBlockerActive()) {
			this.snackBar.open(this.translate.instant('general.download_popup_blocker'), 'OK');
		} else {
			const a = document.createElement('a');
			a.setAttribute('target', '_blank');
			a.setAttribute(
				'href',
				`${this.appConfig.envConfig.bucketManager.be_url}/${pathUrl}/download-multipart?path=/${pathQueryParam}&token=${this.appConfig.token}`
			);
			a.click();
		}
	}
}
