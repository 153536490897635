import { FormGroup, AbstractControl } from '@angular/forms';

export class FormValidatorCustom {
	constructor() {}

	public static match2fields(first, second) {
		return (c: FormGroup) => {
			return c.controls && c.controls[first].value === c.controls[second].value
				? null
				: {
						passwordsEqual: {
							valid: false
						}
				  };
		};
	}

	public static email(c: AbstractControl) {
		const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (c.value === '' || c.value === null) {
			return null;
		}
		return EMAIL_REGEXP.test(c.value) ? null : { valid: false };
	}

	public static minNumber(number) {
		return (c: FormGroup) => {
			if (c.value !== null) {
				return parseFloat(c.value) >= number
					? null
					: {
							diffSettings: {
								valid: false
							}
					  };
			} else {
				return null;
			}
		};
	}

	public static maxNumber(number) {
		return (c: FormGroup) => {
			if (c.value !== null) {
				return parseFloat(c.value) <= number
					? null
					: {
							diffSettings: {
								valid: false
							}
					  };
			} else {
				return null;
			}
		};
	}

	public static checkInt() {
		return (c: FormGroup) => {
			const val = parseFloat(c.value);
			return Number.isInteger(val)
				? null
				: {
						valid: false
				  };
		};
	}

	public static allEmptyOrAllFilled() {
		return (c: FormGroup) => {
			const values = [];
			for (const key of Object.keys(c.controls)) {
				values.push(c.controls[key].value);
			}
			return values.every(i => i.length > 0) || values.every(i => i.length === 0) ? null : { valid: false };
		};
	}

	public static notEmptyString(c: AbstractControl) {
		const REGEXP = /^\s*$/;
		return REGEXP.test(c.value) || c.value === null ? { validationMessage: 'The field cannot be empty' } : null;
	}

	public static isAtLeastEqualThan(first, second) {
		return (c: FormGroup) => {
			return c.parent.controls && c.parent.controls[first].value >= c.parent.controls[second].value
				? null
				: {
						validationMessage: {
							valid: false
						}
				  };
		};
	}
}
