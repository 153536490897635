import { UserDetailModel, ContextCodeAssociationItem } from '@saep-ict/angular-spin8-core';
import { Injectable } from "@angular/core";
import { CommercialAreaModel } from "../../model/commercial-area.model";
import { Observable } from "rxjs";
import { BaseStateModel } from "../../model/state/base-state.model";
import * as fromState from '../../state/index';
import { Store } from '@ngrx/store';
import { reject } from "lodash";
import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';
import { PouchDbCommonsAdapter } from '../pouchdb/instance/commons/pouchdb-commons.service';
import { DivisionPouchModel, HeaderTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCommercialAreaModel } from '../../model/pouch/commercial-area-pouch';
import { StoreUtilService } from './store-util.service';
import { StateFeature } from '../../state/index';
import _ from 'lodash';
import { noSqlDocSeparator } from '../../constant/structure.constant';

@Injectable()
export class CommercialAreasService {

    user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
    user: UserDetailModel;
    organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(StateFeature.getOrganizationList);
    organizationList: OrganizationPouchModel[];

    commercialAreas: CommercialAreaModel[] = [];

    constructor(
      private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
      private store: Store<any>,
      private utilStoreService: StoreUtilService
    ) {
      this.loadStaticData();
    }

    loadStaticData() {
      this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
        this.user = e.data;
      });
      this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(e => {
        this.organizationList = e.data;
      });
    }

    getCommercialAreas(): Promise<CommercialAreaModel[]> {
      return new Promise(async resolve => {
          try {
            // `b2bContextCode`: legenda valori
            // - undefined: visibilità di tutte le zone commerciali
            // - [...]: visibilità delle sole zone commerciali associate alle organization associate ai `code_item` contenuti
            //   nell'array
            // - ['NO_PRIVILEGES']: visibilità di nessuna zona commerciale
            let b2bContextCode: string[];
            switch (this.user.current_permission.context_application) {
              case ContextApplicationItemCodeEnum.B2B:
                // `b2bContextCode` con length 1, avente solo il `code_item` dell'organization usata per la login
                b2bContextCode = [this.user.current_permission.context_code.code];
                break;
              case ContextApplicationItemCodeEnum.BACKOFFICE:
                const associationB2B =
                  this.user.current_permission.context_code.context_code_association_list.find(i =>
                    i.code === ContextApplicationItemCodeEnum.B2B
                  );
                if (associationB2B) {
                  // `associationB2B.context_code_list_all` -> `b2bContextCode: undefined`
                  // visibilità di tutte le zone commerciali
                  if (!associationB2B.context_code_list_all) {
                    // `!associationB2B.context_code_list_all` -> `b2bContextCode` è popolato con tutti i `code` (`code_item`)
                    // associati a livello B2B
                    if (associationB2B.context_code_list && associationB2B.context_code_list.length > 0) {
                      b2bContextCode = associationB2B.context_code_list.map(i => i.code);
                    } else {
                      // nessun code associato
                      b2bContextCode = ['NO_PRIVILEGES'];                    
                    }
                  }
                } else {
                  // context application B2B non trovato tra le sub associazioni del context application BACKOFFICE
                  b2bContextCode = ['NO_PRIVILEGES'];
                }
                break;
            }
            // `case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN` -> `b2bContextCode: undefined`
            this.commercialAreas = await this.retrieveCommercialArea(b2bContextCode);
            resolve(this.commercialAreas);
          } catch (err) {
            reject(err);
            throw(new Error(err));
          }
      });
  }

  async retrieveCommercialArea(b2bContextCodeList?: string[]): Promise<CommercialAreaModel[]> {
    return new Promise(async resolve => {
      try {
        let commercialAreaList: CommercialAreaModel[] = [];
        let divisionList: DivisionPouchModel[] = [];
        let organizationListFilterd: OrganizationPouchModel[] = [];
        if (b2bContextCodeList) {
          if (b2bContextCodeList[0] === 'NO_PRIVILEGES') {
            organizationListFilterd = [];
          } else {
            organizationListFilterd = _.cloneDeep(this.organizationList).filter(i => b2bContextCodeList.includes(i.code_item));
          }
        } else {
          organizationListFilterd = _.cloneDeep(this.organizationList);
        }
        for (const organization of organizationListFilterd) {
            if (organization && organization.division_list && organization.division_list.length > 0) {
              divisionList = divisionList.concat(organization.division_list);
            }
        }
        if (divisionList.length > 0) {
          let tableAreaCommercialList: BodyTablePouchCommercialAreaModel[] =
          (await this.pouchDbCommonsAdapter.basePouch.getDetail<HeaderTablePouchModel<BodyTablePouchCommercialAreaModel>>(`table${noSqlDocSeparator}area_commercial`))
          .values;
          // elimina dalla lista le zone commerciali ITA
          tableAreaCommercialList = tableAreaCommercialList.filter(i => i.area_geographic !== "7");
          for (const division of divisionList) {
            if (
              division.valid &&
              division.area_commercial
            ) {
              // esclude le divisioni non valide e prive dell'oggetto area_commercial
              const commercialAreaItem = tableAreaCommercialList.find(n => n.code_item === division.area_commercial);
              if (
                commercialAreaItem &&
                !commercialAreaList.find(i => i.codiceElemento === commercialAreaItem.code_item)
              ) {
                // include nel resolve solo gli avente code_item che trova riscontro nella tabella di decodifica
                commercialAreaList.push(
                  {
                    codiceElemento: commercialAreaItem.code_item,
                    descrizioneEstesa: commercialAreaItem.description,
                    descrizioneBreve: commercialAreaItem.description_short
                  }
                );                
              }
            }
          }
        }
        commercialAreaList = commercialAreaList.sort((a, b) => (a.descrizioneEstesa > b.descrizioneEstesa) ? 1 : -1);
        resolve(commercialAreaList);
      } catch (err) {
        reject(err);
        throw(new Error(err));
      }
    });
  }

}