import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

export interface RedirectionExtras extends NavigationExtras {
	target?: string;
}

@Injectable()
export class RedirectExternalUrlService {
	constructor(@Inject(DOCUMENT) readonly document: Document, readonly router: Router) {}

	/** The Window object from Document defaultView */
	get window(): Window {
		return this.document.defaultView;
	}

	/** Redirects instantly to the external link without the mediation of the router */
	public redirect(url: string, target: string = '_self'): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			try {
				resolve(!!this.window.open(url, target));
			} catch (e) {
				reject(e);
			}
		});
	}

	/** Returns true if the given url looks external */
	public external(url: string): boolean {
		return /^http(?:s)?:\/{2}\S+$/.test(url);
	}

	/** Parses the given url for extenral redirection */
	private externalUrl(url: string, target?: string): string {
		return 'redirect?url=' + url + (!!target ? '&=' + target : '');
	}

	/** Parses the given url for internal routing */
	private internalUrl(url: string): string {
		return url && url.startsWith('#') ? this.router.url + url : url && url.replace(/^\./, this.router.url);
	}

	/** Navigates to the given url, redirecting when necessary
	 * @param url An absolute URL. The function does not apply any delta to the current URL.
	 * When starting with 'http(s)://' triggers the external redirection.
	 * @param extras (optional). An object containing properties that modify the navigation strategy.
	 * The function ignores any properties that would change the provided URL.
	 */
	public navigate(url: string, extras?: RedirectionExtras): Promise<boolean> {
		// Compose the url link for redirection
		const link = this.external(url)
			? this.externalUrl(url, extras && extras.target ? extras.target : '_self')
			: this.internalUrl(url);
		console.log(link);
		// Navigates with the router activating the redirection guard
		return this.router.navigateByUrl(link, extras);
	}
}
