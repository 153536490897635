import { RequestCenterEnum } from "../../../enum/request-center/request-center.enum";
import { TicketModel, TicketPouchModel, TicketStatusRemapItemModel } from "../../../model/asset-request/ticket.model";
import { ButtonItemModel } from "../../../model/button-list.model";

export namespace RequestCenterStatusMapCompany {
    export namespace FilterRemap {
        export const base: TicketStatusRemapItemModel[] = [
            {
                value: [RequestCenterEnum.Status.base.DRAFT],
                label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.DRAFT]
            },
            {
                value: [
                    RequestCenterEnum.Status.base.PENDING_EUROITALIA,
                    RequestCenterEnum.Status.base.PENDING_AGENCY,
                    RequestCenterEnum.Status.base.COMPLETED_AGENCY
                ],
                label: 'PENDING'
            },
            {
                value: [RequestCenterEnum.Status.base.MORE_INFO_NEEDED],
                label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.MORE_INFO_NEEDED]
            },
            {
                value: [RequestCenterEnum.Status.base.SENT_TO_CLIENT],
                label: 'COMPLETED'
            }
        ];
    }
    export namespace StatusChangeButtonList {
        export namespace RequestType {
            export const advertisingMaterial =
                (ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>, formValidity: boolean):
                ButtonItemModel[] => {
                return [
                    {
                        value: RequestCenterEnum.Status.base.PENDING_EUROITALIA,
                        label: "Send to Euroitalia",
                        visible: () => ticket.status === RequestCenterEnum.Status.base.DRAFT || ticket.status === RequestCenterEnum.Status.base.MORE_INFO_NEEDED,
                        disabled: () => !(ticket.asset_list && ticket.asset_list.length > 0 && formValidity),
                        buttonClass: "APPROVED"
                    },
                    {
                        value: RequestCenterEnum.Status.base.DELETED,
                        label: "Delete",
                        visible: () =>
                            (ticket.status === RequestCenterEnum.Status.base.DRAFT || ticket.status === RequestCenterEnum.Status.base.MORE_INFO_NEEDED) &&
                            ticket._id &&
                            ticket.status === RequestCenterEnum.Status.base.DRAFT,
                        buttonClass: "REJECTED"
                    }
                ];
            }
        }
    }
}