import { RequestCenterEnum } from '../../enum/request-center/request-center.enum';
import { AssetRequestModel } from '../../model/asset-request/request-center.model';
import { RequestCenterStatusMapAgency } from './status/request-center-status-map-agency.constant';
import { RequestCenterStatusMapCompany } from './status/request-center-status-map-company.constant';
import { RequestCenterStatusMapEuroitalia } from './status/request-center-status-map-euroitalia.constant';
import { DialogRequestCenterAdvertisingMaterial } from '../../page/asset-request/widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../../page/asset-request/widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { TicketModel, TicketPouchModel } from '../../model/asset-request/ticket.model';
import { RequestCenterColumnListPageDetailAgency } from './column-list/page-detail/page-detail-agency.constant';
import { RequestCenterColumnListPageDetailCompany } from './column-list/page-detail/page-detail-company.constant';
import { RequestCenterColumnListPageDetailEuroitalia } from './column-list/page-detail/page-detail-euroitalia.constant';
import { RequestCenterColumnListPageListAgency } from './column-list/page-list/page-list-agency.constant';
import { RequestCenterColumnListPageListCompany } from './column-list/page-list/page-list-company.constant';
import { RequestCenterColumnListPageListEuroitalia } from './column-list/page-list/page-list-euroitalia.constant';
import { AssetRequestUtilService } from '../../service/util/asset-request-util.service';
import { Injector } from '@angular/core';

const injector = Injector.create({
	providers: [{ provide: AssetRequestUtilService, deps: [] }]
});

const assetRequestUtilService = injector.get(AssetRequestUtilService);

export const requestCenterConfiguration: AssetRequestModel.Configuration<
	DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
>[] = [
	{
		type: RequestCenterEnum.type.IMAGE_VIDEO,
		name: 'Images/Video Request',
		status: {
			filterRemap: {
				euroitalia: RequestCenterStatusMapEuroitalia.FilterRemap.RequestType.advertisingMaterial,
				company: RequestCenterStatusMapCompany.FilterRemap.base,
				agency: RequestCenterStatusMapAgency.FilterRemap.RequestType.advertisingMaterial
			},
			changeButtonList: {
				euroitalia: (ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>) =>
					RequestCenterStatusMapEuroitalia.StatusChangeButtonList.RequestType.advertisingMaterial(ticket),
				company: (ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>, formValidity: boolean) =>
					RequestCenterStatusMapCompany.StatusChangeButtonList.RequestType.advertisingMaterial(
						ticket,
						formValidity
					),
				agency: (ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>) =>
					RequestCenterStatusMapAgency.StatusChangeButtonList.RequestType.advertisingMaterial(ticket)
			}
		},
		pageDetail: {
			columnList: {
				agency: RequestCenterColumnListPageDetailAgency.advertisingMaterial.columList,
				company: RequestCenterColumnListPageDetailCompany.advertisingMaterial.columList,
				euroitalia: RequestCenterColumnListPageDetailEuroitalia.advertisingMaterial.columList
			},
			dialog: DialogRequestCenterAdvertisingMaterial,
			headerField: {
				date_due: {
					disable: () => true,
					minDate: assetRequestUtilService.addBusinessDays(new Date(), 5),
					filterDate: assetRequestUtilService.excludeWeekend
				},
				urgent: {
					disable: (
						ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>,
						userType: RequestCenterEnum.User.type
					) =>
						userType === RequestCenterEnum.User.type.EUROITALIA &&
						[
							RequestCenterEnum.Status.base.DRAFT,
							RequestCenterEnum.Status.base.PENDING_EUROITALIA
						].includes(ticket.status)
							? false
							: true,
					visible: {
						euroitalia: true,
						company: false,
						agency: true
					}
				}
			}
		},
		pageList: {
			columnList: {
				agency: RequestCenterColumnListPageListAgency.advertisingMaterial.columList,
				company: RequestCenterColumnListPageListCompany.advertisingMaterial.columList,
				euroitalia: RequestCenterColumnListPageListEuroitalia.advertisingMaterial.columList
			},
			hiddenColumnNameList: {
				agency: RequestCenterColumnListPageListAgency.advertisingMaterial.hiddenColumnNameList,
				euroitalia: RequestCenterColumnListPageListEuroitalia.advertisingMaterial.hiddenColumnNameList
			}
		},
		actionKey: RequestCenterEnum.storeActionKey.IMAGE_VIDEO,
		assetType: RequestCenterEnum.Asset.Type.AdvertisingMaterial,
		sort: {
			name: 'request.date_due',
			order: 'desc'
		}
	},
	{
		type: RequestCenterEnum.type.PRINT_PRODUCTION_FLOW,
		name: 'Print Production Flow',
		status: {
			filterRemap: {
				euroitalia: RequestCenterStatusMapEuroitalia.FilterRemap.RequestType.printProductionFlow,
				agency: RequestCenterStatusMapAgency.FilterRemap.RequestType.printProductionFlow
			},
			changeButtonList: {
				euroitalia: (
					ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>,
					formValidity: boolean
				) =>
					RequestCenterStatusMapEuroitalia.StatusChangeButtonList.RequestType.printProductionFlow(
						ticket,
						formValidity
					),
				agency: (ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>) =>
					RequestCenterStatusMapAgency.StatusChangeButtonList.RequestType.printProductionFlow(ticket)
			}
		},
		pageDetail: {
			columnList: {
				agency: RequestCenterColumnListPageDetailAgency.printProductionFlow.columList,
				euroitalia: RequestCenterColumnListPageDetailEuroitalia.printProductionFlow.columList
			},
			dialog: DialogRequestCenterPrintProductionFlowComponent,
			headerField: {
				date_due: {
					disable: (
						ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>,
						userType: RequestCenterEnum.User.type
					) =>
						userType === RequestCenterEnum.User.type.EUROITALIA &&
						[
							RequestCenterEnum.Status.base.DRAFT,
							RequestCenterEnum.Status.base.PENDING_EUROITALIA
						].includes(ticket.status)
							? false
							: true,
					minDate: assetRequestUtilService.minDateToday,
					filterDate: assetRequestUtilService.excludeWeekend
				},
				urgent: {
					disable: (
						ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>,
						userType: RequestCenterEnum.User.type
					) =>
						userType === RequestCenterEnum.User.type.EUROITALIA &&
						[RequestCenterEnum.Status.base.DRAFT].includes(ticket.status)
							? false
							: true,
					visible: {
						euroitalia: true,
						agency: true
					}
				}
			}
		},
		pageList: {
			columnList: {
				agency: RequestCenterColumnListPageListAgency.printProductionFlow.columList,
				euroitalia: RequestCenterColumnListPageListEuroitalia.printProductionFlow.columList
			}
		},
		actionKey: RequestCenterEnum.storeActionKey.PRINT_PRODUCTION_FLOW,
		assetType: RequestCenterEnum.Asset.Type.PrintProductionFlow.Code,
		sort: {
			name: 'date_status_update',
			order: 'desc'
		}
	}
];
