import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// model
import { SurveyModel, BrandItemModel } from '../../../../model/survey-center/survey.model';
// widget
import { UtilService } from '../../../../service/util/util.service';

@Component({
  selector: 'backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {

  private _survey: SurveyModel = new SurveyModel();
  @Input() set survey(s: SurveyModel) {
		if (s.brand_list && s.brand_list.length > 0) {
      this._survey = JSON.parse(JSON.stringify(s));
      this.brandSelected = this.utilService.brandSelected(s.brand_list);
		}
	}
  get survey(): SurveyModel { return this._survey; }

  brandSelected: BrandItemModel = new BrandItemModel();

  @Output() onSurveyValidationChange  = new EventEmitter();
  @Output() onDownloadLatest = new EventEmitter<string>();

  constructor(
    private utilService: UtilService
  ) { }

  ngOnInit() {
  }

  onSaveSurveyValidation(brand: BrandItemModel) {
    let survey = this.survey;
    survey.brand_list[this.utilService.getBrandIndex(survey.brand_list, brand.brand.codice_brand)] = brand;
    this.onSurveyValidationChange.emit(survey);
  }
}
