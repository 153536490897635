import { Injectable } from '@angular/core';

// model
import { BasePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseState, BaseStateModel } from '../../../../../model/state/base-state.model';
import { LoginContextCodeCompanyActionEnum, LoginContextCodeCompanyStateAction } from './login-context-code-company.actions';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// widget & utility
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StoreUtilService } from '../../../../../service/util/store-util.service';
import { PouchAdapterEnum } from '../../../../../enum/pouch-adapter.enum';

@Injectable()
export class LoginContextCodeCompanyEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoginContextCodeCompanyActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInDetailState<OrganizationPouchModel>(action, PouchAdapterEnum.commons_organization))
			),
			map((customer: BaseStateModel<OrganizationPouchModel>) =>
				LoginContextCodeCompanyStateAction.update(customer)
			),
			catchError((error, caught) => {
				this.store.dispatch(LoginContextCodeCompanyStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private storeUtilService: StoreUtilService
	) {}

}
