import { Injectable } from "@angular/core";
import { TitleCasePipe } from '@angular/common';
import { CommercialAreaModel } from '../../../model/commercial-area.model';
import { UtilService } from '../util.service';

@Injectable()
export class SurveyMessageService {

    constructor(
        private utilService: UtilService,
        private titleCasePipe: TitleCasePipe
    ) {

    }

    returnSurveyConfirmationDialogTitle(
        survey_type: string,
        year?: number,
        commercial_area?: CommercialAreaModel
    ): string {
		return `
			${this.titleCasePipe.transform(this.utilService.formatPlaceholder(survey_type))}
			${year ? ' | ' + year : ''}
            ${commercial_area ? ' | ' + commercial_area.descrizioneEstesa : ''}
        `;
	}

}