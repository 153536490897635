import { createAction, props } from '@ngrx/store';
// model
import { AbstractListModel } from '../../model/structure/AbstractListModel';
import { ChatDetailStateFeatureSelectionParameterModel } from '../../model/pouch/chat.model';


export enum ChatActionEnum {
	UPDATE_LIST = '[Chat] Update List',
	LOAD_LIST = '[Chat] Load List',
	UPDATE_LIST_ITEM = '[Chat] Update Item',
	CLOSE_CONNECTION = '[Chat] Close Connection',
	RESET_LIST = '[Chat] Reset List',
	ERROR_LIST = '[Chat] Error'
}

export namespace ChatStateAction {
	export const updateList = createAction(
		ChatActionEnum.UPDATE_LIST,
		props<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>>()
	);
	export const updateListItem = createAction(
		ChatActionEnum.UPDATE_LIST_ITEM,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
	export const loadList = createAction(ChatActionEnum.LOAD_LIST);
	export const closeConnection = createAction(ChatActionEnum.CLOSE_CONNECTION);
	export const resetList = createAction(ChatActionEnum.RESET_LIST);
	export const error = createAction(ChatActionEnum.ERROR_LIST);
}
