import { createReducer, on } from '@ngrx/store';
import { BackendProcessQueueModel } from '../../../model/asset-request/backend-queue-process.model';
import { BackendProcessQueueAction as BackendProcessQueueAction } from './backend-process-queue.actions';


export namespace BackendProcessQueueReducer {
	// detail
	export const initialState: BackendProcessQueueModel = null;

	const _reducer = createReducer(
		initialState,
		on(BackendProcessQueueAction.update, (state, backendProcessQueue) => JSON.parse(JSON.stringify(backendProcessQueue))),
		on(BackendProcessQueueAction.load, state => state),
		on(BackendProcessQueueAction.save, state => state),
		on(BackendProcessQueueAction.reset, state => initialState),
		on(BackendProcessQueueAction.remove, state => state)
	);

	export function reducer(state, action) {
		return _reducer(state, action);
	}

}
