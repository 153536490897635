import { Injectable } from '@angular/core';
import { BasePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationListActionEnum, OrganizationListStateAction } from './organization-list.actions';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseStateModel } from '@saep-ict/angular-core';
import { PouchDbConstant } from '@saep-ict/angular-spin8-core';
import { PouchUtilService } from '../../service/util/pouch-util.service';
import { PouchAdapterEnum } from '../../enum/pouch-adapter.enum';
import { StoreUtilService } from '../../service/util/store-util.service';
import { noSqlDocSeparator } from '../../constant/structure.constant';

@Injectable()
export class OrganizationListEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.LOAD),
			mergeMap((e: BaseStateModel<OrganizationPouchModel[]>) => from(
				this.utilPouchService.allDocs<OrganizationPouchModel>(
					{
						include_docs: true,
						startkey: 'organization' + noSqlDocSeparator,
						endkey: 'organization' + noSqlDocSeparator + PouchDbConstant.allDocsLastCharacter
					},
					PouchAdapterEnum.commons_organization
				)
			)),
			map((e: BaseStateModel<OrganizationPouchModel[]>) => OrganizationListStateAction.update(e)),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	loadDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.LOAD_DETAIL),
			mergeMap((e: BaseStateModel<BasePouchModel>) => from(
				this.storeUtilService.getCouchDetailAndReturnInListState<OrganizationPouchModel>(
					e,
					PouchAdapterEnum.commons_organization
				)
			)),
			map((e: BaseStateModel<OrganizationPouchModel[]>) => OrganizationListStateAction.update(e)),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: PouchUtilService,
		private storeUtilService: StoreUtilService
	) {

	}

}
