import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

// model
import {
	TicketUserTypeSelectorModel,
	TicketStatusRemapItemModel,
	TicketStateModel,
	TicketModel
} from '../../model/asset-request/ticket.model';
import { UtilService } from './util.service';
import { AssetTypeFormFieldList } from '../../model/asset-request/ticket.model';
import { CategoryListStateModel } from '../../model/category-list.model';
import { AssetRequestCategory } from '../../model/asset-request/asset-request-category-list.model';

// widget & utility
import * as moment from 'moment';
import { FormValidatorCustom } from '../../shared/misc/form-validator';
import { RequestCenterEnum } from '../../enum/request-center/request-center.enum';

@Injectable({
	providedIn: 'root'
})
export class AssetRequestUtilService {
	categoryList: CategoryListStateModel<AssetRequestCategory> = {};

	statusListDashboard: TicketUserTypeSelectorModel<TicketStatusRemapItemModel[]> = {
		company: [],
		euroitalia: [
			{
				value: [RequestCenterEnum.Status.base.PENDING_EUROITALIA],
				label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_EUROITALIA]
			},
			{
				value: [RequestCenterEnum.Status.base.PENDING_AGENCY],
				label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_AGENCY]
			}
		],
		agency: []
	};

	assetTypeFormFieldMap: AssetTypeFormFieldList[] = [
		{
			type: RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER,
			fieldList: [
				{
					key: 'version',
					validator: [Validators.required]
				},
				{
					key: 'cta'
				},
				{
					key: 'digital_width',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'digital_height',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'country',
					validator: [Validators.required]
				},
				{
					key: 'client',
					validator: [Validators.required],
					label: 'Website'
				}
			]
		},
		{
			type: RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO,
			fieldList: [
				{
					key: 'version',
					validator: [Validators.required]
				},
				{
					key: 'file_extension',
					validator: [Validators.required]
				},
				{
					key: 'duration',
					validator: [Validators.required]
				},
				{
					key: 'digital_width',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'digital_height',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'file_extension',
					validator: [Validators.required]
				},
				{
					key: 'date_release'
				},
				{
					key: 'video_diffusion_type',
					validator: [Validators.required]
				},
				{
					key: 'country',
					validator: [Validators.required]
				},
				{
					key: 'city',
					label: 'City / Airport'
				},
				{
					key: 'client',
					validator: [Validators.required],
					label: 'Store / Website'
				}
			]
		},
		{
			type: RequestCenterEnum.Asset.Type.AdvertisingMaterial.INSTORE_VISUAL_FOR_PRINT,
			fieldList: [
				{
					key: 'version',
					validator: [Validators.required]
				},
				{
					key: 'measure',
					validator: [Validators.required]
				},
				{
					key: 'dimension_visible_width',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'dimension_visible_height',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'dimension_total_width',
					validator: [
						Validators.required,
						FormValidatorCustom.isAtLeastEqualThan('dimension_total_width', 'dimension_visible_width'),
						FormValidatorCustom.minNumber(1)
					]
				},
				{
					key: 'dimension_total_height',
					validator: [
						Validators.required,
						FormValidatorCustom.isAtLeastEqualThan('dimension_total_height', 'dimension_visible_height'),
						FormValidatorCustom.minNumber(1)
					]
				},
				{
					key: 'country',
					validator: [Validators.required]
				},
				{
					key: 'city',
					validator: [Validators.required],
					label: 'City'
				},
				{
					key: 'client',
					validator: [Validators.required],
					label: 'Store'
				}
			]
		},
		{
			type: RequestCenterEnum.Asset.Type.AdvertisingMaterial.INSTORE_VISUAL_FOR_MONITOR,
			fieldList: [
				{
					key: 'version',
					validator: [Validators.required]
				},
				{
					key: 'digital_width',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'digital_height',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'country',
					validator: [Validators.required]
				},
				{
					key: 'city',
					validator: [Validators.required],
					label: 'City'
				},
				{
					key: 'client',
					validator: [Validators.required],
					label: 'Store'
				}
			]
		},
		{
			type: RequestCenterEnum.Asset.Type.AdvertisingMaterial.MAGAZINE,
			fieldList: [
				{
					key: 'version',
					validator: [Validators.required]
				},
				{
					key: 'date_release'
				},
				{
					key: 'measure',
					validator: [Validators.required]
				},
				{
					key: 'dimension_visible_width',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'dimension_visible_height',
					validator: [Validators.required, FormValidatorCustom.minNumber(1)]
				},
				{
					key: 'dimension_total_width',
					validator: [
						Validators.required,
						FormValidatorCustom.isAtLeastEqualThan('dimension_total_width', 'dimension_visible_width'),
						FormValidatorCustom.minNumber(1)
					]
				},
				{
					key: 'dimension_total_height',
					validator: [
						Validators.required,
						FormValidatorCustom.isAtLeastEqualThan('dimension_total_height', 'dimension_visible_height'),
						FormValidatorCustom.minNumber(1)
					]
				},
				{
					key: 'country',
					validator: [Validators.required]
				},
				{
					key: 'client',
					validator: [Validators.required],
					label: 'Magazine'
				}
			]
		}
	];

	minDateToday = parseInt(moment().format('x'), null);

	imagePreviewExtensionRegex = this.returnImagePreviewExtensionRegex();

	constructor(public utilService: UtilService) {}

	/**
	 * This function returns RequestCenterEnum.Status.base values the user is allowed to see
	 * based on statusListRemap object
	 * @param {TicketStatusRemapItemModel[]} array: company | euroitalia | agency
	 * @returns: number[] userType based (see statusListRemap object)
	 * @memberof AssetRequestUtilService
	 */
	returnStatusValueNumberList(array: TicketStatusRemapItemModel[]) {
		const valueList: number[] = [];
		array.forEach(i => {
			i.value.forEach(n => {
				valueList.push(n);
			});
		});
		return valueList;
	}

	getLabelfromStatusListRemap(
		pageListStatusList: TicketUserTypeSelectorModel<TicketStatusRemapItemModel[]>,
		userType: string,
		ticketStatus: number
	): string {
		let label = '';
		pageListStatusList[userType].forEach(item => {
			if (item.value.includes(ticketStatus)) {
				label = item.label;
			}
		});
		return label;
	}

	getLabelClassfromStatusListRemap(
		pageListStatusList: TicketUserTypeSelectorModel<TicketStatusRemapItemModel[]>,
		userType: string,
		ticketStatus: number
	): string {
		let labelClass = '';
		pageListStatusList[userType].forEach(item => {
			if (item.value.includes(ticketStatus)) {
				labelClass = item.labelClass ? item.labelClass : item.label;
			}
		});
		return labelClass;
	}

	/**
	 * This function handle the ticket to modify the assets inside it.
	 * @param ticket: TicketStateModel, is the original ticket that contains the ticketlist
	 * @param assetInfo.asset: TicketModel.AssetItem.AdvertisingMaterial, is the asset of the ticket to modify
	 * @param assetInfo.index: number || null, (evaluated `typeof assetInfo.index === 'number'`)
	 * rappresents item position in ticket.asset_list
	 * @returns: ticket: TicketStateModel
	 *
	 * - Passing assetInfo.asset only: pushes it as new item in ticket.asset_lits
	 * - Passing assetInfo.asset and assetInfo.index: uses it to update ticket.asset_lits[assetInfo.index]
	 * - Passing assetInfo.index only: deletes ticket.asset_lits[assetInfo.index]
	 */
	handleAssetListUpdate(
		ticket: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>,
		assetInfo: { asset?: TicketModel.AssetItem.AdvertisingMaterial; index?: number }
	): TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow> {
		if (assetInfo.asset) {
			if (typeof assetInfo.index === 'number') {
				// update
				ticket.asset_list[assetInfo.index] = assetInfo.asset;
			} else {
				// create
				if (!ticket.asset_list) {
					ticket.asset_list = [];
				}
				ticket.asset_list.push(assetInfo.asset);
			}
		} else {
			// delete
			if (typeof assetInfo.index === 'number') {
				ticket.asset_list.splice(assetInfo.index, 1);
			}
		}
		return ticket;
	}

	getBrandLabel(categoryList: CategoryListStateModel<AssetRequestCategory>, idBrand: string): string {
		if (idBrand && categoryList && categoryList.category_list) {
			const brand = categoryList.category_list.find(b => {
				return b.code === idBrand;
			});
			// if not find
			if (brand) {
				return brand.description;
			} else {
				return idBrand;
			}
		} else {
			return null;
		}
	}

	getLineLabel(categoryList: CategoryListStateModel<AssetRequestCategory>, idBrand: string, idLine: string): string {
		if (idBrand && idLine && categoryList && categoryList.category_list) {
			let line = null;
			let brand = null;
			brand = categoryList.category_list.find(b => {
				return b.code === idBrand;
			});
			if (brand) {
				line = brand.category_list.find(l => {
					return l.code === idLine;
				});
			}
			// if not find
			if (brand && line) {
				return line.description;
			} else {
				return idLine;
			}
		} else {
			return null;
		}
	}

	/**
	 * Recuper la line description incrociando un array di stringhe contenente
	 * i brand code e il code stesso della line
	 */
	getLineDescription(brandCodeList: string[], lineCode: string) {
		for (let b = 0; b < brandCodeList.length; b++) {
			const brand = this.categoryList.category_list.find(i => i.code === brandCodeList[b]);
			for (let l = 0; l < brand.category_list.length; l++) {
				if (brand.category_list[l].code === lineCode) {
					return brand.category_list[l].description;
				}
			}
		}
	}

	isColumnUploadAgencyHidden(status: RequestCenterEnum.Status.base, userType: RequestCenterEnum.User.type): boolean {
		return !(
			status === RequestCenterEnum.Status.base.SENT_TO_CLIENT ||
			((status === RequestCenterEnum.Status.base.PENDING_AGENCY ||
				status === RequestCenterEnum.Status.base.COMPLETED_AGENCY ||
				status === RequestCenterEnum.Status.base.PENDING_EUROITALIA) &&
				userType !== RequestCenterEnum.User.type.COMPANY)
		);
	}

	addBusinessDays(date: Date, numDaysToAdd: number) {
		const Sunday = 0;
		const Saturday = 6;
		let daysRemaining = numDaysToAdd;
		while (daysRemaining > 0) {
			date.setDate(date.getDate() + 1);
			if (date.getDay() !== Sunday && date.getDay() !== Saturday) {
				daysRemaining--;
			}
		}
		return date.valueOf();
	}

	excludeWeekend(date: Date): boolean {
		const day = moment(date).day();
		return day !== 0 && day !== 6;
	}

	returnImagePreviewExtensionRegex(): RegExp {
		const imageFilePreviewList: string[] =
			Object.keys(RequestCenterEnum.Asset.ImagePreviewExtension).map(i => RequestCenterEnum.Asset.ImagePreviewExtension[i]); 
		let extensionList: string = '';
		for (let i = 0; i < imageFilePreviewList.length; i++) {
			extensionList +=
				imageFilePreviewList[i] +
				(i !== (imageFilePreviewList.length - 1) ? '|' : '');
		}
		return 	new RegExp(`.${extensionList}$`, "i");
	}

	fileIsImage(fileName: string): boolean {
		return this.imagePreviewExtensionRegex.test(fileName);
	}

}
