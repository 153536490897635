import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// model
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { AbstractListModel } from '../../model/structure/AbstractListModel';
import { ChatDetailStateFeatureSelectionParameterModel } from '../../model/pouch/chat.model';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../state/index';
import { ChatStateAction } from '../../state/chat/chat.actions';
import { RemoteChangeStateAction } from '../../state/remote-change/remote-change.actions';

// widget & utility
import { Observable } from 'rxjs';
import { TdDataTableComponent, ITdDataTableRowClickEvent } from '@covalent/core/data-table';
import { ChatColumnService } from '../../service/td-data-table/implementation/chat.service';
import { UtilService } from '../../service/util/util.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { skipWhile, take, mergeMap } from 'rxjs/operators';
import { BaseStateModel } from '../../model/state/base-state.model';

@Component({
	selector: 'chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	providers: [SubscribeManagerService]
})
export class ChatComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	chatList$: Observable<AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>> =	this.store.select(StateFeature.getChatListState);
	chatList: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]> = {};

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public chatColumnService: ChatColumnService,
		private store: Store<any>,
		public utilService: UtilService,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeUser().subscribe(), 'user');
		this.subscribeManagerService.populate(this.subscribeChatList().subscribe(), 'chat-list');
		this.store.dispatch(ChatStateAction.loadList());
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ChatStateAction.closeConnection());
		this.store.dispatch(RemoteChangeStateAction.reset());
	}

	subscribeUser() {
		return this.user$.pipe(
			skipWhile((user: BaseStateModel<UserDetailModel>) => !user.data),
			take(1),
			mergeMap((user: BaseStateModel<UserDetailModel>) => {
				this.user = user.data;
				return this.user$;
			})
		);
	}

	subscribeChatList() {
		return this.chatList$.pipe(
			skipWhile((chatListState: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>) => !chatListState),
			mergeMap((chatListState: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>) => {
				this.chatList = chatListState;
				if (this.dataTable) {
					this.dataTable.refresh();
				}
				return this.chatList$;
			})
		);
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([`/${this.route.snapshot['_routerState'].url}/${e.row.chat._id}`]);
	}

	// widget
	toggleColumn(e) {
		for (let i = 0; i < this.chatColumnService.columns.length; i++) {
			if (this.chatColumnService.columns[i].name === e.name) {
				this.chatColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}
}
