<breadcrumb-project-instance
	[title]="surveyConf ? (utilService.formatPlaceholder(surveyConf.type) | titlecase) : 'All survey'"
	[subtitle]="'Survey' | titlecase"
	[backButton]="false"
></breadcrumb-project-instance>

<form [formGroup]="formFilters" #resetTag="ngForm">
	<div class="container">
		<div class="row">
			<div class="col-lg-2 order-lg-last">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							Filters
							<span class="flex-span"></span>
							<button mat-raised-button (click)="resetForm()">Reset</button>
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<mat-form-field>
							<mat-select multiple formControlName="commercialAreas" placeholder="Commercial Areas">
								<mat-option
									*ngFor="let item of commercialAreasService.commercialAreas"
									[value]="item.codiceElemento"
								>
									{{ item.descrizioneEstesa }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field>
							<mat-select multiple formControlName="years" placeholder="Years">
								<mat-option *ngFor="let item of surveyYears" [value]="item">
									{{ item }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<ng-container formGroupName="brandStatus">
							<mat-form-field>
								<mat-select multiple formControlName="brandList" placeholder="Brands">
									<mat-option
										*ngFor="let item of brandsService.brandsList"
										[value]="item.brand.codice_brand"
									>
										{{ item.brand.nome_brand }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field>
								<mat-select
									multiple
									formControlName="brandApprovationStatusList"
									placeholder="Sending Status"
								>
									<mat-option *ngFor="let item of brandApprovationStatusList" [value]="item">
										{{ item | titlecase }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-error *ngIf="formFilters.controls.brandStatus.invalid">
								Both or none of the fields <i>Brands</i> and <i>Sending Status</i> must be completed
							</mat-error>
						</ng-container>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="col-lg-10 order-lg-first">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{ surveyConf ? (utilService.formatPlaceholder(surveyConf.type) | titlecase) : 'All survey'
							}}{{ clientCode ? ' | @' + clientCode : '' }}
							<span class="flex-span"></span>
							<td-search-box
								*ngIf="
									(user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE) ||
									(user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN)
								"
								backIcon="arrow_back"
								placeholder="Filter by Client"
								[(ngModel)]="pageSettings.filters.text"
								[ngModelOptions]="{ standalone: true }"
								[showUnderline]="false"
								[debounce]="500"
								[alwaysVisible]="false"
								(searchDebounce)="onFormFiltersSubmit()"
							>
							</td-search-box>
							<app-table-column-toggle
								[columns]="surveyListColumnService.columns"
								(emitter)="toggleColumn($event)"
							>
							</app-table-column-toggle>
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<td-data-table
							#dataTable
							[data]="surveys.data"
							[columns]="surveyListColumnService.columns"
							[clickable]="true"
							[sortBy]="pageSettings.sort[0].name"
							[sortOrder]="pageSettings.sort[0].order"
							(sortChange)="sortChange($event)"
							(rowClick)="goToDetail($event)"
							class="text-right-col-3 skip-borders"
						>
							<ng-template tdDataTableTemplate="commercial_area.descrizioneBreve" let-row="row">
								<span *ngIf="row.commercial_area" [matTooltip]="row.commercial_area.codiceElemento">
									{{ row.commercial_area.descrizioneEstesa }}
								</span>
							</ng-template>
							<ng-template tdDataTableTemplate="brand_list" let-row="row">
								<div *ngIf="row.validation" class="chips-mini">
									<span
										*ngFor="let b of row.brand_list"
										[ngClass]="b.status"
										matTooltip="{{ b.brand?.nome_brand }} status: {{ b.status | titlecase }}"
										>{{ b.brand?.nome_brand_ridotto }}</span
									>
								</div>
							</ng-template>
						</td-data-table>
						<pouch-paging-bar
							[pagination]="pageSettings.pagination"
							(change)="paginationChange($event)"
							[data]="surveys.data"
						></pouch-paging-bar>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</form>
