import { Injectable } from '@angular/core';
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';
import { AssetRequestCenterPouch } from './pouch-function/asset-request-center.pouch';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { LoaderService } from '../../../structure/loader.service';
import { HttpClient } from '@angular/common/http';
import { CategoryListPouch } from '../../pouch-common-function/category-list-common.pouch';

@Injectable()
export class PouchDbAssetRequestCenterAdapter extends PouchDbAdapter {
	baseDatabaseTemplate: 'asset-request';
	database = 'asset-request';
	assetRequestCenterPouch: AssetRequestCenterPouch;
	categoryListCommonPouch: CategoryListPouch;

	constructor(
		protected config: CustomerAppConfig,
		private loaderService: LoaderService,
		offlineDeviceService: OfflineDeviceService,
		private httpClient: HttpClient,
		private transmissionService: TransmissionService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.assetRequestCenterPouch = new AssetRequestCenterPouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
		this.categoryListCommonPouch = new CategoryListPouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
	}
}
