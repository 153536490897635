import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TicketStateReducer } from './ticket.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('ticket', TicketStateReducer.reducer),
		StoreModule.forFeature('request-center', TicketStateReducer.reducerList)
	]
})
export class TicketStateModule {}
