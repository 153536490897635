import { NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe, SlicePipe, UpperCasePipe, CurrencyPipe, CommonModule } from '@angular/common';
import { SafeDomSanitizerPipe } from './safe-dom-sanitizer.pipe';
import { ThousandPipe } from './thousand-separator.pipe';
import { EnumIteratorPipe } from './enum-iterator';
import { ShortenPipe } from './shorten.pipe';
import { FilterByStringPipe } from './filter-by-string.pipe';
import { FilterByAlreadyInsertedPipe } from './filter-by-already-inserted.pipe';
import { AssetRequestIdFormatterPipe } from './asset-request-id-formatter.pipe';
import { DateTranformFormatter } from './date-transform.pipe';

@NgModule({
  declarations: [
    SafeDomSanitizerPipe,
    ThousandPipe,
    EnumIteratorPipe,
    ShortenPipe,
    FilterByStringPipe,
    FilterByAlreadyInsertedPipe,
    AssetRequestIdFormatterPipe,
    DateTranformFormatter
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeDomSanitizerPipe,
    EnumIteratorPipe,
    ShortenPipe,
    FilterByStringPipe,
    FilterByAlreadyInsertedPipe,
    UpperCasePipe,
    TitleCasePipe,
    SlicePipe,
    AssetRequestIdFormatterPipe,
    DateTranformFormatter
  ],
  providers: [
    ThousandPipe,
    DatePipe,
    SlicePipe,
    UpperCasePipe,
    TitleCasePipe,
    ShortenPipe,
    FilterByStringPipe,
    FilterByAlreadyInsertedPipe,
    CurrencyPipe
  ]
})
export class PipeModule { }
