// model
import { BaseStateModel } from '../../../../../model/state/base-state.model';
import { LoginContextCodeBackofficeActionEnum, LoginContextCodeBackofficeStateAction } from './login-context-code-backoffice.actions';
import { BodyTablePouchCustomModel } from '../../../../../model/pouch/base-pouch.model';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

export namespace LoginContextCodeBackofficeStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchCustomModel> = null;

	const _reducer = createReducer(
		initialState,
		on(LoginContextCodeBackofficeStateAction.load, state => state),
		on(LoginContextCodeBackofficeStateAction.update, (state, param) => param),
		on(LoginContextCodeBackofficeStateAction.reset, () => initialState),
		on(LoginContextCodeBackofficeStateAction.error, (state, param) => (param))
	);

	export function reducer(state: BaseStateModel<BodyTablePouchCustomModel>, action: TypedAction<LoginContextCodeBackofficeActionEnum>) {
		return _reducer(state, action);
	}
}
