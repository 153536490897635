import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BodyTablePouchCustomModel } from '../../../../../model/pouch/base-pouch.model';
import { BaseStateModel, BaseState } from '../../../../../model/state/base-state.model';
import { LoginContextCodeBackofficeActionEnum, LoginContextCodeBackofficeStateAction } from './login-context-code-backoffice.actions';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// widget & utility
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StoreUtilService } from '../../../../../service/util/store-util.service';
import { PouchAdapterEnum } from '../../../../../enum/pouch-adapter.enum';

@Injectable()
export class LoginContextCodeBackofficeEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoginContextCodeBackofficeActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action, PouchAdapterEnum.commons_backoffice))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				LoginContextCodeBackofficeStateAction.update(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(LoginContextCodeBackofficeStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private storeUtilService: StoreUtilService
	) {}

}
