<button
  mat-raised-button
  (click)="eventWrapperInput()"
  color="primary"
  type="button"
>
  <label>{{uploadButtonLabel ? uploadButtonLabel : 'Add New file'}}</label>
</button>
<input
  #fileInputContainer
  type="file"
  [multiple]="multiple"
  [accept]="acceptedType"
  hidden
  (change)="onFilesFromSelectChange($event)"
>
<div 
  drop-area
  (filesChangeEmiter)="onFilesChange($event)"
  class="drop-area-container"
>
  <p>
    <mat-icon>cloud_upload</mat-icon>
    <br>
    Drag and drop area
  </p>
</div>