// model
import { BaseStateModel } from '../../../../../model/state/base-state.model';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { LoginContextCodeCompanyActionEnum, LoginContextCodeCompanyStateAction } from './login-context-code-company.actions';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

export namespace LoginContextCodeCompanyStateReducer {
	export const initialState: BaseStateModel<OrganizationPouchModel> = null;

	const _reducer = createReducer(
		initialState,
		on(LoginContextCodeCompanyStateAction.load, state => state),
		on(LoginContextCodeCompanyStateAction.update, (state, param) => param),
		on(LoginContextCodeCompanyStateAction.reset, () => initialState),
		on(LoginContextCodeCompanyStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<OrganizationPouchModel>, action: TypedAction<LoginContextCodeCompanyActionEnum>) {
		return _reducer(state, action);
	}
}
