import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// model
import { BrandItemModel } from '../../../../model/survey-center/survey.model';
// widgte
import { BrandListColumnService } from '../../../../service/td-data-table/implementation/survey-center/brand-list-column.service';
import { CustomerAppConfig } from '../../../../customer-app.config';

@Component({
  selector: 'brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  @Input('brandList') brandList: BrandItemModel[];
  @Output() brandSelectedChange = new EventEmitter();

  constructor(
    public brandListColumnService: BrandListColumnService,
    public appConfig: CustomerAppConfig
  ) { }

  ngOnInit() { }

}
