import { NgModule } from "@angular/core";

// model
import { LoginContextCodeCompanyStateReducer } from "./login-context-code-company.reducer";

// store
import { StoreModule } from "@ngrx/store";

@NgModule({
    imports: [
        StoreModule.forFeature('login-context-code-company', LoginContextCodeCompanyStateReducer.reducer)
    ]
})
export class LoginContextCodeCompanyStateModule{}
