import { NgModule } from '@angular/core';
import { LoaderService } from '../service/structure/loader.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { FramePageComponent } from './admin/admin.component';

@NgModule({
  imports: [
    SharedModule,
    WidgetModule,
  ],
  declarations: [
    FramePageComponent
  ],
  providers: [
    LoaderService
  ]
})
export class FrameModule { }
