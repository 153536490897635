import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { RoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { FrameModule } from './frame/frame.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { HashLocationStrategy, LocationStrategy, LOCATION_INITIALIZED } from '@angular/common';
import { PageModule } from './page/page.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ResourceGlobalConfig, ResourceActionReturnType, ResourceHandler } from '@ngx-resource/core';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { ResourceUrlEncodedHandler } from './service/util/resource-urlencoded-handler.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PouchDbSurveyCenterAdapter } from './service/pouchdb/instance/survey-center/pouchdb-survey-center-adapter.service';
import { PouchDbChatAdapter } from './service/pouchdb/instance/chat/assistance/pouchdb-chat-adapter.service';
import { PouchDbChatAssetRequestAdapter } from './service/pouchdb/instance/chat/asset-request/pouchdb-chat-asset-request-adapter.service';
import { PouchDbAssetRequestCenterAdapter } from './service/pouchdb/instance/asset-request-center/pouchdb-asset-request-center-adapter.service';
import { InjectorModule } from './injector.module';
import { EffectsModule } from '@ngrx/effects';
import { ChatEffects } from './state/chat/chat.effects';
import { ChatStateModule } from './state/chat/chat-state.module';
import { ChatDetailEffects } from './state/chat/chat-detail/chat-detail.effects';
import { ChatDetailStateModule } from './state/chat/chat-detail/chat-detail-state.module';
import { TicketEffects } from './state/asset-request/ticket/ticket.effects';
import { TicketStateModule } from './state/asset-request/ticket/ticket-state.module';
import { CategoryListStateModule } from './state/category-list/category-list-state.module';
import { CategoryListEffects } from './state/category-list/category-list.effects';
import { EnvConfiguratorModule } from '@saep-ict/env-configurator';
import { PouchdbModule } from '@saep-ict/pouch-db';
import { CustomerAppConfig } from './customer-app.config';
import { BrandsResolver } from './service/resolver/brands.resolver';
import { CommercialAreasResolver } from './service/resolver/commercial-areas.resolver';
import { CurrenciesResolver } from './service/resolver/currencies.resolver';
import { UtilService } from './service/util/util.service';
import { PermissionUtilService } from './service/util/permission-util.service';
import { SurveyService } from './service/rest/survey-center/survey.service';
import { BrandsService } from './service/util/survey-center/brands.service';
import { SurveyMessageService } from './service/util/survey-center/survey-message.service';
import { CommercialAreasService } from './service/util/commercial-areas.service';
import { CurrenciesService } from './service/util/survey-center/currencies.service';
import { UserStateModule } from './state/user/user-state.module';
import { RemoteChangeStateModule } from './state/remote-change/remote-change-state.module';
import { PageSettingsStateModule } from './state/page-settings/page-settings-state.module';
import { PermissionResolver } from './service/resolver/permission.resolver';
import { PouchDbCommonsUserAdapter } from './service/pouchdb/instance/commons/pouchdb-commons-user-adapter.service';
import { PouchDbCommonsOrganizationAdapter } from './service/pouchdb/instance/commons/pouchdb-commons-organization.service';
import { AuthTokenGuard } from './service/guard/auth-token.guard';
import { UtilGuardService } from './service/guard/util-guard/util-guard.service';
import { BackendProcessQueueStateModule } from './state/asset-request/backend-process-queue/backend-process-queue-state.module';
import { BackendProcessQueueEffects } from './state/asset-request/backend-process-queue/backend-process-queue.effects';
import { LoaderService } from './service/structure/loader.service';
import { RedirectExternalUrlService } from './service/structure/redirect-external-url.service';
import { ManageExternalUrlGuard } from './service/guard/manage-external-url.guard';
import { LoginContextCodeBackofficeEffects } from './state/common/login/context-code/backoffice/login-context-code-backoffice.effects';
import { LoginContextCodeBackofficeStateModule } from './state/common/login/context-code/backoffice/login-context-code-backoffice.module';
import { LoginContextCodeCompanyEffects } from './state/common/login/context-code/b2b/login-context-code-company.effects';
import { LoginContextCodeCompanyStateModule } from './state/common/login/context-code/b2b/login-context-code-company-state.module';
import { AssetRequestCategoryResolver } from './service/resolver/asset-request-category.resolver';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SaepICTAngularCoreModule, TranslateMultiHttpLoader } from '@saep-ict/angular-core';
import { Language } from './enum/language.enum';
import { PouchDbCommonsBackofficeAdapter } from './service/pouchdb/instance/commons/pouchdb-commons-backoffice.service';
import { PouchDbCommonsAdapter } from './service/pouchdb/instance/commons/pouchdb-commons.service';
import { OrganizationListStateModule } from './state/organization-list/organization-list-state.module';
import { OrganizationListEffects } from './state/organization-list/organization-list.effects';
import { AssetRequestExportExcelService } from './service/export/asset-request-export-excel';
import { ChatPdfExportService } from './service/print-chat-pdf/chat-pdf.service';
import { ChatPdfStateModule } from './state/chat/chat-pdf/chat-pdf.module';

// export function createTranslateLoader(http: HttpClient) {
// 	debugger;
// 	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient, customerAppConfig: CustomerAppConfig) {
	return new TranslateMultiHttpLoader(http, customerAppConfig, [
		// project bundle
		{ prefix: './assets/i18n/', suffix: '.json' },
		// lib bundle
		{ configKey: 'saepIctAngularCore_config' }
	]);
}

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
// 	suppressScrollX: false
// };

export function customResourceHandlerFactory(http: HttpClient) {
	// debugger;
	return new ResourceUrlEncodedHandler(http);
}

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector,
	customerAppConfig: CustomerAppConfig,
	localStorageService: LocalStorageService
) {
	return () =>
		new Promise<any>((resolve: any) => {
			customerAppConfig.libInitializationDone$.subscribe((e: boolean) => {
				if (e) {
					const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
					locationInitialized.then(() => {
						const langToSet = localStorageService.retrieve('language') || Language.DEFAULT;
						translate.setDefaultLang(langToSet);
						translate.use(langToSet).subscribe(
							() => {},
							err => {
								console.error(`Problem with '${langToSet}' language initialization.'`);
							},
							() => {
								resolve(null);
							}
						);
					});
				}
			});
		});
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RoutingModule,
		NgScrollbarModule,
		HttpClientModule,
		ResourceModule.forRoot({
			handler: { provide: ResourceHandler, useFactory: customResourceHandlerFactory, deps: [HttpClient] }
		}),
		NgxWebstorageModule.forRoot(),
		EnvConfiguratorModule.forRoot({ env: environment, appConfig: CustomerAppConfig }),
		PouchdbModule.forRoot({
			config: [
				PouchDbChatAdapter,
				PouchDbChatAssetRequestAdapter,
				PouchDbSurveyCenterAdapter,
				PouchDbAssetRequestCenterAdapter,
				PouchDbCommonsUserAdapter,
				PouchDbCommonsAdapter,
				PouchDbCommonsOrganizationAdapter,
				PouchDbCommonsBackofficeAdapter
			],
			appConfig: CustomerAppConfig
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient, CustomerAppConfig]
			}
		}),
		// saep-ict
		SaepICTAngularCoreModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		// NgRx - Store
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false
				}
			}
		),
		RemoteChangeStateModule,
		PageSettingsStateModule,
		UserStateModule,
		ChatDetailStateModule,
		ChatStateModule,
		ChatPdfStateModule,
		TicketStateModule,
		CategoryListStateModule,
		// login
		LoginContextCodeBackofficeStateModule,
		LoginContextCodeCompanyStateModule,
		// backoffice
		BackendProcessQueueStateModule,
		BackendProcessQueueStateModule,
		OrganizationListStateModule,
		EffectsModule.forRoot([
			ChatEffects,
			ChatDetailEffects,
			TicketEffects,
			CategoryListEffects,
			// login
			LoginContextCodeBackofficeEffects,
			LoginContextCodeCompanyEffects,
			// backoffice
			BackendProcessQueueEffects,
			OrganizationListEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		// structure modules
		FrameModule,
		PageModule,
		InjectorModule
	],
	providers: [
		AuthTokenGuard,
		UtilGuardService,
		ManageExternalUrlGuard,
		RedirectExternalUrlService,
		// couchDbAdapter start
		PouchDbChatAdapter,
		PouchDbChatAssetRequestAdapter,
		PouchDbSurveyCenterAdapter,
		PouchDbAssetRequestCenterAdapter,
		PouchDbCommonsUserAdapter,
		PouchDbCommonsAdapter,
		PouchDbCommonsOrganizationAdapter,
		PouchDbCommonsBackofficeAdapter,
		// couchDbAdapter end
		DeviceDetectorService,
		MatIconRegistry,
		UtilService,
		PermissionUtilService,
		AssetRequestExportExcelService,
		SurveyService,
		BrandsService,
		CurrenciesService,
		CommercialAreasService,
		SurveyMessageService,
		ChatPdfExportService,
		// Resolvers
		PermissionResolver,
		BrandsResolver,
		CommercialAreasResolver,
		CurrenciesResolver,
		AssetRequestCategoryResolver,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector, CustomerAppConfig, LocalStorageService],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
	}
}
