import { Injectable } from '@angular/core';
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { LoaderService } from '../../../structure/loader.service';
import { HttpClient } from '@angular/common/http';
import { BasePouch } from '../../pouch-common-function/base-common.pouch';

@Injectable()
export class PouchDbCommonsAdapter extends PouchDbAdapter {
	baseDatabaseTemplate = 'commons';
	database = 'commons';
	basePouch: BasePouch;

	constructor(
		protected config: CustomerAppConfig,
		offlineDeviceService: OfflineDeviceService,
		private loaderService: LoaderService,
		private httpClient: HttpClient,
		private transmissionService: TransmissionService,
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.basePouch = new BasePouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
	}
}
