<h1>Save Chat as PDF file</h1>

<button mat-button (click)="print()" [mat-dialog-close]="false">
	{{ 'general.save' | translate }}
</button>
<button mat-button [mat-dialog-close]="false">
	{{ 'general.cancel' | translate }}
</button>

<div style="display: none">
	<div id="print-section" style="width: 1000px">
		<table class="header">
            <tr>
                <td style="width: 40%">
                    <img src="{{ company_variables.logo_path.header }}" class="logo" alt="{{company_variables.company.name}} logo"/>
                </td>
                <td style="width: 30%">
                    <dl class="header">
                        <div>
                            <dt>{{ 'chat.name' | translate  }}</dt><dd>{{ company_variables.company.name }}</dd>
                        </div>
                        <div>
                            <dt>{{ 'chat.address' | translate  }}</dt>
                            <dd></dd>
                            <div>
                                <dt class="nocomma">&nbsp;&nbsp;&nbsp;</dt><dd>{{ company_variables.company.address}}</dd>
                            </div>
                            <div>
                                <dt>{{ 'chat.location' | translate  }}</dt>
                                <dd></dd>
                                <div>
                                    <dt class="nocomma">&nbsp;&nbsp;&nbsp;</dt><dd>{{ company_variables.company.location}}</dd>
                                </div>
                            </div>
                        </div>
                    </dl>
                </td>
                <td style="width: 30%">
                    <dl class="header">
                        <div>
                            <dt>{{ 'chat.vat' | translate  }}</dt><dd>{{ company_variables.company.vat }}</dd>
                        </div>
                        <div>
                            <dt>{{ 'chat.phone' | translate  }}</dt><dd>{{ company_variables.company.phone }}</dd>
                        </div>
                        <div>
                            <dt>{{ 'chat.email' | translate  }}</dt><dd>{{ company_variables.company.email }}</dd>
                        </div>
                        <div>
                            <dt>{{ 'chat.pec' | translate  }}</dt><dd>{{ company_variables.company.pec }}</dd>
                        </div>
                    </dl>
                </td>
                <td>
        
                </td>
            </tr>
        
        </table> 

        <!-- Title: num ticket - side chat -->
		<div class="header">
			<p class="title"> {{'chat.ticket' | translate | sentencecase | uppercase}} {{ ticket | assetRequestIdFormatter | sentencecase}} - 
                    {{ 'chat.chat' | translate | uppercase }} {{ userType === requestCenterEnum.User.type.EUROITALIA ? (side | uppercase) : ('Euroitalia' | uppercase) }}</p>
		</div>
        <br>
		<div>
			<div *ngFor="let msg of pdfData?.data?.messages">
				<span>
					<h2>{{ msg.username }} - {{ msg.ts  | dateTranformFormatter}}</h2>
                </span> 
				<div>
					<h3>{{ msg.msg }}</h3>
				</div>
			</div>
		</div>
	</div>
</div>
