import { Injectable } from '@angular/core';
import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise } from '@ngx-resource/core';
import { BaseApiService } from './baseApi.service';
import { MessageModel } from '../../model/pouch/chat.model';

@Injectable()
export class AssetRequestChatService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/chat/send-message-asset-request'
	})
	sendMessage: IResourceMethodPromise<MessageModel, void>;

}
