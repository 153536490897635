<ms-loading-overlay></ms-loading-overlay>
<mat-sidenav-container>
  <mat-sidenav-content>
    <ng-scrollbar
      [autoWidthDisabled]="true"
      [autoHeightDisabled]="false"
      [style.max-height.px]="mediaReplayService.deviceH - 115"
    >
      <div #scrollContainer>
        <div [style.min-height]="mediaReplayService.deviceH + 'px'">
          <div class="container">
            <router-outlet class="page"></router-outlet>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </mat-sidenav-content>
</mat-sidenav-container>

<!--<div class="container">-->
<!--    <router-outlet class="page"></router-outlet>-->
<!--</div>-->