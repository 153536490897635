import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'assetRequestIdFormatter'
})
export class AssetRequestIdFormatterPipe implements PipeTransform {
	transform(value: any) {
		if (!value) {
			return value;
		}
		return value.slice(7, 15).toUpperCase();
	}
}