export const company_variables = {
	title: 'EUROITALIA S.R.L.',
	copyright_owner: 'EUROITALIA S.R.L. ',
	logo_path: {
		header: 'assets/img/logo/logo.png',
		footer: 'assets/img/logo-spin8-generic-icon.svg'
	},
	color: {
		primary: '#66CC33',
		accent: '#FF6600'
	},
	company: {
		address: 'Via G.Galilei 5 Cavenago di Brianza ',
		location: 'MB 20873 Italy',
		email: 'euroitalia.italy@euroitalia.it',
		name: 'EuroItalia S.r.l.',
		pec: 'euroitalia@pec.euroitalia.net',
		phone: '+39 02959161',
		vat: '00788550960'
	}
};