import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

import { CustomerAppConfig } from '../../customer-app.config';
import { LinkCodeModel } from '../../model/user.model';
import { RedirectExternalUrlService } from '../structure/redirect-external-url.service';

export interface RedirectionExtras extends NavigationExtras {
	target?: string;
}

@Injectable()
export class ManageExternalUrlGuard implements CanActivate {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	@LocalStorage('springAuthenticationToken')
	springAuthenticationToken: string;

	@LocalStorage('link_code') link_code: LinkCodeModel;

	constructor(
		private redirectExternalUrlService: RedirectExternalUrlService,
		private appConfig: CustomerAppConfig,
		readonly router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const externalUrlContext = this.appConfig.config.externalUrlContext;
		if (route.data['externalPath'] && externalUrlContext) {
			const redirectPath = externalUrlContext.find(context => {
				return context.codeItem === route.data['externalPath'];
			});
			if (redirectPath && redirectPath.path) {
				const parameterList = {
					token: this.authenticationToken,
					context_application: this.link_code.context_application,
					context_code: this.link_code.context_code,
					spring_token: this.springAuthenticationToken
				};
				const pathParameter = Object.keys(parameterList)
					.map(key => '/' + parameterList[key])
					.join('');
				return this.redirectExternalUrlService.redirect(`${redirectPath.path}${pathParameter}`);
			}
		}
		// Goes on activating the requested route, most likely to NotFound
		return true;
	}
}
