import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class ChatColumnService extends BaseTableListService {
    constructor() {
        super();
    }
    _columns: ITdDataTableColumnCustom[] = [
        {
            name: "type",
            label: "Thread"
        },
        {
            name: "chat.last_message",
            label: "Last Message",
            format: (v: number) => moment(v).format('DD/MM/YY, HH:mm')
        }
    ];
}
