import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

// model
import { MessageModel, ChatModel } from '../../model/pouch/chat.model';

// widget & utility
import { MediaReplayService } from '@saep-ict/angular-core';
import { NgScrollbar } from 'ngx-scrollbar';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';

@Component({
	selector: 'app-chat-thread',
	templateUrl: './chat-thread.component.html',
	styleUrls: ['./chat-thread.component.scss']
})
export class AppChatThreadComponent implements OnInit, OnDestroy, AfterViewInit {
	_chat: ChatModel = new ChatModel();
	/**
	 * L'input intercetta il cambio dell'oggetto chat e in base al numero di pagina della prop. thread effettua
	 * - pagina 1: scroll to bottom
	 * - pagina !1: scroll per porre a vista il più vecchio messaggio ottenuto dall'ultimo input dati
	 *
	 * Gli eventi associati a perfectScrollbar devono passare tramite setTimeout altrimenti l'oggetto risulta ancora undefined
	 */
	@Input() set chat(c: ChatModel) {
		if (c) {
			this.cdr.detectChanges();
			this._chat = Object.assign({}, c);
			if (this._chat.thread.pagination.page_current !== 1) {
				setTimeout(() => {
					this.perfectScrollbar.scrollToElement('#' + this.topMessageId, null);
				}, 0);
			} else {
				setTimeout(() => {
					this.perfectScrollbar.scrollTo({ bottom: 0, duration: 300 });
				}, 0);
			}
		}
	}
	@Input() sender: string;
	@Input() disabled = false;
	@Input() threadHeight: number = null;

	@Output() onSendMessage = new EventEmitter();
	@Output() onReachTop = new EventEmitter();

	form: FormGroup;

	/**
   *  workaround per eliminare  gli errori di validazione generati dall'eventuale submit di un altro brand
      in sostituzione degli'inefficaci .reset() .markeAs*() ecc
   */
	@ViewChild('resetTag') formResetTag;
	@ViewChild('perfectScrollbar') perfectScrollbar: NgScrollbar;
	/**
   *  Firefox: scatenando this.key.next(true); direttamente qui dentro, per qualche motivo
        non viene notificato l'evento @Input() set chat(c: ChatState), nonostante tutto il resto del flusso continui a funzionare
        workaround:
        all'interno di questa condizione, viene simulato un click su un button in hidden
        a sua volta collegato al salvataggio del topMessage e allo scatenamento del metodo debounce che emette
        la richiesta di nuovi messaggi vera e propria
   */
	@ViewChild('clickEventTriggerTag') clickEventTrigger: ElementRef;

	/**
	 * ID dell'ultimo messaggio visualizzato dall'utente, usato per risettare lo scroll una volta caricate le pagine successive
	 */
	topMessageId: string;

	// Unsubscriber for elementScrolled stream.
	private scrollSubscription = Subscription.EMPTY;

	private subscription: Subscription;
	private key = new Subject();

	constructor(
		private fb: FormBuilder,
		public mediaReplayService: MediaReplayService,
		private cdr: ChangeDetectorRef
		) {
		this.createForm();
		// emitter della paginazione dei messaggi
		this.subscription = this.key.pipe(debounceTime(1000)).subscribe(e => this.onReachTop.emit(event));
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.perfectScrollbar.scrolled.subscribe(e => console.log(e));
		    // Subscribe to scroll event
			this.scrollSubscription = this.perfectScrollbar.scrolled.pipe(
				//map((e: any) => e.target. > 50 ? '0.75em' : '1em'),
				//tap((e: any) => this.zone.run(() => this.scrollingUp(e)))
				tap((e: any) => this.scrollingUp(e))
			  ).subscribe();
	  }

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.scrollSubscription.unsubscribe();
	}

	// form
	createForm() {
		this.form = this.fb.group({
			text: ['', Validators.required]
		});
	}

	prepareSaveForm(): MessageModel {
		const formModel = this.form.value;
		const saveForm: MessageModel = {
			text: formModel.text as string,
			date_sending: Date.now()
		};
		return saveForm;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.onSendMessage.emit(this.prepareSaveForm());
			this.formResetTag.resetForm();
		}
	}

	// events
	scrollingUp(e) {
		if (e.target.scrollTop > 10) {
			this.clickEventTrigger.nativeElement.click();
		}
	}

	/**
	 * metodo bindato on click al button hidden
	 *  1 salva l'ID dell'ultimo messaggio visualizzato per il riposizionamento del thread dopo l'arrivo delle successive pagine
	 *  2 scatena il metodo in debounce per emettere la richiesta della pagina di messaggi successiva
	 */
	emitForNextPage() {
		this.topMessageId = this._chat.thread.data[0]._id;
		this.key.next(true);
	}
}
