import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// model
import {
	SurveyModel,
	BrandItemModel,
	SubmitSurveyModel,
	SendToEnum,
	NotificationType,
	SubmitCurrencyModel
} from '../../../model/survey-center/survey.model';
import { ChatModel, ThreadItemModel } from '../../../model/survey-center/chat.model';
import { FileUpload } from '../../../model/asset-request/fileUpload.model';

// rest
import { SurveyService } from '../../../service/rest/survey-center/survey.service';

// widget
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogConfirmComponent } from '../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { UtilService } from '../../../service/util/util.service';
import { MediaReplayService } from '@saep-ict/angular-core';
import { BrandsService } from '../../../service/util/survey-center/brands.service';
import { CommercialAreasService } from '../../../service/util/commercial-areas.service';
import { CurrenciesService } from '../../../service/util/survey-center/currencies.service';
import * as moment from 'moment';
import { PouchDbSurveyCenterAdapter } from '../../../service/pouchdb/instance/survey-center/pouchdb-survey-center-adapter.service';
import { Brand } from '../../../model/brand.model';
import { SurveyMessageService } from '../../../service/util/survey-center/survey-message.service';
import { SurveyConfiguration } from '../../../model/survey-center/survey.model';
import { SurveyListUtilService } from '../../../service/util/survey-list-util.service';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { Observable } from 'rxjs';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';
import { BucketManagerService } from '../../../service/util/util-bucket-manager.service';

@Component({
	selector: 'app-survey-detail',
	templateUrl: './survey-detail.component.html',
	styleUrls: ['./survey-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class SurveyDetailComponent implements OnInit {
	idSurvey: string = this.route.snapshot.paramMap.get('idSurvey');
	survey: SurveyModel = new SurveyModel();
	chat: ChatModel = new ChatModel();

	surveyConf: SurveyConfiguration =
		this.route.snapshot.paramMap.get('surveyType') !== 'all-survey'
			? this.surveyListUtilService.getSurveyConf(this.route.snapshot.paramMap.get('surveyType'))
			: null;

	platformType: 'frontoffice' | 'backoffice' = this.route.snapshot.data['platform'];
	// user
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	public ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	disabled = true;

	constructor(
		private route: ActivatedRoute,
		public location: Location,
		public utilService: UtilService,
		private surveyMessageService: SurveyMessageService,
		private surveyService: SurveyService,
		private dialog: MatDialog,
		public mediaReplayService: MediaReplayService,
		private pouchDbSurveyCenterAdapter: PouchDbSurveyCenterAdapter,
		private brandsService: BrandsService,
		private commercialAreasService: CommercialAreasService,
		private currenciesService: CurrenciesService,
		private snackBar: MatSnackBar,
		private surveyListUtilService: SurveyListUtilService,
		public store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private bucketManager: BucketManagerService,
	) {
		this.subscribeManagerService.populate(this.subscribeUserData().subscribe(), 'subscribe-user');
		this.chat = {
			thread: {
				pagination: {
					page_current: 1,
					page_size: 20
				}
			}
		};
	}

	ngOnInit() {
		this.getSurveyDetail();
		this.getSurveyValidationThread();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribeUserData() {
		return this.user$.pipe(
			filter((userState: BaseStateModel<UserDetailModel>) =>
				userState && userState.data && userState.data.current_permission ? true : false
			),
			map((userState: BaseStateModel<UserDetailModel>) => {
				this.user = userState.data;
				console.log(this.user);
			})
		);
	}

	// navigation
	brandSelectedChange(id) {
		// non copiando l'oggetto il get/set in frontoffice/backoffice non viene scatenato
		const survey: SurveyModel = Object.assign({}, this.survey);
		for (let i = 0; i < this.survey.brand_list.length; i++) {
			if (this.survey.brand_list[i].brand.codice_brand !== id) {
				this.survey.brand_list[i].selected = false;
			} else {
				this.survey.brand_list[i].selected = true;
			}
		}
		this.survey = survey;
	}

	// dialog

	// NB: a dialog di conferma per il cambio della currency scatta direttamente in frontoffice in modo
	// in modo da permetterne il reset del formCurrency in caso di annullamento

	dialogUpdateSurveyFormConfirm(survey: SurveyModel) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: `Update of
					${this.surveyMessageService.returnSurveyConfirmationDialogTitle(
						this.survey.survey_type,
						this.survey.year,
						this.survey.commercial_area
					)}
					Data`,
				text: `Do You want to save
					${this.utilService.brandSelected(this.survey.brand_list).brand.nome_brand}
					declared data?`
			},
			panelClass: ['dialog-small', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.updateSurvey({ brand: this.utilService.brandSelected(survey.brand_list) });
			}
		});
	}

	dialogUpdateSurveyValidationConfirm(survey: SurveyModel) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: `Update of
					${this.surveyMessageService.returnSurveyConfirmationDialogTitle(
						this.survey.survey_type,
						this.survey.year,
						this.survey.commercial_area
					)}
					Validation`,
				text: `Do You want to mark
					${this.utilService.brandSelected(survey.brand_list).brand.nome_brand} as
					${this.utilService.brandSelected(survey.brand_list).status}?`
			},
			panelClass: ['dialog-small', 'michelangelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.updateSurveyApproval(this.utilService.brandSelected(survey.brand_list));
			}
		});
	}

	// rest
	/**
	 * tipo response
	 *  media-marketing_880010_1.json
	 *  budget_880010_1.json
	 *
	 */
	getSurveyDetail() {
		this.pouchDbSurveyCenterAdapter.surveyCenterPouch
			.getSurveyDetail({
				idSurvey: this.idSurvey
			})
			.then(res => {
				this.survey = res;
				this.disabled =
					this.survey.date_end && moment(this.survey.date_end).diff(moment()) <= 0 ? true : false;
				this.survey.brand_list = this.survey.brand_list
					.map(sbl => {
						const brand = this.brandsService.brandsList.find(
							bl => bl.brand.codice_brand === sbl.brand.codice_brand
						);
						if (brand) {
							delete sbl.brand;
							return {
								...brand,
								...sbl
							};
						}
					})
					.filter(brand => brand);
				this.survey.commercial_area = this.commercialAreasService.commercialAreas.find(ca => {
					// gestione temporanea dell'errore dovuto all'assenza delle zone commerciali in alcune tipologie di survey
					// da valutare l'occorrente in fase di salvataggio
					if (this.survey.commercial_area) {
						return ca.codiceElemento === this.survey.commercial_area.codiceElemento;
					} else {
						return false;
					}
				});
				const brandSelected = _.cloneDeep(this.utilService.brandSelected(this.survey.brand_list));
				brandSelected
					? (this.survey.brand_list.find(
							bl => bl.brand.codice_brand === brandSelected.brand.codice_brand
					  ).selected = true)
					: (this.survey.brand_list[0].selected = true);
				this.survey.currency = this.currenciesService.surveyCurrencies.find(
					cu => cu.isoCode === this.survey.currency_name
				);
			})
			.catch(err => {
				console.log(err);
			});
	}

	updateSurvey(body: { brand?: BrandItemModel; currency_name?: string }) {
		if (body.brand) {
			const ssm = new SubmitSurveyModel();
			ssm.id_survey = this.survey._id;
			ssm.client_code = this.survey.client_code;
			ssm.year = this.survey.year;
			ssm.commercial_area = this.survey.commercial_area;
			ssm.brand = this.utilService.brandSelected(this.survey.brand_list).brand;
			ssm.currency_name = this.survey.currency_visible ? this.survey.currency_name : null;
			ssm.survey_type = this.survey.survey_type;
			ssm.survey_form = body.brand.survey_form;
			/** Questa chiamata REST deve salvare in couch le info come previsto in riga 272 del pouchdb-adapter.ts */
			this.surveyService
				.submitSurvey(ssm)
				.then(() => {
					this.snackBar.open('Survey correctly updated', 'OK', {
						duration: 3000
					});
					this.getSurveyDetail();
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			const scm = new SubmitCurrencyModel();
			scm.id_survey = this.survey._id;
			this.survey.currency = this.currenciesService.surveyCurrencies.find(
				cu => cu.isoCode === body.currency_name
			);
			scm.currency_name = this.survey.currency.isoCode;
			scm.exchange_rate = this.survey.currency.eurRate;

			this.surveyService
				.submitCurrency(scm)
				.then(() => {
					this.snackBar.open('Currency correctly updated', 'OK', {
						duration: 3000
					});
					this.getSurveyDetail();
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	updateSurveyApproval(brand: BrandItemModel) {
		this.pouchDbSurveyCenterAdapter.surveyCenterPouch
			.updateSurveyApproval({
				idSurvey: this.idSurvey,
				brand: brand
			})
			.then(res => {
				this.getSurveyDetail();
				this.sendNotification({ brand: brand });
			})
			.catch(err => {
				console.log(err);
			});
	}
	/**
	 * tipo responses
	 *  chat_1.json
	 *  chat_2.json
	 *
	 */
	getSurveyValidationThread() {
		this.pouchDbSurveyCenterAdapter.surveyCenterPouch
			.getSurveyChatDetail({
				idSurvey: this.idSurvey
			})
			.then(res => {
				this.chat.thread.data = this.chatSureveySenderUserInfoAdapter(res);
			})
			.catch(err => {
				console.log(err);
			});
	}

	updateSurveyValidationThread(threadItem: ThreadItemModel) {
		threadItem.sender =
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE
				? 'Euroitalia'
				: null;
		threadItem.type = 'chat';

		this.pouchDbSurveyCenterAdapter.surveyCenterPouch
			.updateChatDetail({
				idSurvey: this.idSurvey,
				threadItem: threadItem
			})
			.then(res => {
				this.chat.thread.data = this.chatSureveySenderUserInfoAdapter(res);
				this.sendNotification({ message: threadItem.text });
			})
			.catch(err => {
				console.log(err);
			});
	}

	sendNotification(body: { message?: string; brand?: BrandItemModel }) {
		this.surveyService
			.sendNotification({
				send_to:
					this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B
						? SendToEnum.BO
						: SendToEnum.FO,
				notification_type: body.message ? NotificationType.MESSAGE : NotificationType.UPDATE,
				survey_info: {
					client_code: this.survey.client_code,
					survey_type: this.survey.survey_type,
					year: this.survey.year,
					commercial_area: this.survey.commercial_area ? this.survey.commercial_area.descrizioneEstesa : null,
					brand: this.utilService.brandSelected(this.survey.brand_list).brand.nome_brand,
					message: body.message,
					status: body.brand ? body.brand.status : null
				}
			})
			.then(res => {
				// console.log('notification sent')
			})
			.catch(err => {
				console.log(err);
			});
	}

	chatSureveySenderUserInfoAdapter(thread: ThreadItemModel[]) {
		for (let i = 0; i < thread.length; i++) {
			if (thread[i].sender) {
				thread[i].sender_user_info = {
					username: 'Euroitalia'
				};
			}
		}
		return thread;
	}

	downloadTemplateFile(e: Brand) {
		const year = this.survey.year ? this.survey.year.toString() : '';
		const month = this.survey.year ? (new Date(this.survey.date_start).getMonth() + 1).toString() : '';
		this.bucketManager.bucketManagerDownloadTemplate(
			'survey-center',
			`template/${this.survey.survey_type}/${year}/${month}/${e.codice_brand}/${this.survey.survey_type}.xlsx`
		);
	}
	
	onDownloadLatest(e: string) {
		this.bucketManager.bucketManagerDownloadTemplate(
			'survey-center',
			e
		);
	}

}
