<breadcrumb-project-instance
	title="Asset request dashboard"
	subtitle="Home / Asset request"
	[backButton]="false"
></breadcrumb-project-instance>

<div class="row">
	<div class="col-md-12">
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					Expiring requests
					<span class="flex-span"></span>
				</mat-toolbar-row>
			</mat-toolbar>

			<ng-container *ngIf="ticketList?.length; else noData">
				<td-data-table
					#dataTable
					[data]="ticketList"
					[columns]="requestCenterDashboardColumnService.columns"
					[clickable]="true"
					[sortBy]="pageSettings.sort[0].name"
					[sortOrder]="pageSettings.sort[0].order"
					(sortChange)="sortChange($event)"
					(rowClick)="goToDetail($event)"
				>
					<!-- Status -->
					<ng-template tdDataTableTemplate="status" let-row="row" let-value="value">
						<div class="chips-mini">
							<span
								[ngClass]="
									assetRequestUtilService.getLabelfromStatusListRemap(
										requestConfiguration.status.filterRemap,
										'euroitalia',
										row.status
									)
								"
								[matTooltip]="
									utilService.formatPlaceholder(
										assetRequestUtilService.getLabelfromStatusListRemap(
											requestConfiguration.status.filterRemap,
											'euroitalia',
											row.status
										)
									) | titlecase
								"
							></span>
						</div>
					</ng-template>
					<!-- Brand list -->
					<ng-template tdDataTableTemplate="asset_list_brand" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Line list -->
					<ng-template tdDataTableTemplate="asset_list_line" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Diffusion country list -->
					<ng-template tdDataTableTemplate="asset_list_diffusion_country" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Diffusion client list -->
					<ng-template tdDataTableTemplate="asset_list_diffusion_client" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Asset type list -->
					<ng-template tdDataTableTemplate="asset_list_type" let-value="value">
						<ng-container *ngFor="let assetType of value">
							<mat-icon
								[matTooltip]="
									utilService.formatPlaceholder(requestConfiguration.assetType[assetType]) | titlecase
								"
							>
								{{ requestCenterEnum.Asset.icon[requestConfiguration.assetType[assetType]] }}
							</mat-icon>
						</ng-container>
					</ng-template>
				</td-data-table>
			</ng-container>
			<ng-template #noData>
				<p class="placeholder">There are no tickets to display</p>
			</ng-template>

			<pouch-paging-bar
				[pagination]="pageSettings.pagination"
				(change)="paginationChange($event)"
				[data]="ticketList"
			></pouch-paging-bar>
		</mat-card>
	</div>
</div>
