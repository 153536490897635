import { DatePipe } from '@angular/common';
import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { Injectable } from "@angular/core";
import { AssetRequestIdFormatterPipe } from '../../../../shared/pipes/asset-request-id-formatter.pipe';

@Injectable()
export class RequestCenterDashboardColumnService extends BaseTableListService {
	constructor(private datePipe: DatePipe, private assetRequestIdFormatterPipe: AssetRequestIdFormatterPipe) {
		super();
	}

	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'status',
			label: 'Status',
			width: 60
		},
		{
			name: '_id',
			label: 'ID',
			sortable: false,
			width: 100,
			format: (v: string) => {
				return this.assetRequestIdFormatterPipe.transform(v.toUpperCase());
			}
		},
		{
			name: 'asset_list_brand',
			label: 'Brand',
			width: 120
		},
		{
			name: 'asset_list_line',
			label: 'Line',
			width: 120
		},
		{
			name: 'asset_list_diffusion_country',
			label: 'Country',
			width: 120
		},
		{
			name: 'asset_list_diffusion_client',
			label: 'Location',
			width: 120
		},
		{
			name: 'request.assignee',
			label: 'Assignee'
		},
		{
			name: 'request.company.area_commercial',
			label: 'Commercial Area'
		},
		{
			name: 'asset_list_number',
			label: 'Requests',
			width: 80
		},
		{
			name: 'asset_list_type',
			label: 'Type',
			width: 120
		},
		{
			name: 'request.date_request',
			label: 'Requested date',
			numeric: true,
			width: 120,
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			sortable: true
		},
		{
			name: 'request.date_due',
			label: 'Due date',
			numeric: true,
			width: 120,
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			sortable: true
		}
	];
}
