import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// model
import {
	SurveyListModel,
	BrandApprovationStatus,
	SurveyConfiguration
} from '../../../model/survey-center/survey.model';
import { Filters, PageSettingsModel, Pagination } from '../../../model/structure/AbstractListModel';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { UserDetailModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state/index';
import * as fromPageSettingsState from '../../../state/page-settings/page-settings.actions';

// widget & utility
import { SurveyListColumnService } from '../../../service/td-data-table/implementation/survey-center/survey-list-column.service';
import { ITdDataTableRowClickEvent, ITdDataTableSortChangeEvent, TdDataTableComponent } from '@covalent/core/data-table';
import { UtilService } from '../../../service/util/util.service';
import { BrandsService } from '../../../service/util/survey-center/brands.service';
import { CommercialAreasService } from '../../../service/util/commercial-areas.service';
import { PouchDbSurveyCenterAdapter } from '../../../service/pouchdb/instance/survey-center/pouchdb-survey-center-adapter.service';
import { Subscription, Observable } from 'rxjs';
import { take, skipWhile, mergeMap } from 'rxjs/operators';
import { FormValidatorCustom } from '../../../shared/misc/form-validator';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { SurveyListUtilService } from '../../../service/util/survey-list-util.service';
import * as _ from 'lodash';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'app-survey-list',
	templateUrl: './survey-list.component.html',
	styleUrls: ['./survey-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class SurveyListComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	public ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	// user
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(StateFeature.getOrganizationList);
	organizationList: OrganizationPouchModel[];

	surveyConf: SurveyConfiguration =
		this.route.snapshot.paramMap.get('surveyType') !== 'all-survey'
			? this.surveyListUtilService.getSurveyConf(this.route.snapshot.paramMap.get('surveyType'))
			: null;

	clientCode: string;
	surveys: SurveyListModel = new SurveyListModel();
	surveyYears: number[] = [];
	formFilters: FormGroup;
	changeFormFilterSubscribe: Subscription;
	brandApprovationStatusList = Object.values(BrandApprovationStatus);

	pageSettingsState$: Observable<PageSettingsModel> = this.store.select(StateFeature.getPageSettingsState);
	pageSettings: PageSettingsModel = {
		pageName: `survey_${this.surveyConf ? this.surveyConf.type : null}_list`,
		pagination: {
			page_current: 1,
			page_size: 10
		},
		filters: {
			text: null
		},
		sort: [
			{
				name: 'client_code',
				order: 'asc'
			}
		]
	};

	componentInit = true;
	/**
	 *  workaround per eliminare  gli errori di validazione generati dall'eventuale submit di un altro brand
		in sostituzione degli'inefficaci .reset() .markeAs*() ecc
	*/
	@ViewChild('resetTag') formResetTag;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public location: Location,
		private fb: FormBuilder,
		private store: Store<any>,
		public surveyListColumnService: SurveyListColumnService,
		public utilService: UtilService,
		private permissionUtilService: PermissionUtilService,
		private pouchDbSurveyCenterAdapter: PouchDbSurveyCenterAdapter,
		public brandsService: BrandsService,
		public commercialAreasService: CommercialAreasService,
		private subscribeManagerService: SubscribeManagerService,
		private surveyListUtilService: SurveyListUtilService,
		public utilStoreService: StoreUtilService,
	) {
		this.loadStaticData();
		this.subscribeManagerService.populate(
			this.formFilters.valueChanges.subscribe(() => {
				this.onFormFiltersSubmit();
			}),
			'form-filters'
		);
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
		});
		this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(e => {
			this.organizationList = e.data;
		});
		// se l'utente è di tibo B2B organizationList store restituisce in array solo la sua organization
		this.clientCode =
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B ?
			this.organizationList[0].code_erp :
			null;
		this.subscribeManagerService.populate(this.getPageSettings().subscribe(), 'page-settings');
	}

	// form - filters

	createFormFilters() {
		this.formFilters = this.fb.group({
			commercialAreas: [
				{
					value:
						this.pageSettings.filters.commercialAreas &&
						this.pageSettings.filters.commercialAreas.length > 0
							? this.pageSettings.filters.commercialAreas
							: [],
					disabled: true
				}
			],
			years: [
				{
					value:
						this.pageSettings.filters.years && this.pageSettings.filters.years.length > 0
							? this.pageSettings.filters.years
							: [],
					disabled: true
				}
			],
			brandStatus: this.fb.group(
				{
					brandList: [
						{
							value:
								this.pageSettings.filters.brandList && this.pageSettings.filters.brandList.length > 0
									? this.pageSettings.filters.brandList
									: [],
							disabled: true
						}
					],
					brandApprovationStatusList: [
						{
							value:
								this.pageSettings.filters.brandApprovationStatusList &&
								this.pageSettings.filters.brandApprovationStatusList.length > 0
									? this.pageSettings.filters.brandApprovationStatusList
									: [],
							disabled: true
						}
					]
				},
				{
					validators: [FormValidatorCustom.allEmptyOrAllFilled()]
				}
			)
		});
	}

	resetForm() {
		this.formFilters.patchValue({
			commercialAreas: [],
			years: []
		});
		this.formFilters.get('brandStatus').setValue(
			{
				brandList: [],
				brandApprovationStatusList: []
			},
			{
				validators: [FormValidatorCustom.allEmptyOrAllFilled()]
			}
		);
		this.onFormFiltersSubmit();
	}

	prepareSaveFormFilters(): Filters {
		const formModel = this.formFilters.getRawValue();
		const saveForm: Filters = {
			commercialAreas: formModel.commercialAreas as string[],
			years: formModel.years as number[],
			brandList:
				formModel.brandStatus && formModel.brandStatus.brandList
					? (formModel.brandStatus.brandList as string[])
					: [],
			brandApprovationStatusList:
				formModel.brandStatus && formModel.brandStatus.brandApprovationStatusList
					? (formModel.brandStatus.brandApprovationStatusList as string[])
					: [],
			text: this.pageSettings.filters.text
		};
		return saveForm;
	}

	onFormFiltersSubmit() {
		if (
			this.formFilters.valid ||
			this.formFilters.status === 'DISABLED'
			// alcune tipologie vedono l'intero form disabilitato, comportando formFilters.valid sempre false
		) {
			this.pageSettings.filters = this.prepareSaveFormFilters();
			this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
			this.getSurveyFilteredList();
		}
	}

	// navigation

	goToDetail(e: ITdDataTableRowClickEvent) {
		const url =
			this.router.url +
			'/' +
			([ContextApplicationItemCodeEnum.BACKOFFICE, ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN].includes(
				this.user.current_permission.context_application
			)
				? e.row.client_code + '/'
				: '') +
			e.row._id;
		this.router.navigate([url]);
	}

	paginationChange(pagination: Pagination) {
		this.pageSettings.pagination = pagination;
		this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
		this.getSurveyFilteredList();
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		this.pageSettings.sort = [
			{
				name: e.name,
				order: e.order.toLowerCase()
			}
		];
		this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
		this.getSurveyFilteredList();
	}

	toggleColumn(e) {
		for (let i = 0; i < this.surveyListColumnService.columns.length; i++) {
			if (this.surveyListColumnService.columns[i].name === e.name) {
				this.surveyListColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	// data

	/**
	 * Controlla lo page-settings-state
	 * Effettua la get eventualmente filtrata per i precedenti valori relativi alla paginazione
	 */

	getPageSettings() {
		return this.pageSettingsState$.pipe(
			take(1),
			skipWhile((pageSettings: PageSettingsModel) => !pageSettings),
			mergeMap((pageSettings: PageSettingsModel) => {
				if (pageSettings.pageName === this.pageSettings.pageName) {
					this.pageSettings = pageSettings;
				} else {
					this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
				}
				this.createFormFilters();
				this.getSurveyFilteredList();
				return this.pageSettingsState$;
			})
		);
	}

	/**
	 * tipo response
	 *  budget_880010.json
	 *  media-marketing_880010.json
	 *
	 * legenda di brand_list.status:
	 *
	 * UNSENT (grigio): brand mai inviato dal cliente
	 * REJECTED (rosso): brand inviato dal cliente e bocciato da Euroitalia
	 * PENDING (giallo): brand inviato dal cliente e non ancora validato da Euroitalia
	 * APPROVED (verde): brand inviato dal cliente e approvato da Euroitalia
	 */
	getSurveyFilteredList() {
		let clientCodeList: string[];
		if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B) {
			clientCodeList = [this.clientCode];
		}
		if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE) {
			if (
				this.user.current_permission.context_code.context_code_association_list &&
				this.user.current_permission.context_code.context_code_association_list.length > 0
			) {
				const associationB2B =
					this.user.current_permission.context_code.context_code_association_list.find(i =>
						i.code === ContextApplicationItemCodeEnum.B2B
					);
				if (associationB2B) {
					if (!associationB2B.context_code_list_all) {
						clientCodeList = this.permissionUtilService.returnUserBackofficeContextCodeAssociated(
							this.user,
							ContextApplicationItemCodeEnum.B2B,
							this.organizationList
						);
					}
				} else {
					clientCodeList = ['NO_PRIVILEGES'];
				}
			} else {
				clientCodeList = ['NO_PRIVILEGES'];
			}
		}
		// if user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN
		// risulta nella casistica !clientCodeList
		if (!clientCodeList || (clientCodeList && clientCodeList.length > 0 && clientCodeList[0] != 'NO_PRIVILEGES')) {
			this.pouchDbSurveyCenterAdapter.surveyCenterPouch
				.getSurveyFilteredList({
					surveyType: this.surveyConf ? this.surveyConf.type : null,
					client_code: clientCodeList,
					pagination: this.pageSettings.pagination,
					filters: this.pageSettings.filters,
					sort: this.pageSettings.sort
				})
				.then(async res => {
					this.pageSettings.pagination.total_element = res.total_element;
					this.surveys.data = res.data;

					// form filters
					this.surveys.data = this.surveys.data.map(d => {
						d.brand_list = d.brand_list.map(sbl => {
							const brand = this.brandsService.brandsList.find(
								bl => bl.brand.codice_brand === sbl.brand.codice_brand
							);
							delete sbl.brand;
							return {
								...brand,
								...sbl
							};
						});
						return {
							commercial_area: this.commercialAreasService.commercialAreas.find(
								ca => d.commercial_area && ca.codiceElemento === d.commercial_area.codiceElemento
							),
							...d
						};
					});
					if (this.surveyYears.length === 0) {
						let yearList: number[] = [...new Set(this.surveys.data.map(sur => sur.year))];
						yearList = yearList.filter(i => i);
						this.surveyYears = yearList;
					}

					this.checkNavigationControlVisibility();
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	checkNavigationControlVisibility() {
		if (this.componentInit) {
			this.componentInit = false;

			this.surveyListColumnService.columns[
				this.utilService.getElementIndex(this.surveyListColumnService.columns, 'name', 'client_code')
			].hidden = [
				ContextApplicationItemCodeEnum.BACKOFFICE,
				ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN
			].includes(this.user.current_permission.context_application)
				? false
				: true;

			this.surveyListColumnService.columns[
				this.utilService.getElementIndex(this.surveyListColumnService.columns, 'name', 'business_name')
			].hidden = [
				ContextApplicationItemCodeEnum.BACKOFFICE,
				ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN
			].includes(this.user.current_permission.context_application)
				? false
				: true;

			// Commercial area
			if (this.surveyConf && !this.surveyConf.commercial_area) {
				this.surveyListColumnService.columns[
					this.utilService.getElementIndex(
						this.surveyListColumnService.columns,
						'name',
						'commercial_area.descrizioneBreve'
					)
				].hidden = true;
			} else {
				this.formFilters.get('commercialAreas').enable({ emitEvent: false });
			}

			// Validation
			if (this.surveyConf && !this.surveyConf.validation) {
				this.surveyListColumnService.columns[
					this.utilService.getElementIndex(this.surveyListColumnService.columns, 'name', 'brand_list')
				].hidden = true;
			} else {
				this.formFilters.get('brandStatus').enable({ emitEvent: false });
			}

			// Duration month
			if (this.surveyConf && !this.surveyConf.duration_month) {
				this.surveyListColumnService.columns[
					this.utilService.getElementIndex(this.surveyListColumnService.columns, 'name', 'date_start')
				].hidden = true;
				this.surveyListColumnService.columns[
					this.utilService.getElementIndex(this.surveyListColumnService.columns, 'name', 'date_end')
				].hidden = true;
			} else {
				this.formFilters.get('years').enable({ emitEvent: false });
			}

			// la colonna survey_type è visibile soltanto se ti trovi in All Survey (!this.platformType)
			// oppure non ci sono altre colonne da mostrare in base ai controlli precedenti di questo metodo
			const list = _.cloneDeep(this.surveyListColumnService.columns);
			if (this.surveyConf && list.filter(column => !column.hidden && column.name !== 'survey_type').length > 0) {
				const indexSurveyType = this.utilService.getElementIndex(
					this.surveyListColumnService.columns,
					'name',
					'survey_type'
				);
				this.surveyListColumnService.columns[indexSurveyType].hidden = true;
			}
		}
	}
}
