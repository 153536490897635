<td-data-table
  [columns]="checkerListService.columns"
  [data]="rows"
  class="text-right-col-2 text-right-col-3 text-right-col-4"
>
  <ng-template tdDataTableTemplate="name" let-value="value">
    {{utilService.formatPlaceholder(value) | titlecase}}
  </ng-template>
  <ng-template tdDataTableTemplate="file_latest" let-value="value">
    <a
      mat-icon-button
      *ngIf="value"
      href="javascript:void(0)"
      (click)="onDownloadLatest.emit(value.link)"
    >
        <mat-icon>cloud_download</mat-icon>
    </a>
  </ng-template>
</td-data-table>
<div *ngIf="enableValidation" class="row validation-actions">
  <div class="col-12">
    <button mat-raised-button class="REJECTED" (click)="onValidate(BrandApprovationStatus.REJECTED)">REJECT</button>
    <button mat-raised-button class="APPROVED" (click)="onValidate(BrandApprovationStatus.APPROVED)">APPROVE</button>
  </div>
</div>
