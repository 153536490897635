import { AbstractListModel } from '../structure/AbstractListModel';
import { CommercialAreaModel } from '../commercial-area.model';
import { Brand } from '../brand.model';
import { FileUpload } from '../asset-request/fileUpload.model';
import { FormFieldItem } from '../util.model';

export class SurveyListModel extends AbstractListModel<SurveyModel[]> {}

export enum SurveyType {
	budget = 'budget',
	media_marketing = 'media-marketing'
}

export enum SurveyStatus {
	// budget tipo CURRENT:
	//     relativo all’anno corrente, visibile sempre selezionando l’anno corrente
	CURRENT = 'CURRENT',
	// budget di tipo ACTUAL:
	//     consolidamento dell’anno precedente, visibile se ancora disponibile (primi mesi dell'anno corrente)
	//     selezionando l’anno precedente rispetto al corrente
	ACTUAL = 'ACTUAL',
	// budget di tipo NEXT_YEAR:
	//     possibilità di iniziare a compilare il budget dell’anno successivo,
	//     visibile se già disponibile (ultimi mesi dell'anno corrente) selezionando l’anno successivo rispetto al corrente
	NEXT_YEAR = 'NEXT_YEAR'
}

export class SurveyModel {
	_id: string;
	type: string;
	client_code: string;
	commercial_area: CommercialAreaModel;
	date_start: number;
	date_end: number;
	year: number;
	status: SurveyStatus;
	brand_list: BrandItemModel[];
	currency_visible: boolean;
	currency: CurrencyModel;
	currency_name: string;
	survey_type: SurveyType;
	validation: boolean;
}

export class CurrencyModel {
	isoCode: string;
	eurRate: string;
	country: string;
}

export enum BrandApprovationStatus {
	APPROVED = 'APPROVED', // (verde):  brand inviato dal cliente e approvato da Euroitalia
	PENDING = 'PENDING', // (giallo): brand inviato dal cliente e non ancora validato da Euroitalia
	REJECTED = 'REJECTED', // (rosso):  brand inviato dal cliente e bocciato da Euroitalia
	UNSENT = 'UNSENT' // (grigio): brand mai inviato dal cliente
}

export class BrandItemModel {
	brand: Brand;
	status?: BrandApprovationStatus;
	date_last_update?: number;
	selected?: boolean;
	survey_form?: SurveyFieldModel[];
}

export interface SurveyFieldModel extends FormFieldItem {
	extra_file: { name: string; data: string }[];
	file_latest?: FileUpload;
}

export class SubmitSurveyModel {
	id_survey: string;
	id_brand: number;
	client_code: string;
	year: number;
	commercial_area: CommercialAreaModel;
	brand: string;
	currency_name: string;
	survey_type: string;
	excel_key = ['name', 'value'];
	survey_form: SurveyFieldModel[];
}

export class SubmitCurrencyModel {
	id_survey: string;
	currency_name: string;
	exchange_rate: string;
	excel_key = ['name', 'value'];
}

export class SendNotificationModel {
	send_to: SendToEnum;
	notification_type: NotificationType;
	survey_info: {
		client_code: string;
		survey_type: string;
		year: number;
		commercial_area: string;
		brand: string;
		message: string;
		status: string;
	};
}

export enum SendToEnum {
	FO = 'FO',
	BO = 'BO'
}

export enum NotificationType {
	CREATE = 'CREATE',
	REMINDER = 'REMINDER',
	UPDATE = 'UPDATE',
	MESSAGE = 'MESSAGE'
}

export class SurveyConfiguration {
	type: string;
	commercial_area?: boolean;
	duration_month?: boolean;
	notifications_year?: number;
	template?: boolean;
	currency?: boolean;
	validation?: boolean;
  multibrand?: boolean;
}
