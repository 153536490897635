import { createReducer, on } from '@ngrx/store';
// model
import { 	ChatPdfStateAction } from './chat-pdf.actions';

export namespace ChatPdfStateReducer {


	const _reducer = createReducer(
		on(ChatPdfStateAction.loadChatPdf, state => state),
		on(ChatPdfStateAction.update, (state, param) => {
			state[param.side] = param.chat;
			return JSON.parse(JSON.stringify(state));
		})
	);
	export function reducer(state,action) {
		return _reducer(state, action);
	}

}
