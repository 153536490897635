import { NgModule } from '@angular/core';
import { DropAreaDirective } from './drop-area.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { RequestCenterDialogBaseDirective } from '../../page/asset-request/misc/request-center-dialog-base.directive';

@NgModule({
  declarations: [
    DropAreaDirective,
    ClickOutsideDirective,
    StopPropagationDirective,
    RequestCenterDialogBaseDirective
  ],
  exports: [
    DropAreaDirective,
    ClickOutsideDirective,
    StopPropagationDirective,
  ]
})
export class DirectivesModule { }
