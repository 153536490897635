import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

// model
// import { UserInfo } from "../../model/user.model";

// store
import { Store } from "@ngrx/store";
import { StateFeature } from "../../state";
import { UserStateAction } from "../../state/user/user.actions";

// widget & utility
import { skipWhile, map, take } from 'rxjs/operators';
import { Observable } from "rxjs";
import { AuthService } from '../rest/auth.service';
import { BaseStateModel } from "../../model/state/base-state.model";
import { UserDetailModel } from "@saep-ict/angular-spin8-core";

@Injectable()
export class PermissionResolver implements Resolve<void> {

	// user
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);

	constructor(
		private store: Store<any>,
		private authService: AuthService
	) {

	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> | Promise<any> | any {
		const clientCode =
			route.params['clientCode'] && route.params['clientCode'] ?
			route.params['clientCode'] :
			null;
		if (clientCode) {
			this.authService.clientCode = clientCode;
		}
		// TODO: da deprecare
		// this.subscribeUserData(clientCode).subscribe();
	}

	// subscribeUserData(clientCode?) {
	// 	return this.user$.pipe(
	// 		skipWhile((user: UserInfo) => !(user && user.id)),
	// 		take(1),
	// 		map((user: UserInfo) => {
	// 			if (clientCode) {
	// 				user.client_code = clientCode;
	// 			}
	// 			this.store.dispatch(UserStateAction.loadPermission(user));
	// 		})
	// 	);
	// }

}
