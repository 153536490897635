import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BrandsService } from "../util/survey-center/brands.service";

@Injectable()
export class BrandsResolver implements Resolve<void> {
  constructor(private brandsService: BrandsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.brandsService.getBrandList(route.data['platform']);
  }
}
