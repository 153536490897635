import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { BasePouchModel, BodyTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

import { CustomerAppConfig } from '../../../customer-app.config';
import { BodyTablePouchCustomModel } from '../../../model/pouch/base-pouch.model';
import { BaseState } from '../../../model/state/base-state.model';
import {
    LinkCodeModel
} from '../../../model/user.model';
import { B2BRouteList } from '../../../router/b2b-routing';
import { BackofficeRouteList } from '../../../router/backoffice-routing';
import { StateFeature } from '../../../state';
import { LoginContextCodeBackofficeStateAction } from '../../../state/common/login/context-code/backoffice/login-context-code-backoffice.actions';
import { LoginContextCodeCompanyStateAction } from '../../../state/common/login/context-code/b2b/login-context-code-company.actions';
import {
	UserDetailModel, ContextPermission, UserTypeContextModel, PermissionKeyEnum,
	ContextApplicationItemCodeEnum, UserTypePermission
} from '@saep-ict/angular-spin8-core';
import { noSqlDocSeparator } from '../../../constant/structure.constant';

export namespace PermissionTypeList {
	export const typeList: UserTypeContextModel[] = [
		{
			permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
			route: BackofficeRouteList.routes,
			type: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
			action: LoginContextCodeBackofficeStateAction.load,
			action_key: "backoffice_admin",
			state: StateFeature.getLoginContextCodeBackofficeState,
			description: "Backoffice Area (Admin)"
		},
		{
			permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
			route: BackofficeRouteList.routes,
			type: ContextApplicationItemCodeEnum.BACKOFFICE,
			action: LoginContextCodeBackofficeStateAction.load,
			action_key: "backoffice",
			state: StateFeature.getLoginContextCodeBackofficeState,
			description: "Backoffice Area"
		},
		{
			permission: PermissionKeyEnum.B2B_DASHBOARD,
			route: B2BRouteList.routes,
			type: ContextApplicationItemCodeEnum.B2B,
			action: LoginContextCodeCompanyStateAction.load,
			action_key: "organization",
			state: StateFeature.getLoginContextCodeCompanyState,
			description: 'B2B Area'
		}
	];
}

export type ContextType = BodyTablePouchModel | OrganizationPouchModel | BodyTablePouchCustomModel;

@Injectable()
export class UtilGuardService {
	constructor(private store: Store<any>, private appConfig: CustomerAppConfig) {}
	checkUserContext(user: UserDetailModel, context: ContextApplicationItemCodeEnum) {
		let hasContext: boolean = false;
		let currentContext: ContextPermission;
		if (context) {
			if (user && user.context_application_list) {
				for (let i = 0; i < user.context_application_list.length; i++) {
					if (user.context_application_list[i].code === context) {
						hasContext = true;
						currentContext = user.context_application_list[i];
						break;
					}
				}
			}
		}
		return { hasContext, currentContext };
	}

	checkUserPermission(user: UserDetailModel, permission?: PermissionKeyEnum) {
		if (!permission) {
			const currentContext = PermissionTypeList.typeList.find(userPermission => {
				return userPermission.type === user.current_permission.context_application;
			});
			permission = currentContext.permission;
		}
		let hasPermission: boolean = false;
		if (permission) {
			if (user && user.current_permission && user.current_permission.permission) {
				for (let i = 0; i < user.current_permission.permission.length; i++) {
					if (user.current_permission.permission[i] === permission) {
						hasPermission = true;
						break;
					}
				}
			}
		}
		return hasPermission;
	}

	dispatchUserContext(permission: UserTypeContextModel, user: UserDetailModel, link?: string | number) {
		if (!link) {
			link = user.context_application_list[0].context_code_list[0].code;
		}
		if (permission.action) {
			const request: BasePouchModel = { _id: `${permission.action_key}${noSqlDocSeparator}${link.toString()}` };
			this.store.dispatch(permission.action(new BaseState(request)));
		} else {
			// TODO: error Label
			throw new Error('the permission action is not exist');
		}
	}

	retrieveContextPermissionList(): UserTypeContextModel[] {
		return PermissionTypeList.typeList.filter(permission => {
			return this.appConfig.config.permissionContext.includes(permission.type);
		});
	}

	retrieveContextPermission(key: ContextApplicationItemCodeEnum): UserTypeContextModel {
		return this.retrieveContextPermissionList().find(permission => {
			return permission.type === key;
		});
	}

	retrieveCurrentPermissionFromLinkCode(link_code: LinkCodeModel, user: UserDetailModel) {
		let current_link: ContextPermission;
		let permission: string[] = [];
		const current_context = this.checkUserContext(user, link_code.context_application);
		if (current_context.hasContext){
			current_link = current_context.currentContext.context_code_list.find((link: ContextPermission) => {
				return link.code === link_code.context_code;
			});
		}
		if (current_link){
			const setPermission = new Set(permission.concat(current_link.permission).concat(user.permission));
			permission = Array.from(setPermission);
		}
		return permission;
	}

	filterRouteWithPermission(routeList: Route[], permissionList: string[]){
		const cloneRouteList: Route[] = [];
		for (let index = 0; index < routeList.length; index++) {
			const element = routeList[index];
			const currentPermission = permissionList.find(permission => {
				return ((element.data && element.data['permissionKey']) ? element.data['permissionKey'] : []).includes(permission);
			});
			if (currentPermission){
				cloneRouteList.push(element);
				if (element.children && element.children.length > 0){
					cloneRouteList[cloneRouteList.length - 1].children = this.filterRouteWithPermission(element.children, permissionList);
				}
			}
		}
		return cloneRouteList;
	}
}
