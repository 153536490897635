import { ITdDataTableColumn } from '@covalent/core/data-table';
import { RequestCenterEnum } from '../../../../enum/request-center/request-center.enum';
import { RequestCenterColumnListPageDetailBase } from './page-detail-base.constant';

export namespace RequestCenterColumnListPageDetailAgency {
	export namespace advertisingMaterial {
		const columnNameList: string[] = [
			'index',
			'brand.description',
			'line.description',
			'note',
			'type',
			RequestCenterEnum.Asset.AttachmentType.AGENCY,
			RequestCenterEnum.Asset.AttachmentType.FRONTOFFICE
		];
		export const columList: ITdDataTableColumn[] = RequestCenterColumnListPageDetailBase.base.filter(col =>
			columnNameList.includes(col.name)
		);
	}

	export namespace printProductionFlow {
		const columnNameList: string[] = [
			'index',
			'brand.description',
			'component_code',
			'line.description',
			'note',
			'order_code',
			'product_code',
			'type',
			RequestCenterEnum.Asset.AttachmentType.AGENCY,
			RequestCenterEnum.Asset.AttachmentType.FRONTOFFICE
		];
		export const columList: ITdDataTableColumn[] = RequestCenterColumnListPageDetailBase.base.filter(col =>
			columnNameList.includes(col.name)
		);
	}
}
