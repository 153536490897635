import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialComponentsModule} from './modules/material-components.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import { NgScrollbarModule } from 'ngx-scrollbar';
import {LoadingOverlayComponent} from "../frame/loading-overlay/loading-overlay.component";
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentModule } from './modules/covalent.module';
import { NgApexchartsModule } from 'ng-apexcharts';
// service
import { AppAttachmentResolverService } from '../service/structure/app-attachment-resolver.service';
import { AttachmentResolverService, MediaReplayService, SaepICTAngularCoreModule } from '@saep-ict/angular-core';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  declarations: [
    LoadingOverlayComponent
  ],
  exports: [
    CommonModule,
    LoadingOverlayComponent,
    RouterModule,
    CovalentModule,
    MaterialComponentsModule,
    FlexLayoutModule,
		NgScrollbarModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    PipeModule,
    // MediaCenterModule
    SaepICTAngularCoreModule
  ],
  providers: [
    // TODO: rimuovere in favore di @saep-ict/angular-core
    AppAttachmentResolverService,
    AttachmentResolverService,
    MediaReplayService
  ]
})
export class SharedModule {
}
