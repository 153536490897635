import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// model
import {
	AbstractListModel,
	AbstractQueryListModel,
	PageSettingsModel
} from '../../../model/structure/AbstractListModel';
import { TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { BaseStateModel, BaseState } from '../../../model/state/base-state.model';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TicketStateAction, TicketActionEnum } from './ticket.actions';

// widget & utility
import { PouchDbAssetRequestCenterAdapter } from '../../../service/pouchdb/instance/asset-request-center/pouchdb-asset-request-center-adapter.service';
import { from, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AssetRequestUtilService } from '../../../service/util/asset-request-util.service';
import { AssetRequestService } from '../../../service/rest/asset-request.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';

@Injectable()
export class TicketEffects {
	// detail
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketActionEnum.LOAD),
			mergeMap((action: { id: string }) => from(this.getTicketDetail(action.id))),
			map(action => TicketStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(TicketStateAction.error());
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketActionEnum.SAVE),
			mergeMap((action: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>) => from(this.postTicket(action))),
			map(ticket => TicketStateAction.update(ticket)),
			catchError((error, caught) => {
				this.store.dispatch(TicketStateAction.error());
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>) => from(this.deleteTicket(action))),
			map(ticket => TicketStateAction.update(new BaseState(ticket))),
			catchError((error, caught) => {
				this.store.dispatch(TicketStateAction.error());
				return caught;
			})
		)
	);

	// list
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TicketActionEnum.LOAD_LIST),
			mergeMap(action =>
				from(this.loadTicketFilteredList(action)).pipe(
					map(ticketList => this.addMetaInformationToTicketList(ticketList)),
					map(ticketList => TicketStateAction.updateList(ticketList)),
					catchError(err => of({ err, type: TicketActionEnum.ERROR_LIST }))
				)
			)
		)
	);

	constructor(
		private store: Store,
		private actions$: Actions,
		private pouchDbAssetRequestAdapter: PouchDbAssetRequestCenterAdapter,
		private assetRequestUtilService: AssetRequestUtilService,
		private assetRequestService: AssetRequestService,
		private snackBar: MatSnackBar,
		public router: Router
	) {}

	// detail
	getTicketDetail(id: string) {
		return this.pouchDbAssetRequestAdapter.assetRequestCenterPouch.loadTicket(id).then(
			(res: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>) => {
				const actionReturn = <BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>>{
					data: res
				};
				return actionReturn;
			},
			err => {
				this.openSnackBar('Error:' + err.body.status, null, 3000);
				return err;
			}
		);
	}

	/**
	 * Controlli fatti backend:
	 * * user id esistente
	 * * company client code esistente
	 * * type valorizzato dinamicamente in base alla requestType
	 * * campo request esistente
	 * * campo asset list esistente
	 * * campo status esistente
	 */
	postTicket(action: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>): any {
		try {
			return this.assetRequestService
				.saveTicket(action.data)
				.then(res => {
					this.openSnackBar('Ticket updated');
					if (!action.data._id) {
						this.router.navigateByUrl(this.router.url.replace('/new/', `/${res._id}/`));
					}
					return new BaseState(res);
				})
				.catch(err => {
					console.log(err);
					this.openSnackBar('Error during ticket updating');
					throw new Error(err);
				});
		} catch(err) {
			throw new Error(err);
		}
	}

	/**
	 * Obbligatori _id e _rev
	 */
	deleteTicket(action: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>): any {
		try {
			return this.assetRequestService
			.deleteTicket(action.data)
			.then(res => {
				this.openSnackBar('Ticket deleted');

				const deletedTicket: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow> = {
					_id: 'deleted'
				};

				return deletedTicket;
			})
			.catch(err => {
				this.openSnackBar('Error during ticket deleting');
				throw new Error(err);
			});
		} catch(err) {
			throw new Error(err);
		}
	}

	// list
	loadTicketFilteredList(pageSettings: PageSettingsModel): Promise<AbstractListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>> {
		const pageSettingsRemap: PageSettingsModel = JSON.parse(JSON.stringify(pageSettings));

		pageSettingsRemap.filters.status_list = this.assetRequestUtilService.returnStatusValueNumberList(
			pageSettingsRemap.filters.status_list
		);

		return this.pouchDbAssetRequestAdapter.assetRequestCenterPouch
			.loadTicketFilteredList(pageSettingsRemap)
			.then(ticketList => {
				const ticketListState: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]> = {
					query: pageSettings,
					data: ticketList.docs
				};
				return ticketListState;
			});
	}

	// widget & utility
	addMetaInformationToTicketList(ticketListState: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>) {
		const ticketListStateWithMetaInfo: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]> = {
			query: ticketListState.query,
			data: []
		};

		ticketListState.data.forEach(ticket => {
			// Missing asset list property
			ticket.asset_list = ticket.asset_list || [];
			ticket.asset_list_brand = [];
			ticket.asset_list_diffusion_country = [];
			ticket.asset_list_diffusion_client = [];
			ticket.asset_list_line = [];
			ticket.asset_list_number = ticket.asset_list.length;
			ticket.asset_list_type = [];

			ticket.asset_list.forEach(asset => {
				if (asset.type && !ticket.asset_list_type.includes(asset.type.id)) {
					ticket.asset_list_type.push(asset.type.id);
				}
				// Brand
				if (
					asset.brand &&
					asset.brand.description &&
					!ticket.asset_list_brand.includes(asset.brand.description)
				) {
					ticket.asset_list_brand.push(asset.brand.description);
				}
				// Diffusion country
				if (
					(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion &&
					(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.country &&
					!ticket.asset_list_diffusion_country.includes(
						(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.country
					)
				) {
					ticket.asset_list_diffusion_country.push(
						(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.country
					);
				}
				// Diffusion client
				if (
					(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion &&
					(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.client &&
					!ticket.asset_list_diffusion_client.includes(
						(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.client
					)
				) {
					ticket.asset_list_diffusion_client.push(
						(asset as TicketModel.AssetItem.AdvertisingMaterial).diffusion.client
					);
				}
				// Line
				if (asset.line && asset.line.description && !ticket.asset_list_line.includes(asset.line.description)) {
					ticket.asset_list_line.push(asset.line.description);
				}
			});
			ticket.asset_list_type.sort();

			ticketListStateWithMetaInfo.data.push(ticket);
		});
		return ticketListStateWithMetaInfo;
	}

	openSnackBar(message: string, action = 'Ok', duration = 1000) {
		this.snackBar.open(message, action, { duration: duration });
	}
}
