import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromPageSettings from './page-settings.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('page-settings', fromPageSettings.reducer)
  ]
})
export class PageSettingsStateModule {}
