import { Injectable } from '@angular/core';

// model
import { AbstractListModel } from '../../model/structure/AbstractListModel';
import { ChatDetailStateFeatureSelectionParameterModel } from '../../model/pouch/chat.model';
import { ChatInstanceTypeEnum } from '../../enum/chat.enum';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatActionEnum, ChatStateAction } from './chat.actions';

// widget & utility
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChatFunctionService } from '../../service/util/chat.service';

@Injectable()
export class ChatEffects {

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatActionEnum.LOAD_LIST),
			mergeMap(() => from(this.chatFunctionService.loadList())),
			mergeMap((action: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>) => from(this.chatFunctionService.loadListMetaInfo(action))),
			map(chat => ChatStateAction.updateList(chat)),
			catchError(() => of({ type: ChatActionEnum.ERROR_LIST }))
		)
	);

	updateListItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatActionEnum.UPDATE_LIST_ITEM),
			mergeMap((action: ChatDetailStateFeatureSelectionParameterModel) => (this.chatFunctionService.updateListItem(action))),
			map((action: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>) => ChatStateAction.updateList(action)),
			catchError(() => of({ type: ChatActionEnum.ERROR_LIST }))
		)
	);

	closeConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatActionEnum.CLOSE_CONNECTION),
			map(() => {
				this.chatFunctionService.cancelListener(ChatInstanceTypeEnum.assistance_list);
				return ChatStateAction.resetList();
			}),
			catchError(() => of({ type: ChatActionEnum.ERROR_LIST }))
		)
	);

	constructor(
		private actions$: Actions,
		private chatFunctionService: ChatFunctionService
	) {

	}

}
