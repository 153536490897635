import { Injectable } from '@angular/core';

// widget & utility
import { OfflineDeviceService, PouchDbAdapter, TransmissionService } from '@saep-ict/pouch-db';
import { ChatCommonPouch } from '../../../pouch-common-function/chat-common.pouch';
import { CustomerAppConfig } from '../../../../../customer-app.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoaderService } from '../../../../structure/loader.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PouchDbChatAssetRequestAdapter extends PouchDbAdapter {
	baseDatabaseTemplate: 'chat-asset-request';
	database = 'chat-asset-request';
	chatCommonPouch: ChatCommonPouch;

	constructor(
		protected config: CustomerAppConfig,
		private loaderService: LoaderService,
		private deviceDetectorService: DeviceDetectorService,
		private httpClient: HttpClient,
		offlineDeviceService: OfflineDeviceService,
		private transmissionService: TransmissionService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.chatCommonPouch = new ChatCommonPouch(
			this.getDB(), this.httpClient, this.config, this.transmissionService, this.loaderService
		);
	}

}
