import { PipeTransform, Pipe } from '@angular/core';
import { PipeFilterOption } from '../../model/structure/pipe-filter.model';

@Pipe({
	name: 'filterByAlreadyInserted',
	pure: false
})
export class FilterByAlreadyInsertedPipe implements PipeTransform {
	transform(items: any[], filterOption: PipeFilterOption) {
		if (
			!items || !filterOption ||
			!filterOption.propertyName ||
			!filterOption.alreadyInsertedArray ||
			filterOption.alreadyInsertedArray.length === 0
		) {
            return items;
		}
		return items.filter(item => !filterOption.alreadyInsertedArray.includes(item[filterOption.propertyName]));
	}
}
