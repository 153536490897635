<breadcrumb-project-instance
	title="{{ requestConfiguration.name }}"
	subtitle="Home / {{ requestConfiguration.name }}"
	[backButton]="false"
></breadcrumb-project-instance>

<div app-list-wrapper [filterOpened]="false" #listWrapper>
	<!-- MAIN -->
	<ng-container main>
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					<button
						*ngIf="
							clientCode ||
							(requestType === requestCenterEnum.type.PRINT_PRODUCTION_FLOW &&
								userType === requestCenterEnum.User.type.EUROITALIA)
						"
						mat-raised-button
						color="accent"
						[routerLink]="[router.url, 'new']"
					>
						<mat-icon>add</mat-icon> New ticket
					</button>
					<span class="flex-span"></span>
					<td-search-box
						backIcon="search"
						[placeholder]="'Filter by Text'"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="true"
						[(ngModel)]="pageSettings.filters.text"
						(searchDebounce)="onFormSubmit()"
					>
					</td-search-box>
					<app-table-column-toggle
						[columns]="requestConfiguration.pageList.columnList[userType]"
						(emitter)="toggleColumn($event)"
					></app-table-column-toggle>
					<button mat-icon-button (click)="listWrapper.sidenav.open()">
						<mat-icon>filter_list</mat-icon>
					</button>
				</mat-toolbar-row>
			</mat-toolbar>

			<ng-container *ngIf="ticketList?.length; else noData">
				<td-data-table
					#dataTable
					[data]="ticketList"
					[columns]="requestConfiguration.pageList.columnList[userType]"
					[clickable]="true"
					[sortBy]="pageSettings.sort[0].name"
					[sortOrder]="pageSettings.sort[0].order"
					(sortChange)="sortChange($event)"
					(rowClick)="goToDetail($event)"
				>
					<ng-template tdDataTableTemplate="status" let-row="row" let-value="value">
						<div
							class="chips-label"
							[ngClass]="
								assetRequestUtilService.getLabelClassfromStatusListRemap(
									requestConfiguration.status.filterRemap,
									userType,
									row.status
								)
							"
						>
							{{
								utilService.formatPlaceholder(
									assetRequestUtilService.getLabelfromStatusListRemap(
										requestConfiguration.status.filterRemap,
										userType,
										row.status
									)
								) | titlecase
							}}
						</div>
					</ng-template>
					<ng-template tdDataTableTemplate="asset_list_type" let-value="value">
						<ng-container *ngFor="let assetType of value">
							<mat-icon
								[matTooltip]="
									utilService.formatPlaceholder(requestConfiguration.assetType[assetType]) | titlecase
								"
							>
								{{ requestCenterEnum.Asset.icon[requestConfiguration.assetType[assetType]] }}
							</mat-icon>
						</ng-container>
					</ng-template>
					<!-- Brand list -->
					<ng-template tdDataTableTemplate="asset_list_brand" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Line list -->
					<ng-template tdDataTableTemplate="asset_list_line" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Diffusion country list -->
					<ng-template tdDataTableTemplate="asset_list_diffusion_country" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Diffusion client list -->
					<ng-template tdDataTableTemplate="asset_list_diffusion_client" let-value="value">
						<span class="item-list" [matTooltip]="value">{{ value }}</span>
					</ng-template>
					<!-- Urgent -->
					<ng-template tdDataTableTemplate="request.urgent" let-value="value">
						<mat-icon *ngIf="value" class="icon__urgent">priority_high</mat-icon>
					</ng-template>
				</td-data-table>
			</ng-container>
			<ng-template #noData>
				<p class="placeholder">There are no tickets to display</p>
			</ng-template>

			<pouch-paging-bar
				[pagination]="pageSettings.pagination"
				(change)="paginationChange($event)"
				[data]="ticketList"
			></pouch-paging-bar>
		</mat-card>
	</ng-container>

	<!-- SIDEBAR -->
	<ng-container side>
		<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<mat-form-field>
							<mat-label>Status</mat-label>
							<mat-select [compareWith]="compareValueList" formControlName="status_list" multiple>
								<mat-option
									*ngFor="let item of requestConfiguration.status.filterRemap[userType]"
									[value]="item"
								>
									{{ utilService.formatPlaceholder(item.label) | titlecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-12">
						<mat-form-field>
							<mat-label>Type of assets</mat-label>
							<mat-select formControlName="asset_type">
								<mat-option
									*ngFor="let item of requestConfiguration.assetType | enumIterator"
									[value]="item.id"
								>
									{{ utilService.formatPlaceholder(item.description) | titlecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-12" *ngIf="requestConfiguration.pageDetail.headerField.urgent.visible[userType]">
						<mat-checkbox formControlName="urgent">Show only urgent ticket</mat-checkbox>
					</div>

					<div class="col-md-6">
						<!-- Date request -->
						<ng-container formGroupName="date_request">
							<mat-form-field>
								<mat-label>Request date</mat-label>
								<mat-date-range-input [rangePicker]="pickerDateRequest">
									<input
										matStartDate
										formControlName="start"
										placeholder="Start"
									/>
									<input
										matEndDate
										formControlName="end"
										placeholder="End"
									/>
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="pickerDateRequest"></mat-datepicker-toggle>
								<mat-date-range-picker #pickerDateRequest></mat-date-range-picker>
							</mat-form-field>
						</ng-container>
					</div>

					<div class="col-md-6">
						<!-- Date due -->
						<ng-container formGroupName="date_due">
							<mat-form-field>
								<mat-label>Due date</mat-label>
								<mat-date-range-input [rangePicker]="pickerDueDate">
									<input
										matStartDate
										formControlName="start"
										placeholder="Start"
									/>
									<input
										matEndDate
										formControlName="end"
										placeholder="End"
									/>
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="pickerDueDate"></mat-datepicker-toggle>
								<mat-date-range-picker #pickerDueDate></mat-date-range-picker>
							</mat-form-field>
						</ng-container>
					</div>

					<div class="col-md-12">
						<!-- Brand -->
						<mat-form-field>
							<mat-label>Brand</mat-label>
							<mat-select formControlName="brandList" multiple>
								<mat-option
									*ngFor="let brand of assetRequestUtilService.categoryList?.category_list"
									[value]="brand.code"
								>
									{{ brand.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div
						*ngIf="getformFieldValue('brandList') && getformFieldValue('brandList').length > 0"
						class="col-md-12"
					>
						<!-- Line list -->
						<mat-form-field>
							<mat-chip-list #chipList formControlName="lineList" aria-label="Line selection">
								<mat-chip
									*ngFor="let line of getformFieldValue('lineList'); let i = index"
									[selectable]="true"
									[removable]="true"
									(removed)="chipsAutocompleteLineRemoveItem(line)"
								>
									{{
										assetRequestUtilService.getLineDescription(getformFieldValue('brandList'), line)
									}}
									<mat-icon matChipRemove>cancel</mat-icon>
								</mat-chip>
								<input
									#chipsAutocompleteLineInput
									[matAutocomplete]="auto"
									[matChipInputFor]="chipList"
									placeholder="Line"
								/>
							</mat-chip-list>
							<mat-autocomplete
								#auto="matAutocomplete"
								(optionSelected)="chipsAutocompleteLineAddItem($event)"
							>
								<mat-option
									*ngFor="
										let line of chipsAutocompleteLineList
											| filterByAlreadyInserted
												: {
														propertyName: 'code',
														alreadyInsertedArray: getformFieldValue('lineList')
												  }
											| filterByString
												: {
														propertyName: 'description',
														value: chipsAutocompleteLineInput.value
												  }
									"
									[value]="line"
								>
									{{ line.description }}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
					</div>
				</div>
			</div>

			<button mat-flat-button color="primary" class="btn-sidebar mt-16" type="submit">Apply filters</button>
			<button mat-stroked-button color="primary" class="btn-sidebar" type="button" (click)="resetFilters()">
				Reset
			</button>
			<button *ngIf="requestType === requestCenterEnum.type.PRINT_PRODUCTION_FLOW || user.current_permission.context_application === 'BACKOFFICE'"
				mat-stroked-button
				color="primary"
				class="btn-sidebar"
				type="button"
				(click)="exportExcel()"
			>
			Export Excel
		</button>
		</form>
	</ng-container>
</div>
