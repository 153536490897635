import { Injectable } from "@angular/core";
import { SurveyService } from "../../rest/survey-center/survey.service";
import { CurrencyModel } from "../../../model/survey-center/survey.model";

@Injectable()
export class CurrenciesService {

    // CurrencyModel
    surveyCurrencies: CurrencyModel[] = [];

    constructor(private surveyService: SurveyService) { }

    getCurrencies() {
        this.surveyService.getCurrencies()
        .then(res => {
            this.surveyCurrencies = Array.from(res);
            this.surveyCurrencies.sort((a, b) => {
                if(a.isoCode < b.isoCode) { return -1; }
                if(a.isoCode > b.isoCode) { return 1; }
                return 0;
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
}