import { Injectable } from '@angular/core';

// model
import { CategoryListStateModel } from '../../model/category-list.model';
import { AssetRequestCategory } from '../../model/asset-request/asset-request-category-list.model';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';

// widget & utility
import { PouchDbAssetRequestCenterAdapter } from '../../service/pouchdb/instance/asset-request-center/pouchdb-asset-request-center-adapter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PouchUtilService } from '../../service/util/pouch-util.service';

@Injectable()
export class CategoryListEffects {
	loadBrandAndLine$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryListActionEnum.LOAD_BRAND_AND_LINE),
			mergeMap((action: CategoryListStateModel<AssetRequestCategory>) =>
				from(this.loadCategoryFilteredList(action))
			),
			mergeMap((action: CategoryListStateModel<AssetRequestCategory>) => from(this.loadLineFilteredList(action))),
			map(action => CategoryListAction.update(action)),
			catchError(() => of({ type: CategoryListActionEnum.ERROR }))
		)
	);

	loadLine$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryListActionEnum.LOAD_LINE),
			mergeMap((action: CategoryListStateModel<AssetRequestCategory>) => from(this.loadLineFilteredList(action))),
			map(action => CategoryListAction.update(action)),
			catchError(() => of({ type: CategoryListActionEnum.ERROR }))
		)
	);

	constructor(
		private actions$: Actions,
		private pouchDbAssetRequestAdapter: PouchDbAssetRequestCenterAdapter,
		private snackBar: MatSnackBar,
		private pouchUtilService: PouchUtilService
	) {}

	// async getAllCategory(
	// 	action: CategoryListStateModel<AssetRequestCategory>
	// ): Promise<CategoryListStateModel<AssetRequestCategory>> {
	// 	const allDocsLastCharacter = '\uFFF0';
	// 	const documentName = 'category';
	// 	const allDocsParam: any = {
	// 		include_docs: true,
	// 		startkey: documentName + '_',
	// 		endkey: documentName + '_' + allDocsLastCharacter
	// 	};

	// 	await this.pouchUtilService
	// 		.allDocs<AssetRequestCategory>(allDocsParam)
	// 		.then((result: any) => {
	// 			action.category_list = result.data;
	// 		})
	// 		.catch(err => {
	// 			console.log(err);
	// 		});

	// 	return action;
	// }

	loadCategoryFilteredList(
		action: CategoryListStateModel<AssetRequestCategory>
	): Promise<CategoryListStateModel<AssetRequestCategory>> {
		return this[action.adapter].categoryListCommonPouch
			.loadCategoryFilteredList(action)
			.then(res => {
				action.category_list = res.docs;
				return action;
			})
			.catch(err => {
				console.log(err);
				this.openSnackBar(`Error loading`);
				return err;
			});
	}

	loadLineFilteredList(action: CategoryListStateModel<AssetRequestCategory>): any {
		const promises = [];
		action.category_list.map(i => {
			const actionParameter: CategoryListStateModel<AssetRequestCategory> = {
				adapter: action.adapter,
				filter: {
					has_parents: [i._id],
					has_duration: action.filter && action.filter.has_duration ? action.filter.has_duration : null,
					type: action.filter.type
				}
			};
			promises.push(
				this.loadCategoryFilteredList(actionParameter).then(res => {
					if (res.category_list && res.category_list.length > 0) {
						i.category_list = res.category_list;
					}
					return i;
				})
			);
		});
		return Promise.all(promises).then(() => {
			return action;
		});
	}

	openSnackBar(message: string, action = 'Ok', duration = 1000) {
		this.snackBar.open(message, action, { duration: duration });
	}
}
