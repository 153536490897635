<ng-container *ngFor="let button of configuration">
    <button
        *ngIf="button.visible()"
        mat-raised-button
        [ngClass]="button.buttonClass"
        [disabled]="button.disabled ? button.disabled() : false"
        (click)="onButtonClick.emit(button)"
        [innerHTML]="button.label"
    >
    </button>
</ng-container>