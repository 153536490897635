import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TicketStateAction, TicketActionEnum } from './ticket.actions';

// model
import { TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { AbstractQueryListModel } from '../../../model/structure/AbstractListModel';
import { BaseStateModel } from '../../../model/state/base-state.model';

export namespace TicketStateReducer {
	// detail
	export const initialState: BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>> = null;

	const _reducer = createReducer(
		initialState,
		on(TicketStateAction.update, (state, ticket) => JSON.parse(JSON.stringify(ticket))),
		on(TicketStateAction.load, state => state),
		on(TicketStateAction.save, state => state),
		on(TicketStateAction.reset, state => initialState),
		on(TicketStateAction.remove, state => state),
		on(TicketStateAction.error, (state) => ({
			data: state ? state.data : null,
			type: TicketActionEnum.ERROR
		}))
	);

	export function reducer(state, action) {
		return _reducer(state, action);
	}

	// list
	export const initialListState: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]> = null;

	const _reducerList = createReducer(
		initialListState,
		on(TicketStateAction.updateList, (state, ticketList) => ticketList),
		on(TicketStateAction.loadList, state => state),
		on(TicketStateAction.resetList, state => initialListState)
	);

	export function reducerList(
		state: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>,
		action: TypedAction<TicketActionEnum>
	) {
		return _reducerList(state, action);
	}
}
