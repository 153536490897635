import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ChatStateAction, ChatActionEnum } from './chat.actions';
// model
import { AbstractListModel } from '../../model/structure/AbstractListModel';
import { ChatDetailStateFeatureSelectionParameterModel } from '../../model/pouch/chat.model';


export namespace ChatStateReducer {

	export const initialState: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]> = null;

	const _reducerList = createReducer(
		JSON.parse(JSON.stringify(initialState)),
		on(ChatStateAction.updateList, (state, chatList) => chatList),
		on(ChatStateAction.loadList, state => state),
		on(ChatStateAction.updateListItem, state => state),
		on(ChatStateAction.resetList, state => null)
	);

	export function reducerList(
		state: AbstractListModel<ChatDetailStateFeatureSelectionParameterModel[]>,
		action: TypedAction<ChatActionEnum>
	) {
		return _reducerList(state, action);
	}
}
