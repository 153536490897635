import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ChatPdfStateReducer } from './chat-pdf.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('chat-pdf', ChatPdfStateReducer.reducer)
	]
})
export class ChatPdfStateModule {}
