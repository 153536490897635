import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableRowClickEvent, ITdDataTableSortChangeEvent, TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { requestCenterConfiguration } from '../../../constant/request-center/request-center-configuration.constant';
import { AssetRequestModel } from '../../../model/asset-request/request-center.model';
import { TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { AbstractQueryListModel, PageSettingsModel, Pagination } from '../../../model/structure/AbstractListModel';
import { RequestCenterDashboardColumnService } from '../../../service/td-data-table/implementation/asset-request/request-center.service';
import { AssetRequestUtilService } from '../../../service/util/asset-request-util.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { UtilService } from '../../../service/util/util.service';
import { TicketStateAction } from '../../../state/asset-request/ticket/ticket.actions';
import { StateFeature } from '../../../state/index';
import * as fromPageSettingsState from '../../../state/page-settings/page-settings.actions';
import { DialogRequestCenterAdvertisingMaterial } from '../widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';

@Component({
	selector: 'asset-request-dashboard',
	templateUrl: './asset-request-dashboard.component.html',
	styleUrls: ['./asset-request-dashboard.component.scss'],
	providers: [SubscribeManagerService]
})
export class AssetRequestDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	platformType: 'frontoffice' | 'backoffice' = this.route.snapshot.data['platform'];
	userType = this.route.snapshot.paramMap.get('userType');
	requestType = this.route.snapshot.paramMap.get('requestType');
	requestConfiguration: AssetRequestModel.Configuration<
		DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
	> = requestCenterConfiguration.find(i => i.type === this.requestType);

	pageSettingsState$: Observable<PageSettingsModel> = this.store.select(StateFeature.getPageSettingsState);
	pageSettings: PageSettingsModel = {
		pageName: `${this.requestConfiguration.type}_${this.requestConfiguration.actionKey}`,
		pagination: {
			page_current: 1,
			page_size: 10
		},
		filters: {
			company: null,
			text: null,
			// TODO: l'auth tramite query param da problemi alla gestione dei paramtri quali userType
			// quick fix: forzato set di filtri euroitalia (unico tipo a vedere la dashboard)
			// status_list: this.assetRequestUtilService.statusListDashboard[this.userType],
			status_list: this.assetRequestUtilService.statusListDashboard['euroitalia'],
			asset_type: null,
			urgent: null,
			date_request: null,
			date_due: null,
			brandList: null,
			lineList: null,
			types: [this.requestConfiguration.actionKey]
		},
		sort: [
			{
				name: 'request.date_due',
				order: 'desc'
			}
		]
	};

	ticketList: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[];
	ticketListState$: Observable<AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>> = this.store.select(
		StateFeature.getTicketListState
	);

	requestCenterEnum = RequestCenterEnum;

	constructor(
		private route: ActivatedRoute,
		private store: Store<any>,
		public assetRequestUtilService: AssetRequestUtilService,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		public utilService: UtilService,
		public requestCenterDashboardColumnService: RequestCenterDashboardColumnService
	) {
		this.subscribeManagerService.populate(this.subscribePageSettings().subscribe(), 'page-settings');
		this.subscribeManagerService.populate(this.subscribeTicketList().subscribe(), 'request-center');
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribePageSettings(): Observable<PageSettingsModel> {
		return this.pageSettingsState$.pipe(
			take(1),
			mergeMap((pageSettings: PageSettingsModel) => {
				if (pageSettings && pageSettings.pageName === this.pageSettings.pageName) {
					this.pageSettings = pageSettings;
				} else {
					this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
				}
				this.loadTicketFilteredList();
				return this.pageSettingsState$;
			})
		);
	}

	subscribeTicketList() {
		return this.ticketListState$.pipe(
			skipWhile((ticketListState: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>) => !ticketListState),
			map((ticketListState: AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>) => {
				this.ticketList = ticketListState.data;
			})
		);
	}

	// functions
	loadTicketFilteredList() {
		this.store.dispatch(TicketStateAction.loadList(this.pageSettings));
	}

	// table
	sortChange(e: ITdDataTableSortChangeEvent) {
		this.pageSettings.sort = [
			{
				name: e.name,
				order: e.order.toLowerCase()
			}
		];
		this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
		this.loadTicketFilteredList();
	}

	paginationChange(pagination: Pagination) {
		this.pageSettings.pagination = pagination;
		this.store.dispatch(fromPageSettingsState.update(this.pageSettings));
		this.loadTicketFilteredList();
	}

	goToDetail(e: ITdDataTableRowClickEvent) {
		const url = [
			this.platformType,
			'request-center',
			RequestCenterEnum.type.IMAGE_VIDEO,
			'euroitalia',
			e.row._id
		];
		this.router.navigate(url);
	}
}
