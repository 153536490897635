import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ChatStateReducer } from './chat.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('chat', ChatStateReducer.reducerList)
	]
})
export class ChatStateModule {}
