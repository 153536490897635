import { createAction, props } from '@ngrx/store';
import { BackendProcessQueueModel } from '../../../model/asset-request/backend-queue-process.model';
import { PageSettingsModel } from '../../../model/structure/AbstractListModel';


export enum BackendProcessQueueActionEnum {
	// detail
	UPDATE = '[Backend Process Queue] Update',
	LOAD = '[Backend Process Queue] Load',
	RESET = '[Backend Process Queue] Reset',
	SAVE = '[Backend Process Queue] Save',
	REMOVE = '[Backend Process Queue] Delete',
	ERROR = '[Backend Process Queue] Error'
}

export namespace BackendProcessQueueAction {
	// detail
	export const update = createAction(BackendProcessQueueActionEnum.UPDATE, props<BackendProcessQueueModel>());
	export const load = createAction(BackendProcessQueueActionEnum.LOAD, props<PageSettingsModel>());
	export const save = createAction(BackendProcessQueueActionEnum.SAVE, props<BackendProcessQueueModel>());
	export const remove = createAction(BackendProcessQueueActionEnum.REMOVE, props<BackendProcessQueueModel>());
	export const reset = createAction(BackendProcessQueueActionEnum.RESET);
}
