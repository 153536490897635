import { createAction, props } from '@ngrx/store';
// model
import { ChatDetailStateFeatureSelectionParameterModel } from '../../../model/pouch/chat.model';

export enum ChatPdfActionEnum {

	LOAD_CHAT_PDF = '[Chat Pdf] Load Chat Pdf',
	UPDATE = '[Chat Pdf] Update Chat Pdf',
	ERROR = '[Chat Pdf] Error',
}

export namespace ChatPdfStateAction {
	export const loadChatPdf = createAction(
		ChatPdfActionEnum.LOAD_CHAT_PDF,
		props<{roomName: string}>()
	);
	export const update = createAction(
		ChatPdfActionEnum.UPDATE,
		props<ChatDetailStateFeatureSelectionParameterModel>()
	);
}
