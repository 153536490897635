import { Injectable } from "@angular/core";
import { IResourceMethodPromiseStrict, ResourceAction, ResourceRequestMethod } from "@ngx-resource/core";
import { LoaderService } from "../structure/loader.service";
import { BaseApiService } from "../rest/baseApi.service";
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-webstorage";
import { CustomerAppConfig } from "../../customer-app.config";

@Injectable()
export class ChatPdfExportService extends BaseApiService  {

  constructor(
        public loaderService: LoaderService,
		public router: Router,
		public localStorageService: LocalStorageService,
		public appConfig: CustomerAppConfig
  ) {
    super(loaderService, router, localStorageService, appConfig);
	this.appConfig.config$.subscribe(config => {
		// setto il prefisso per la chiamata a rocket chat service per la stampa pdf. Oggetto definito in environment
		if (config && config.chat) {
		  this.$setPathPrefix(config.chat.pdf);
		}
	  })	
  }

    @ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/messages/list-messages/{!room_name}`
	})
	getChatPdf: IResourceMethodPromiseStrict<{room_name: string}, any, void, any>;
}