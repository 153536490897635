import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// model
import { CurrencyModel, BrandItemModel, BrandApprovationStatus } from '../../../../../model/survey-center/survey.model';
// widget
import { UtilService } from '../../../../../service/util/util.service';
import { ThousandPipe } from '../../../../../shared/pipes/thousand-separator.pipe';
import { CheckerListColumnService } from "../../../../../service/td-data-table/implementation/survey-center/survey-checker-list-column.service";
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'survey-checker',
  templateUrl: './survey-checker.component.html',
  styleUrls: ['./survey-checker.component.scss']
})
export class SurveyCheckerComponent implements OnInit {

  BrandApprovationStatus = BrandApprovationStatus;

  private _currency: CurrencyModel = new CurrencyModel();
  @Input() set currency(c: CurrencyModel) {
    if (c) {
      this._currency = c;
    }
  }

  private _brand: BrandItemModel = new BrandItemModel();
  @Input() set brand(b: BrandItemModel) {
    if (b && Object.keys(b).length) {
      this._brand = b;
      this.createGrid();
    }
  }

  @Input() enableValidation: boolean;

  rows = [];

  @Output() onSaveSurveyValidation = new EventEmitter();
  @Output() onDownloadLatest = new EventEmitter<string>();

  constructor(
    private thousandPipe: ThousandPipe,
    public utilService: UtilService,
    public checkerListService: CheckerListColumnService,
    public sanitization: DomSanitizer
  ) { }

  ngOnInit() {
  }

  createGrid() {
    this.rows = [];
    const surveyClone = _.cloneDeep(this._brand.survey_form);
    for (const field of surveyClone) {
      const row = field;
      if (field.type === 'number') {
        const value = row.value;
        row['value'] = this.thousandPipe.transform(value);
        row['value_eur'] = this.utilService.getEurConvertedValue(value, +this._currency.eurRate);
      }
      this.rows.push(row);
    }
  }

  onValidate(status: BrandApprovationStatus) {
    const brand: BrandItemModel = JSON.parse(JSON.stringify(this._brand));
    brand.status = status;
    this.onSaveSurveyValidation.emit(brand);
  }

}
