import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
// model
import { RemoteChangeActionEnum } from './remote-change.actions';
import { RemoteChangeStateAction } from './remote-change.actions';
import { RemoteChangeState } from '../../model/remote-change.model';

export namespace RemoteChangeStateReducer {
	export const initialState: RemoteChangeState = {
		chatDetailState: null
	};

	const _reducer = createReducer(
		JSON.parse(JSON.stringify(initialState)),
		on(RemoteChangeStateAction.update, (state, param) => param),
		on(RemoteChangeStateAction.reset, state => JSON.parse(JSON.stringify(initialState)))
	);

	export function reducer(state: RemoteChangeState, action: TypedAction<RemoteChangeActionEnum>) {
		return _reducer(state, action);
	}
}
