import { DatePipe } from '@angular/common';
import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Injectable } from "@angular/core";

@Injectable()
export class SurveyListColumnService extends BaseTableListService {

	constructor(private datePipe: DatePipe) {
		super();
	}

	_columns: ITdDataTableColumnCustom[] = [
		{
			name: "survey_type",
			label: "Survey Type",
			format: (v: string) => v.toUpperCase(),
			sortable: true,
		},
		{
			name: "client_code",
			label: "Client Code",
			width: 100,
			sortable: true
		},
		{
			name: "business_name",
			label: "Business Name",
			sortable: true
		},
		{
			name: "commercial_area.descrizioneBreve",
			label: "Area",
			sortable: true
		},
		{
			name: "brand_list",
			label: "Completion"
		},
		{
			name: "date_start",
			label: "Start Date",
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			width: 100,
			sortable: true
		},
		{
			name: "date_end",
			label: "End Date",
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			width: 100,
			sortable: true
		},
	];
}
