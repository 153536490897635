import {Injectable} from "@angular/core";
import {BaseApiService} from "../baseApi.service";
import {IResourceMethod, ResourceAction, ResourceRequestMethod} from "@ngx-resource/core";
// model
import {
  CurrencyModel, SubmitSurveyModel,
  SendNotificationModel, SubmitCurrencyModel } from "../../../model/survey-center/survey.model";
import { LoaderService } from '../../structure/loader.service';
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-webstorage";
import { CustomerAppConfig } from "../../../customer-app.config";

@Injectable()
export class SurveyService extends BaseApiService {

  constructor(
    public loaderService: LoaderService,
		public router: Router,
		public localStorageService: LocalStorageService,
		public appConfig: CustomerAppConfig
  ) {
    super(loaderService, router, localStorageService, appConfig);
    this.appConfig.config$.subscribe(config => {
			if (config && config.surveyCenter) {
        this.$setPathPrefix(config.surveyCenter.be_url);
			}
		});
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/survey-center/currencies'
  })
  getCurrencies: IResourceMethod<void, CurrencyModel[]>;

  // TODO: sostituire con POST su couch, change feed cambio valori excel
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/survey-center/submit'
  })
  submitSurvey: IResourceMethod<SubmitSurveyModel, void>;

  // TODO: sostituire con POST su couch, change feed cambio currency su excel, altro?
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/survey-center/submit-currency'
  })
  submitCurrency: IResourceMethod<SubmitCurrencyModel, void>;

  // TODO: deprecare in favore di change feed?
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/survey-center/send-notification'
  })
  sendNotification: IResourceMethod<SendNotificationModel, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!env}/copia-commissione/all-linee'
  })
  getBrandList: IResourceMethod<{env: string}, any[]>;

}
