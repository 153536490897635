// model
import { BaseStateModel } from '../../../../../model/state/base-state.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';

// store
import { createAction, props } from '@ngrx/store';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

export enum LoginContextCodeCompanyActionEnum {
    UPDATE = '[login-context-code-company] Update',
    LOAD = '[login-context-code-company] Load',
    RESET = '[login-context-code-company] Reset',
    ERROR = '[login-context-code-company] Error'
}

export namespace LoginContextCodeCompanyStateAction {
    export const load = createAction(
        LoginContextCodeCompanyActionEnum.LOAD,
        props<BaseStateModel<BasePouchModel>>()
    );
    export const update = createAction(
        LoginContextCodeCompanyActionEnum.UPDATE,
        props<BaseStateModel<OrganizationPouchModel>>()
    );
    export const reset = createAction(
        LoginContextCodeCompanyActionEnum.RESET
    );
    export const error = createAction(
        LoginContextCodeCompanyActionEnum.ERROR,
        props<BaseStateModel<null>>()
    );
}
