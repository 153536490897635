import { createAction, props } from '@ngrx/store';

// model
import { AbstractQueryListModel, PageSettingsModel } from '../../../model/structure/AbstractListModel';
import { TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { BaseStateModel } from '../../../model/state/base-state.model';

export enum TicketActionEnum {
    // detail
    UPDATE  = '[Ticket] Update',
    LOAD    = '[Ticket] Load',
    RESET   = '[Ticket] Reset',
    SAVE    = '[Ticket] Save',
    REMOVE  = '[Ticket] Delete',
    ERROR   = '[Ticket] Error',

    // list
    UPDATE_LIST = '[Ticket] Update List',
    LOAD_LIST   = '[Ticket] Load List',
    RESET_LIST  = '[Ticket] Reset List',
    ERROR_LIST  = '[Ticket] Error List'
}

export namespace TicketStateAction {
    // detail
    export const update = createAction(TicketActionEnum.UPDATE, props<BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>>());
    export const load = createAction(TicketActionEnum.LOAD, props<{id: string}>());
    export const reset = createAction(TicketActionEnum.RESET);
    export const error = createAction(TicketActionEnum.ERROR);
    export const save = createAction(TicketActionEnum.SAVE, props<BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>>());
    export const remove = createAction(TicketActionEnum.REMOVE, props<BaseStateModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>>>());
    // ...

    // list
    export const updateList = createAction(TicketActionEnum.UPDATE_LIST, props<AbstractQueryListModel<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]>>());
    export const loadList   = createAction(TicketActionEnum.LOAD_LIST, props<PageSettingsModel>());
    export const resetList  = createAction(TicketActionEnum.RESET_LIST);
}


// eventualmente da normalizzare insieme alle list actions
// export const updateItem     = createAction('[Ticket] Update Item', props<BaseStateModel<TicketStateModel>>());
// export const loadAssetList  = createAction('[Ticket] Load Asset List', props<BaseStateModel<TicketStateModel>>());
