<drop-area-file-project-instance
	[multiple]="multiple"
	[acceptedType]="acceptedType"
	[uploadButtonLabel]="uploadButtonLabel"
	(filesEmitter)="changeFiles($event)"
>
</drop-area-file-project-instance>
<ng-container *ngIf="files.length > 0">
	<div *ngFor="let row of files; let i = index" class="row file-selected-container">
		<div *ngIf="row.preview" class="col-md-3">
			<div [style.background-image]="'url(' + row.preview + ')'" class="img-placeholder"></div>
		</div>
		<div class="col-md-{{ row.preview ? '7' : '10' }}" [matTooltip]="row.name">
			{{ row.name }}
		</div>
		<div class="delete-button col-md-2">
			<button mat-icon-button color="warn" (click)="deleteFile(i)">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>
</ng-container>
