<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<div class="col-md-12">
			<h1 class="mb-0" mat-dialog-title>{{ data.asset ? 'Modify Asset' : 'Add new Asset' }}</h1>
		</div>
	</div>
	<ng-scrollbar
		[autoWidthDisabled]="true"
		[autoHeightDisabled]="false"
		[style.max-height.px]="mediaReplayService.deviceH - 250"
	>
		<!-- <mat-dialog-content> -->
		<div class="container">
			<div class="row">
				<!-- Common features -->
				<div class="col-12">
					<h2 class="subtitle"><mat-icon>fullscreen</mat-icon> Common features</h2>
				</div>
				<!-- Asset Type -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Asset type</mat-label>
						<mat-select formControlName="type" [compareWith]="compareItemId">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('type').value"
							>
								{{
									utilService.formatPlaceholder(form.get('type').value.description)
										| titlecase
								}}
							</mat-option>
							<mat-option
								*ngFor="
									let item of requestCenterEnum.Asset.Type.PrintProductionFlow.Code
										| enumIterator
								"
								[value]="item"
							>
								{{ utilService.formatPlaceholder(item.description) | titlecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<!-- Brand -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Brand</mat-label>
						<mat-select formControlName="brand" [compareWith]="compareItemCode">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('brand').value"
							>
								{{ form.get('brand').value.description }}
							</mat-option>
							<mat-option
								*ngFor="let brand of assetRequestUtilService.categoryList?.category_list"
								[value]="{ code: brand.code, description: brand.description }"
							>
								{{ brand.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<!-- Line -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Line</mat-label>
						<mat-select formControlName="line" [compareWith]="compareItemCode">
							<mat-option
								*ngIf="
									!(
										ticketStatus === ticketStatusEnum.DRAFT ||
										ticketStatus === ticketStatusEnum.MORE_INFO_NEEDED
									)
								"
								[value]="form.get('brand').value"
							>
								{{ form.get('line').value?.description }}
							</mat-option>

							<mat-option
								*ngFor="let line of lineList"
								[value]="{ code: line.code, description: line.description }"
							>
								{{ line.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<!-- Other features -->
				<div class="col-12">
					<h2 class="subtitle"><mat-icon>fullscreen_exit</mat-icon> Other features</h2>
				</div>
				<!-- Product code -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Product code</mat-label>
						<input matInput formControlName="product_code" />
					</mat-form-field>
				</div>
				<!-- Component code -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Component code</mat-label>
						<input matInput formControlName="component_code" />
					</mat-form-field>
				</div>
				<!-- PO number -->
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>PO number</mat-label>
						<input matInput formControlName="order_code" />
					</mat-form-field>
				</div>
			</div>
			<!-- note -->
			<ng-container
				*ngIf="
					ticketStatus == ticketStatusEnum.DRAFT ||
					ticketStatus == ticketStatusEnum.MORE_INFO_NEEDED ||
					asset.note
				"
			>
				<div class="row">
					<div class="col-md-12">
						<h2 class="subtitle mb-0"><mat-icon>comment</mat-icon> Comment</h2>
						<mat-form-field class="w-100">
							<mat-label>Leave a comment</mat-label>
							<textarea matInput formControlName="note" rows="2" cols="40"></textarea>
						</mat-form-field>
					</div>
				</div>
			</ng-container>
			<div class="row">
				<div class="col-md-6">
					<!-- Section: upload_euroitalia -->
					<h2 class="subtitle mb-0"><mat-icon>attach_file</mat-icon> Examples</h2>
					<ng-container
						*ngTemplateOutlet="
							attachmentList;
							context: {
								configuration: {
									key: requestCenterEnum.Asset.AttachmentType.EUROITALIA,
									attachmentListManager: attachmentListManagerConfiguration[
										requestCenterEnum.Asset.AttachmentType.EUROITALIA
									]
								}
							}
						"
					>
					</ng-container>
				</div>
				<div class="col-md-6">
					<ng-container
						*ngIf="
							ticketStatus === ticketStatusEnum.COMPLETED_AGENCY || 
							ticketStatus === ticketStatusEnum.COMPLETED_EUROITALIA ||
							(userType === requestCenterEnum.User.type.EUROITALIA &&
								ticketStatus === ticketStatusEnum.PENDING_EUROITALIA) ||
							(userType === requestCenterEnum.User.type.AGENCY &&
								ticketStatus === ticketStatusEnum.PENDING_AGENCY)
						"
					>
						<h2 class="mb-0 mt-8 subtitle"><mat-icon>check_circle</mat-icon> Assets</h2>
						<ng-container
							*ngTemplateOutlet="
								attachmentList;
								context: {
									configuration: {
										key: requestCenterEnum.Asset.AttachmentType.AGENCY,
										attachmentListManager: attachmentListManagerConfiguration[
											requestCenterEnum.Asset.AttachmentType.AGENCY
										]
									}
								}
							"
						>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-scrollbar>
		<mat-dialog-actions align="end">
			<button
				mat-stroked-button
				*ngIf="!hideButton"
				type="button"
				color="primary"
				(click)="onCancelHandlaer()"
			>
				CANCEL
			</button>
			<button
				mat-raised-button
				type="submit"
				color="primary"
				*ngIf="
					((ticketStatus == ticketStatusEnum.DRAFT || ticketStatus == ticketStatusEnum.MORE_INFO_NEEDED) &&
						userType === requestCenterEnum.User.type.EUROITALIA)
				"
				[disabled]="!form.valid"
			>
				SAVE
			</button>
			<button
				mat-raised-button
				*ngIf="
					(ticketStatus == ticketStatusEnum.DRAFT || ticketStatus === this.ticketStatusEnum.MORE_INFO_NEEDED) &&
					userType === requestCenterEnum.User.type.EUROITALIA &&
					(assetIndex || assetIndex === 0)
				"
				(click)="onDeleteHandlaer()"
				type="button"
				class="REJECTED"
			>
				<mat-icon>delete</mat-icon>
			</button>
			<!-- bottone fittizio per chiudere la dialog dopo aver allegato degli assets - richiesto dal cliente -->
			<button 
				mat-raised-button
				*ngIf="(userType == requestCenterEnum.User.type.AGENCY && ticketStatus == ticketStatusEnum.PENDING_AGENCY) || 
							(userType == requestCenterEnum.User.type.EUROITALIA && ticketStatus == ticketStatusEnum.PENDING_EUROITALIA)"
				type="button"
				color="primary"
				(click)="closeDialog()"
			>
			Save 
			</button>
		</mat-dialog-actions>
</form>
<!-- ng-template -->
<ng-template #attachmentList let-configuration="configuration">
	<attachment-list-manager
		[configuration]="configuration.attachmentListManager"
		(attachmentListchange)="attachmentListManagerChange($event, configuration.key)"
	>
	</attachment-list-manager>
</ng-template>
