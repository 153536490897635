import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { TicketModel, TicketPouchModel, TicketStatusRemapItemModel } from '../../../model/asset-request/ticket.model';
import { ButtonItemModel } from '../../../model/button-list.model';

export namespace RequestCenterStatusMapEuroitalia {
	export namespace FilterRemap {
		export const base: TicketStatusRemapItemModel[] = [
			{
				value: [RequestCenterEnum.Status.base.MORE_INFO_NEEDED],
				label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.MORE_INFO_NEEDED]
			},
			{
				value: [RequestCenterEnum.Status.base.PENDING_EUROITALIA],
				label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_EUROITALIA]
			},
			{
				value: [RequestCenterEnum.Status.base.PENDING_AGENCY],
				label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_AGENCY]
			}
		];
		export namespace RequestType {
			export const advertisingMaterial: TicketStatusRemapItemModel[] = RequestCenterStatusMapEuroitalia.FilterRemap.base.concat(
				[
					{
						value: [RequestCenterEnum.Status.base.SENT_TO_CLIENT],
						label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.SENT_TO_CLIENT]
					},
					{
						value: [RequestCenterEnum.Status.base.COMPLETED_AGENCY],
						label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.COMPLETED_AGENCY]
					}
				]
			);
			export const printProductionFlow: TicketStatusRemapItemModel[] = RequestCenterStatusMapEuroitalia.FilterRemap.base.concat(
				{
					value: [RequestCenterEnum.Status.base.DRAFT],
					label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.DRAFT]
				},
				{
					value: [RequestCenterEnum.Status.base.COMPLETED_EUROITALIA],
					label: 'closed'
				},
				{
					value: [RequestCenterEnum.Status.base.COMPLETED_AGENCY],
					label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.COMPLETED_AGENCY]
				}
			);
		}
	}
	export namespace StatusChangeButtonList {
		export namespace RequestType {
			export const advertisingMaterial = (
				ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>
			): ButtonItemModel[] => {
				return [
					{
						value: RequestCenterEnum.Status.base.PENDING_AGENCY,
						label: 'Send to Agency',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.PENDING_AGENCY,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_AGENCY]
					},
					{
						value: RequestCenterEnum.Status.base.PENDING_EUROITALIA,
						label: 'Revert to <i>Pending Euroitalia</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.PENDING_EUROITALIA,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_EUROITALIA]
					},
					{
						value: RequestCenterEnum.Status.base.SENT_TO_CLIENT,
						label: 'Send to Client as <i>Complete</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.SENT_TO_CLIENT,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.SENT_TO_CLIENT]
					},
					{
						value: RequestCenterEnum.Status.base.MORE_INFO_NEEDED,
						label: 'Bounce to Client as <i>More Info Needed</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.MORE_INFO_NEEDED,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.MORE_INFO_NEEDED]
					},
					{
						value: RequestCenterEnum.Status.base.DELETED,
						label: 'Delete',
						visible: () => true,
						buttonClass: 'REJECTED'
					}
				];
			};
			export const printProductionFlow = (
				ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>,
				formValidity: boolean
			): ButtonItemModel[] => {
				return [
					{
						value: RequestCenterEnum.Status.base.PENDING_AGENCY,
						label: 'Send to Agency',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.PENDING_AGENCY,
						disabled: () => !(ticket.asset_list && ticket.asset_list.length > 0 && formValidity),
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_AGENCY]
					},
					{
						value: RequestCenterEnum.Status.base.PENDING_EUROITALIA,
						label: 'Revert to <i>Pending Euroitalia</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.PENDING_EUROITALIA,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_EUROITALIA]
					},
					{
						value: RequestCenterEnum.Status.base.COMPLETED_AGENCY,
						label: 'Mark as <i>Completed</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.COMPLETED_AGENCY,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.COMPLETED_AGENCY]
					},
					{
						value: RequestCenterEnum.Status.base.COMPLETED_EUROITALIA,
						label: 'Mark as <i>Closed</i>',
						visible: () => ticket.status !== RequestCenterEnum.Status.base.COMPLETED_EUROITALIA,
						buttonClass: 'closed'
					},
					{
						value: RequestCenterEnum.Status.base.DELETED,
						label: 'Delete',
						visible: () => true,
						buttonClass: 'REJECTED'
					}
				];
			};
		}
	}
}
