import { createReducer, on } from '@ngrx/store';
import * as fromPageSettings from './page-settings.actions';
// model
import { PageSettingsModel } from '../../model/structure/AbstractListModel';

export const initialState: PageSettingsModel = {
  pageName: ''
};

const _reducer = createReducer(initialState,
  on(fromPageSettings.update, (state, pageSettings) => pageSettings),
  on(fromPageSettings.reset, (state) => initialState),
);

export function reducer(state, action) {
  return _reducer(state, action);
}
