import { TicketModel } from '../../../model/asset-request/ticket.model';
import { TicketStateModel } from '../../../model/asset-request/ticket.model';
import { WorkBook, WorkSheet, writeFile, utils } from 'xlsx-js-style';
import moment from 'moment';
import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { AssetRequestModel } from '../../../model/asset-request/request-center.model';
import { DialogRequestCenterAdvertisingMaterial } from '../../../page/asset-request/widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../../../page/asset-request/widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { AssetRequestUtilService } from '../../../service/util/asset-request-util.service';

export namespace ExportExcelConstants {
	export interface ExportConfig {
		userType: string;
		requestType: string;
		requestConfiguration: AssetRequestModel.Configuration<
			DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
		>;
		assetRequestUtilService: AssetRequestUtilService;
	}

	export const exportExcel = (
        list: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[],
        config: ExportConfig
    ) => {
        let export_excel: Function;
        if (config.requestType === RequestCenterEnum.type.PRINT_PRODUCTION_FLOW) {
            export_excel = exportExcel_printProductionFlow;
        }
        if (config.requestType === RequestCenterEnum.type.IMAGE_VIDEO) {
            export_excel = exportExcel_imageVideo;
        }
        export_excel(list, config);
    };

	const exportExcel_printProductionFlow = (
		list: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[],
		config: ExportConfig
	) => {
		let wb: WorkBook = utils.book_new();
		let heading = [
			[
				'id',
				'status',
				'subject',
				'owner',
				'request date',
				'due date',
				'completed date',
				'urgent',
				'asset type',
				'brand',
				'line',
				'product code',
				'component code',
				'po number'
			]
		];
		let json = [];
		list.forEach(t => {
			let status = config.assetRequestUtilService.getLabelClassfromStatusListRemap(
				config.requestConfiguration.status.filterRemap,
				config.userType,
				t.status
			);
			if (t.asset_list) {
				t.asset_list.forEach(a => {
					let asset_type =
						RequestCenterEnum.Asset.Type.PrintProductionFlow.ShortDescription[a.type.description];

					json.push({
						id: t._id.substring(7,15).toUpperCase(),
						status: status,
						subject: t.request.subject,
						owner: t.request.user_owner ? t.request.user_owner.last_name : '',
						request_date: t.request.date_request ? moment(t.request.date_request).toDate() : '',
						due_date: t.request.date_due ? moment(t.request.date_due).toDate() : '',
						completed_date: t.date_completed ? moment(t.date_completed).toDate() : '',
						urgent: t.request.urgent ? 'VERO' : 'FALSO',
						asset_type: asset_type,
						brand: a.brand ? a.brand.description : '',
						line: a.line ? a.line.description : '',
						product_code: a['product_code'],
						component_code: a['component_code'],
						po_number: a['order_code']
					});
				});
			}
		});
		let ws: WorkSheet = utils.json_to_sheet(json);
		utils.sheet_add_aoa(ws, heading);
		boldify_header(ws);
		utils.book_append_sheet(wb, ws, 'Euroitalia');
		writeFile(wb, 'Euroitalia.xlsx', { compression: true, bookType: 'xlsx', type: 'binary' });
	};

	const exportExcel_imageVideo = (
		list: TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial>[],
		config: ExportConfig
	) => {
		let wb: WorkBook = utils.book_new();
		let heading = [
			[
				'id',
				'status',
				'created by',
				'company',
				'owner',
				'request date',
				'due date',
				'completed date',
				'urgent',
				'asset type',
				'brand',
				'line',
				'country'
			]
		];
		let json = [];
		list.forEach(t => {
			let status = config.assetRequestUtilService.getLabelClassfromStatusListRemap(
				config.requestConfiguration.status.filterRemap,
				config.userType,
				t.status
			);
			if (t.asset_list) {
				t.asset_list.forEach(a => {
					let asset_type = RequestCenterEnum.Asset.Type.AdvertisingMaterialDescription[a.type.description];

					json.push({
						// mostrare prime 6 cifre del code item ticket
						id: t._id.substring(7,15).toUpperCase(),
						status: status,
						created_by: t.request.user ? t.request.user.username : '',
						company: t.request.company ? t.request.company.business_name : '',
						owner: t.request.user_owner ? t.request.user_owner.last_name : '',
						request_date: t.request.date_request ? moment(t.request.date_request).toDate() : '',
						due_date: t.request.date_due ? moment(t.request.date_due).toDate() : '',
						completed_date: t.date_completed ? moment(t.date_completed).toDate() : '',
						urgent: t.request.urgent ? 'VERO' : 'FALSO',
						asset_type: asset_type,
						brand: a.brand ? a.brand.description : '',
						line: a.line ? a.line.description : '',
						country: a.diffusion?.country ?  a.diffusion?.country : ''
					});
				});
			}
		});
		let ws: WorkSheet = utils.json_to_sheet(json);
		utils.sheet_add_aoa(ws, heading);
		boldify_header(ws);
		utils.book_append_sheet(wb, ws, 'Euroitalia');
		writeFile(wb, 'Euroitalia.xlsx', { compression: true, bookType: 'xlsx', type: 'binary' });
	};
}

function boldify_header(ws: WorkSheet) {
	//Setta la prima riga (intestazione) in grassetto e in maiuscolo.
	const headerStyle = {
		font: { bold: true } //font: { modificatori  }
	};
	const headerRange = utils.decode_range(ws['!ref']);
	for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
		const cell = utils.encode_cell({ r: headerRange.s.r, c: C });
		ws[cell].v = ('' + ws[cell].v).toUpperCase();
		ws[cell].s = headerStyle;
	}
}
