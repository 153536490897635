import { AbstractListModel } from "../structure/AbstractListModel";
import { UserDetailModel } from "@saep-ict/angular-spin8-core";
import { ChatInstanceTypeEnum } from '../../enum/chat.enum';

// store
export interface ChatDetailStateModel {
	client?: ChatModel;
	agency?: ChatModel;
	assistance?: ChatModel;
}

export interface ChatDetailStateFeatureSelectionParameterModel {
	side?: ChatInstanceTypeEnum;
	chat?: ChatModel;
	type?: string;
}

// entity
export class ChatModel {
    type?: string;
    _id?: string;
    _rev?: string;
    partecipant_list?: PartecipantList[];
    name?: string;
    last_message?: number;
    thread?: AbstractListModel<MessageModel[]>;
    url?: string;
}

export class MessageModel {
    type?: string;
    _id?: string;
    thread_id?: string;
    sender?: number;
    sender_user_info?: UserDetailModel;
    text?: string;
    date_sending?: number;
}

export interface PartecipantList extends UserDetailModel {
    last_access: number;
    company?: (string | number)[];
}
