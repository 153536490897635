import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// model
import { Pagination, AbstractQueryListModel, PageSettingsModel } from '../../../model/structure/AbstractListModel';

// store
import { Store } from '@ngrx/store';
import { TicketModel, TicketStateModel } from '../../../model/asset-request/ticket.model';
import { StateFeature } from '../../../state/index';

// widget & utility
import {
	ITdDataTableRowClickEvent,
	TdDataTableComponent,
	ITdDataTableSortChangeEvent,
	ITdDataTableColumn
} from '@covalent/core/data-table';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from '../../../service/util/util.service';
import { AppListWrapperComponent } from '../../../widget/list-wrapper/list-wrapper.component';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { skipWhile, map, take, mergeMap } from 'rxjs/operators';
import { AssetRequestUtilService } from '../../../service/util/asset-request-util.service';
import * as moment from 'moment';
import { CategoryListStateModel } from '../../../model/category-list.model';
import { AssetRequestCategory } from '../../../model/asset-request/asset-request-category-list.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BackendProcessQueueModel } from '../../../model/asset-request/backend-queue-process.model';
import { BackendProcessQueueAction } from '../../../state/asset-request/backend-process-queue/backend-process-queue.actions';
import { AssetRequestModel } from '../../../model/asset-request/request-center.model';
import { requestCenterConfiguration } from '../../../constant/request-center/request-center-configuration.constant';
import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { DialogRequestCenterAdvertisingMaterial } from '../widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { TicketStateAction } from '../../../state/asset-request/ticket/ticket.actions';
import { LocalStorageService } from 'ngx-webstorage';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-spin8-core';
import { AssetRequestExportExcelService } from '../../../service/export/asset-request-export-excel';

@Component({
	selector: 'app-request-center',
	templateUrl: './request-center.component.html',
	styleUrls: ['./request-center.component.scss'],
	providers: [SubscribeManagerService]
})
export class RequestCenterComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;
	@ViewChild('listWrapper') listWrapper: AppListWrapperComponent;
	@ViewChild('chipsAutocompleteLineInput') chipsAutocompleteLineInput: ElementRef;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(
		StateFeature.getOrganizationList
	);
	organizationList: OrganizationPouchModel[];

	ticketStatusEnum = RequestCenterEnum.Status.base;
	requestCenterEnum = RequestCenterEnum;

	platformType: 'frontoffice' | 'backoffice' = this.route.snapshot.data['platform'];

	userType = this.route.snapshot.paramMap.get('userType');
	requestType = this.route.snapshot.paramMap.get('requestType');

	requestConfiguration: AssetRequestModel.Configuration<
		DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
	> = requestCenterConfiguration.find(i => i.type === this.requestType);

	clientCode: string;

	form: FormGroup;

	pageName: string = `${this.requestConfiguration.type}_${this.userType}`;

	pageSettingsState$: Observable<PageSettingsModel> = this.store.select(StateFeature.getPageSettingsState);
	pageSettings: PageSettingsModel = {
		pageName: this.pageName
	};

	// category list
	categoryList$: Observable<CategoryListStateModel<AssetRequestCategory>> = this.store.select(
		StateFeature.getCategoriesState
	);
	categoryList: CategoryListStateModel<AssetRequestCategory>;

	BackendProcessQueue$: Observable<BackendProcessQueueModel> = this.store.select(StateFeature.getBackendProcessQueue);
	BackendProcessQueue: BackendProcessQueueModel;

	ticketList: TicketStateModel<
		TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow
	>[];
	ticketListState$: Observable<
		AbstractQueryListModel<
			TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>[]
		>
	> = this.store.select(StateFeature.getTicketListState);

	chipsAutocompleteLineList: AssetRequestCategory[] = [];

	constructor(
		private fb: FormBuilder,
		public router: Router,
		private route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public utilService: UtilService,
		public assetRequestUtilService: AssetRequestUtilService,
		private utilStoreService: StoreUtilService,
		private localStorageService: LocalStorageService,
		private exportExcelService: AssetRequestExportExcelService
	) {
		this.loadStaticData();
		this.createForm();
		this.initPageSetting();
		this.subscribeManagerService.populate(this.subscribeCategoryList().subscribe(), 'initMandatory');
		this.loadTicketFilteredList();
		this.subscribeManagerService.populate(this.getTicketList().subscribe(), 'request-center');
		this.subscribeManagerService.populate(this.subscribeBrandChange(), 'brand-change');
		this.checkNavigationControlVisibility();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(TicketStateAction.resetList());
	}

	// subscribe
	loadStaticData() {
		this.utilStoreService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(e => {
			this.user = e.data;
		});
		this.utilStoreService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(e => {
			this.organizationList = e.data;
		});
		// se l'utente è di tibo B2B organizationList store restituisce in array solo la sua organization
		this.clientCode =
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B
				? this.organizationList[0].code_erp
				: this.route.snapshot.paramMap.get('clientCode');
	}

	subscribeCategoryList() {
		return this.categoryList$.pipe(
			skipWhile((categoryList: CategoryListStateModel<AssetRequestCategory>) => !categoryList),
			map((categoryList: CategoryListStateModel<AssetRequestCategory>) => {
				this.assetRequestUtilService.categoryList = categoryList;
				this.setValueForm();
			})
		);
	}

	subscribeBrandChange(): Subscription {
		return this.form.get('brandList').valueChanges.subscribe(res => {
			if (res) {
				this.chipsAutocompleteLineSetList(res);
			}
		});
	}

	getTicketList() {
		return this.ticketListState$.pipe(
			skipWhile(
				(
					ticketListState: AbstractQueryListModel<
						TicketStateModel<
							TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow
						>[]
					>
				) => !ticketListState
			),
			map(
				(
					ticketListState: AbstractQueryListModel<
						TicketStateModel<
							TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow
						>[]
					>
				) => {
					// valutare in seguito la necessità di
					// this.pageSettings = ticketListState.query;
					this.ticketList = ticketListState.data;
				}
			)
		);
	}

	loadTicketFilteredList() {
		this.store.dispatch(TicketStateAction.loadList(this.pageSettings));
	}

	// form - filters

	createForm() {
		this.form = this.fb.group({
			status_list: [[]],
			asset_type: [null],
			urgent: [null],
			date_request: this.fb.group({
				start: [null],
				end: [null]
			}),
			date_due: this.fb.group({
				start: [null],
				end: [null]
			}),
			brandList: [[]],
			lineList: [[]]
		});
	}

	/**
	 * - status_list: le condizioni nulla-selezionato e tutto-selezionato emettono lo stesso valore per
	 *   l'array usato nel selettore couch. Per evitare la visualizzazione di tutte le checkbox selezionate onInit,
	 *   il campo viene svuotato (prepareSaveForm agisce di conseguenza settando tutto i valori in caso di nessuno selezionato)
	 */
	setValueForm() {
		const statusListAllSelected: boolean =
			this.pageSettings.filters.status_list.length ===
			this.requestConfiguration.status.filterRemap[this.userType].length;
		this.form.setValue({
			status_list: statusListAllSelected ? [] : this.pageSettings.filters.status_list,
			asset_type: this.pageSettings.filters.asset_type,
			urgent: this.pageSettings.filters.urgent,
			date_request:
				this.pageSettings.filters.date_request &&
				this.pageSettings.filters.date_request.start &&
				this.pageSettings.filters.date_request.end
					? this.pageSettings.filters.date_request
					: { start: null, end: null },
			date_due:
				this.pageSettings.filters.date_due &&
				this.pageSettings.filters.date_due.start &&
				this.pageSettings.filters.date_due.end
					? this.pageSettings.filters.date_due
					: { start: null, end: null },
			brandList:
				this.pageSettings.filters.brandList && this.pageSettings.filters.brandList.length > 0
					? this.pageSettings.filters.brandList
					: [],
			lineList:
				this.pageSettings.filters.lineList && this.pageSettings.filters.lineList.length > 0
					? this.pageSettings.filters.lineList
					: []
		});

		if (this.pageSettings.filters.brandList && this.pageSettings.filters.brandList.length > 0) {
			this.chipsAutocompleteLineSetList(this.pageSettings.filters.brandList);
		}
	}

	getformFieldValue(key: string) {
		return this.form.get(key).value;
	}

	chipsAutocompleteLineSetList(brandList: string[]) {
		this.chipsAutocompleteLineList = [];
		brandList.forEach((brandCode: string) => {
			const brand = this.assetRequestUtilService.categoryList.category_list.find(i => i.code === brandCode);
			if (brand && brand.category_list && brand.category_list.length > 0) {
				brand.category_list.forEach(line => {
					this.chipsAutocompleteLineList.push(line);
				});
			}
		});
		let formFieldLineList = this.getformFieldValue('lineList');
		if (formFieldLineList) {
			formFieldLineList = formFieldLineList.filter(i => this.chipsAutocompleteLineListHasLineCode(i));
			this.form.patchValue({ lineList: formFieldLineList });
		}
	}

	chipsAutocompleteLineListHasLineCode(lineCode: string): boolean {
		return this.chipsAutocompleteLineList.find(i => i.code === lineCode) ? true : false;
	}

	chipsAutocompleteLineAddItem(event: MatAutocompleteSelectedEvent): void {
		const formFieldLineList = this.getformFieldValue('lineList') ? this.getformFieldValue('lineList') : [];
		formFieldLineList.push(event.option.value.code);
		this.form.patchValue({ lineList: formFieldLineList });
		this.chipsAutocompleteLineInput.nativeElement.value = '';
	}

	chipsAutocompleteLineRemoveItem(line: string): void {
		const formFieldLineList = this.getformFieldValue('lineList');
		formFieldLineList.splice(formFieldLineList.indexOf(line), 1);
		this.form.patchValue({ lineList: formFieldLineList });
	}

	/**
	 * - il metodo non influisce sul valore dei filtri company, text e non ne fa la dispatch (page-settings-state)
	 * - essendo loadTicketFilteredList() (assetRequestCenterPouch.loadTicketFilteredList()) sempre contestuale
	 *   ad un selettore su ticket.status, per evitare la restituzione di elementi non rilevanti per l'utente corrente,
	 *   il seguente metodo restituisce (insieme al resto):
	 *    - requestConfiguration.status.filterRemap[userType]
	 *    - un sub set di requestConfiguration.status.filterRemap[userType] eventualmente contenuto in formModel.status_list
	 */
	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			status_list:
				formModel.status_list && formModel.status_list.length > 0
					? formModel.status_list
					: this.requestConfiguration.status.filterRemap[this.userType],
			asset_type: formModel.asset_type as number,
			urgent: formModel.urgent,
			date_due: formModel.date_due
				? {
						start: this.getUnixDate(formModel.date_due.start, 'start'),
						end: this.getUnixDate(formModel.date_due.end, 'end')
				  }
				: null,
			date_request: formModel.date_request
				? {
						start: this.getUnixDate(formModel.date_request.start, 'start'),
						end: this.getUnixDate(formModel.date_request.end, 'end')
				  }
				: null,
			brandList: formModel.brandList,
			lineList: formModel.lineList
		};
		return saveForm;
	}

	private getUnixDate(date: Date, dayMoment: string): number {
		let unixDate = moment(date);
		if (dayMoment === 'start') {
			unixDate = unixDate.startOf('day');
		} else if (dayMoment === 'end') {
			unixDate = unixDate.endOf('day');
		}
		return parseInt(unixDate.format('x'), 10);
	}

	resetFilters() {
		this.form.reset();
		this.onFormSubmit();
	}

	onFormSubmit() {
		if (this.form.valid) {
			const formReady = this.prepareSaveForm();

			this.pageSettings.filters.status_list = formReady.status_list;
			this.pageSettings.filters.asset_type = formReady.asset_type;
			this.pageSettings.filters.urgent = formReady.urgent;
			this.pageSettings.filters.date_due = formReady.date_due;
			this.pageSettings.filters.date_request = formReady.date_request;
			this.pageSettings.filters.brandList = formReady.brandList;
			this.pageSettings.filters.lineList = formReady.lineList;
			this.pageSettings.pagination.page_current = 1;

			this.setPageSettingsLocalStorage();
			this.loadTicketFilteredList();
		}
	}

	initPageSetting() {
		let localStoragePageSetting: PageSettingsModel = this.localStorageService.retrieve(this.pageName);
		if (
			localStoragePageSetting &&
			localStoragePageSetting.filters &&
			localStoragePageSetting.filters.company &&
			this.clientCode &&
			this.clientCode !== localStoragePageSetting.filters.company
		) {
			localStoragePageSetting = null;
		}
		this.pageSettings.pagination =
			localStoragePageSetting && localStoragePageSetting.pagination
				? localStoragePageSetting.pagination
				: {
						page_current: 1,
						page_size: 10
				  };
		this.pageSettings.filters =
			localStoragePageSetting && localStoragePageSetting.filters
				? localStoragePageSetting.filters
				: {
						company: this.clientCode ? this.clientCode : null,
						text: null,
						status_list: this.requestConfiguration.status.filterRemap[this.userType],
						asset_type: null,
						date_request: null,
						date_due: null,
						brandList: null,
						lineList: null,
						types: [this.requestConfiguration.actionKey]
				  };
		this.pageSettings.filters.urgent =
			localStoragePageSetting &&
			localStoragePageSetting.filters &&
			localStoragePageSetting.filters.urgent &&
			this.requestConfiguration.pageDetail.headerField.urgent.visible[this.userType]
				? localStoragePageSetting.filters.urgent
				: null;

		this.pageSettings.sort =
			localStoragePageSetting && localStoragePageSetting.sort
				? localStoragePageSetting.sort
				: [
						{
							name: this.requestConfiguration.sort.name,
							order: this.requestConfiguration.sort.order
						}
				  ];
		this.setPageSettingsLocalStorage();
	}

	checkNavigationControlVisibility(): void {
		if (
			this.requestConfiguration.pageList.hiddenColumnNameList &&
			this.requestConfiguration.pageList.hiddenColumnNameList[this.userType]
		) {
			this.requestConfiguration.pageList.columnList[this.userType].map(col => {
				col.hidden = this.requestConfiguration.pageList.hiddenColumnNameList[this.userType].includes(col.name);
			});
		}
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		const url = `${this.router.url}/${e.row._id}`;
		this.router.navigate([url]);
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		this.pageSettings.sort = [
			{
				name: e.name,
				order: e.order.toLowerCase()
			}
		];
		this.setPageSettingsLocalStorage();
		this.loadTicketFilteredList();
	}

	paginationChange(pagination: Pagination) {
		this.pageSettings.pagination = pagination;
		this.setPageSettingsLocalStorage();
		this.loadTicketFilteredList();
	}

	toggleColumn(e) {
		for (let i = 0; i < this.requestConfiguration.pageDetail.columnList[this.userType].length; i++) {
			if (this.requestConfiguration.pageDetail.columnList[this.userType][i].name === e.name) {
				this.requestConfiguration.pageDetail.columnList[this.userType][i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	exportTicket() {
		this.onFormSubmit();
		this.store.dispatch(BackendProcessQueueAction.load(this.pageSettings));
	}

	compareValueList(a, b): boolean {
		if (a && b && a.value && b.value && a.value.length && b.value.length) {
			for (let i = 0; i < a.value.length; i++) {
				if (!b.value.includes(a.value[i])) {
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	}

	// TODO: questo salvataggio serve tendenzialmente soltanto a precompilare il filtro brand su richiesta dei
	// referenti del type print-production-flow
	// deprecare una volta sviluppate sub sezioni di request-center divise per brand
	setPageSettingsLocalStorage() {
		this.localStorageService.store(this.pageName, {
			filters: this.pageSettings.filters,
			sort: this.pageSettings.sort,
			pagination: this.pageSettings.pagination
		});
	}

	exportExcel() {
		this.exportExcelService.download(this.pageSettings, this.userType, this.requestType);
	}
}
