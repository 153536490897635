import { TdDataTableSortingOrder } from "@covalent/core/data-table";
import { AttachmentListManager, attachmentListManagerColumnListBase } from "@saep-ict/angular-core";
import { AttachedFile, LocalListHandlerBaseModel } from "@saep-ict/pouch_agent_models";


export const configuration: AttachmentListManager.Configuration<AttachedFile> = {
    localListHandler: <LocalListHandlerBaseModel<AttachedFile>>{
        pagination: { pageSize: 10 },
        filters: {
            localSearchText: {
                value: null,
                key_list: ['name']
            }
        },
        sort: {
            name: 'date_creation',
            order: TdDataTableSortingOrder.Descending
        },
        data: []
    },
    page: {
        title: 'saep_ict_angular_core.attachment_list_manager.name_plural',
        columnList: attachmentListManagerColumnListBase,
        display: {
            paginator: true,
           // sidebarFilter: true,
            textFilter: true
        }
    },
    upload: {
        multiple: true,
        fileNameRegex: /^[a-z0-9_. () -]+$/i,
        fileNameCharactersAccepted: "a-z 0-9 . _ - ()",
        fileNameAllowDuplicate: true
    },
    privilege: null,
    pathUrl: 'asset-request-center',
    pathQueryParam: null,
    customHeaders: null
}