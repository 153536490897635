import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BackendProcessQueueReducer } from './backend-process-queue.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('backend-process-queue', BackendProcessQueueReducer.reducer),
	]
})
export class BackendProcessQueueStateModule {}
