// TODO: usare quello di libreria?
export enum ContextApplicationItemCodeEnum {
    BACKOFFICE_ADMIN = "BACKOFFICE_ADMIN",
    BACKOFFICE = "BACKOFFICE",
    B2B = "B2B"
}

export enum ContextOtherItemCodeEnum {
    ORDER = 4
}
