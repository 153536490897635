import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// model
import { MessageModel, ChatModel, ChatDetailStateFeatureSelectionParameterModel } from '../../../model/pouch/chat.model';
import { RemoteChangeState } from '../../../model/remote-change.model';
import { ChatDetailStateAction } from '../../../state/chat/chat-detail/chat-detail.actions';
import { ChatInstanceTypeEnum } from '../../../enum/chat.enum';
import { UserDetailModel } from '@saep-ict/angular-spin8-core';

// store
import { Store } from '@ngrx/store';
import * as fromState from '../../../state/index';
import { RemoteChangeStateAction } from '../../../state/remote-change/remote-change.actions';
import { StateFeature } from '../../../state/index';

// widget & utility
import { MediaReplayService } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilService } from '../../../service/util/util.service';
import { Observable } from 'rxjs';
import { ChatService } from '../../../service/rest/chat.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { map, skipWhile, take, mergeMap } from 'rxjs/operators';
import { BaseStateModel } from '../../../model/state/base-state.model';

@Component({
	selector: 'chat-detail',
	templateUrl: './chat-detail.component.html',
	styleUrls: ['./chat-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ChatDetailComponent implements OnInit, OnDestroy {

	platformType: string = this.route.snapshot.data['platform'];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
	user: UserDetailModel;

	chat$: Observable<ChatModel> =
		this.store.select(
			fromState.StateFeature.selectActiveAssetRequestChat,
			{ side: ChatInstanceTypeEnum.assistance }
		);
	chat: ChatDetailStateFeatureSelectionParameterModel = {
		side: ChatInstanceTypeEnum.assistance,
		chat: {
			_id: this.route.snapshot.paramMap.get('idChat'),
			thread: {
				pagination: {
					page_current: 1,
					page_size: 15
				}
			}
		}
	};

	remoteChange$: Observable<RemoteChangeState> = this.store.select(StateFeature.getRemoteChangeState);

	sender;

	constructor(
		public mediaReplayService: MediaReplayService,
		private route: ActivatedRoute,
		private store: Store<any>,
		private chatService: ChatService,
		private utilService: UtilService,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.mediaReplayService.deviceH = document.body.clientHeight;
		this.subscribeManagerService.populate(this.subscribeUser().subscribe(), 'user');
		this.subscribeManagerService.populate(this.subscribeChat().subscribe(), 'chat');
		this.subscribeManagerService.populate(this.subscribeRemoteChange().subscribe(), 'remote-change');
		this.getChat();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ChatDetailStateAction.closeConnection(this.chat));
		this.store.dispatch(RemoteChangeStateAction.reset());
	}

	subscribeUser() {
		return this.user$.pipe(
			skipWhile((user: BaseStateModel<UserDetailModel>) => !user.data),
			take(1),
			mergeMap((user: BaseStateModel<UserDetailModel>) => {
				this.user = user.data;
				return this.user$;
			})
		);
	}

	subscribeChat() {
		return this.chat$.pipe(
			skipWhile((chat: ChatModel) => !chat),
			map((chat: ChatModel) => {
				this.chat.chat = chat;
				this.cdr.detectChanges();
				if (
					this.chat.chat.last_message !==
					this.chat.chat.partecipant_list[
						this.utilService.getElementIndex(
							this.chat.chat.partecipant_list,
							'id',
							this.user.id
							)
						].last_access
				) {
					this.onChatUpdateUserLastAccess();
				}
			})
		);
	}

	subscribeRemoteChange() {
		return this.remoteChange$.pipe(
			skipWhile((remoteChangeState: RemoteChangeState) => !(remoteChangeState && remoteChangeState.chatDetailState)),
			map((remoteChangeState: RemoteChangeState) => {
				if (remoteChangeState && remoteChangeState.chatDetailState) {
					this.getChat();
				}
			})
		);
	}

	// data

	/**
	 * Effettua la get dell'intera chat e pagina 1 del thread. L'evento è sfruttato attualmente per ripristinare lo stato
	 * della chat in caso di invio messaggio o ricezione messaggio da remoto
	 */
	getChat() {
		const chat: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(JSON.stringify(this.chat));
		chat.chat.thread.pagination.page_current = 1;
		this.store.dispatch(ChatDetailStateAction.load(chat));
	}

	loadChatPage(page: number) {
		const item: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(JSON.stringify(this.chat));
		item.chat.thread.pagination.page_current = page;
		this.store.dispatch(ChatDetailStateAction.loadMessageList(item));
	}

	onChatSendMessage(e: MessageModel) {
		const message: MessageModel = {
			date_sending: Date.now(),
			text: e.text,
			thread_id: this.chat.chat._id,
			sender: parseInt(this.user.id, null),
			type: 'message'
		};
		this.chatService.sendMessage(message, res => {
			if (!message.thread_id) {
				this.chat.chat._id = res['thread_id'];
				this.getChat();
			}
		}, err => {
			this.snackBar.open('An error has occurred, please try again later', 'OK', {
				duration: 3000
			});
		});
	}

	/**
	 * Setta il timestamp last_access dell'utente al timestamp date_sending dell'ultimo messaggio inviato nel thread
	 * @param timestamp
	 */
	onChatUpdateUserLastAccess() {
		const chat: ChatDetailStateFeatureSelectionParameterModel = JSON.parse(JSON.stringify(this.chat));

		chat.chat.partecipant_list = [];

		chat.chat.partecipant_list = this.chat.chat.partecipant_list.map(n => {
			const obj = {
				id: n.id,
				last_access: n.last_access
			};
			return obj;
		});

		chat.chat.partecipant_list[this.utilService.getElementIndex(chat.chat.partecipant_list, 'id', this.user.id)] = {
			id: this.user.id,
			last_access: this.chat.chat.last_message
		};
		this.store.dispatch(ChatDetailStateAction.updateRemoteLastAccess(chat));
	}

}
