import { ITdDataTableColumn } from '@covalent/core/data-table';
import { RequestCenterColumnListPageListBase } from './page-list-base.constant';

export namespace RequestCenterColumnListPageListCompany {
	export namespace advertisingMaterial {
		const columnNameList: string[] = [
			'_id',
			'asset_list_brand',
			'asset_list_line',
			'asset_list_number',
			'asset_list_type',
			'request.date_due',
			'request.date_request',
			'status'
		];
		export const columList: ITdDataTableColumn[] = RequestCenterColumnListPageListBase.base.filter(col =>
			columnNameList.includes(col.name)
		);
	}
}
