import { BasePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum OrganizationListActionEnum {
	UPDATE = '[organization-list] Update',
	LOAD = '[organization-list] Load',
	LOAD_DETAIL = '[organization-list] Load Detail',
	RESET = '[organization-list] Reset',
	ERROR = '[organization-list] Error'
}

export namespace OrganizationListStateAction {
	export const load = createAction(OrganizationListActionEnum.LOAD);
	export const loadDetail = createAction(
		OrganizationListActionEnum.LOAD_DETAIL,
		props<BaseStateModel<BasePouchModel>>()
	);
	export const update = createAction(
		OrganizationListActionEnum.UPDATE,
		props<BaseStateModel<OrganizationPouchModel[]>>()
	);
	export const reset = createAction(OrganizationListActionEnum.RESET);
	export const error = createAction(OrganizationListActionEnum.ERROR, props<BaseStateModel<any>>());
}
