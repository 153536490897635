// model
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';

export class ChatAssistencePouch extends AbstractPouchdbMethod {
	getList(): Promise<any> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: 'thread'
					}
				]
			} as PouchDB.Find.Selector;
			const sort: { [key: string]: 'asc' | 'desc' }[] = [
				{
					last_message: 'desc'
				}
			];
			this.getWithIndexFilter('thread-list', selector, null, sort)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
