import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AppTableColumnToggleComponent } from './table-column-toggle/table-column-toggle.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { DialogConfirmComponent } from './dialog/dialog-confirm/dialog-confirm.component';
import { AppListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { AppAttachmentResolverComponent } from './attachment-resolver/attachment-resolver.component';
import { DropAreaFileComponent } from './drop-area-file/drop-area-file.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppChatThreadComponent } from './chat-thread/chat-thread.component';
import { PouchPagingBarComponent } from './pouch-paging-bar/pouch-paging-bar.component';
import { DialogRequestCenterAdvertisingMaterial } from '../page/asset-request/widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { DialogRequestCenterPrintProductionFlowComponent } from '../page/asset-request/widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component';
import { ButtonListComponent } from './button-list/button-list.component';
import { ChatPdfComponent } from '../service/print-chat-pdf/chat-pdf/chat-pdf.component';

@NgModule({
    imports: [SharedModule],
    declarations: [
        AppTableColumnToggleComponent,
        AutocompleteComponent,
        DialogConfirmComponent,
        AppListWrapperComponent,
        AppAttachmentResolverComponent,
        DropAreaFileComponent,
        BreadcrumbComponent,
        AppChatThreadComponent,
        PouchPagingBarComponent,
        DialogRequestCenterAdvertisingMaterial,
        DialogRequestCenterPrintProductionFlowComponent,
        MatSnackBarWrapperComponent,
        ButtonListComponent,
        ChatPdfComponent
    ],
    exports: [
        AppTableColumnToggleComponent,
        AutocompleteComponent,
        DialogConfirmComponent,
        AppListWrapperComponent,
        AppAttachmentResolverComponent,
        DropAreaFileComponent,
        BreadcrumbComponent,
        AppChatThreadComponent,
        PouchPagingBarComponent,
        MatSnackBarWrapperComponent,
        ButtonListComponent,
        ChatPdfComponent
    ]
})
export class WidgetModule {}
