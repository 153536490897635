import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';
import { TicketModel, TicketPouchModel, TicketStatusRemapItemModel } from '../../../model/asset-request/ticket.model';
import { ButtonItemModel } from '../../../model/button-list.model';

export namespace RequestCenterStatusMapAgency {
	export namespace FilterRemap {
		export const base: TicketStatusRemapItemModel[] = [
			{
				value: [RequestCenterEnum.Status.base.PENDING_AGENCY],
				label: 'PENDING'
			},
			{
				value: [RequestCenterEnum.Status.base.COMPLETED_AGENCY],
				label: 'COMPLETED',
				labelClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.COMPLETED_AGENCY]
			}
		];
		export namespace RequestType {
			export const advertisingMaterial: TicketStatusRemapItemModel[] = RequestCenterStatusMapAgency.FilterRemap.base.concat(
				[
					{
						value: [RequestCenterEnum.Status.base.SENT_TO_CLIENT],
						label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.SENT_TO_CLIENT]
					}
				]
			);
			export const printProductionFlow: TicketStatusRemapItemModel[] = RequestCenterStatusMapAgency.FilterRemap.base.concat(
				[
					{
						value: [RequestCenterEnum.Status.base.PENDING_EUROITALIA],
						label: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.PENDING_EUROITALIA]
					},
					{
						value: [RequestCenterEnum.Status.base.COMPLETED_EUROITALIA],
						label: 'closed'
					}
				]
			);
		}
	}
	export namespace StatusChangeButtonList {
		export namespace RequestType {
			export const base = (
				ticket: TicketPouchModel<
					TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow
				>
			): ButtonItemModel[] => {
				return [
					{
						value: RequestCenterEnum.Status.base.COMPLETED_AGENCY,
						label: 'Send to Euroitalia as <i>Complete</i>',
						visible: () => ticket.status === RequestCenterEnum.Status.base.PENDING_AGENCY,
						buttonClass: RequestCenterEnum.Status.base[RequestCenterEnum.Status.base.COMPLETED_AGENCY]
					}
				];
			};
			export const advertisingMaterial = (
				ticket: TicketPouchModel<TicketModel.AssetItem.AdvertisingMaterial>
			): ButtonItemModel[] => {
				return base(ticket);
			};
			export const printProductionFlow = (
				ticket: TicketPouchModel<TicketModel.AssetItem.PrintProductionFlow>
			): ButtonItemModel[] => {
				return base(ticket);
			};
		}
	}
}
