import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ChatDetailStateAction, ChatDetailActionEnum } from './chat-detail.actions';
// model
import { ChatDetailStateModel } from '../../../model/pouch/chat.model';

export namespace ChatDetailStateReducer {

	// per motivi non chiari, effettuando delle subscribe ai selettori di feature (client, agency)
	// initialState resta bindato a state

	export const initialState: ChatDetailStateModel = {
		client: null,
		agency: null,
		assistance: null
	};

	const _reducer = createReducer(
		JSON.parse(JSON.stringify(initialState)),
		on(ChatDetailStateAction.update, (state, param) => {
			state[param.side] = param.chat;
			return JSON.parse(JSON.stringify(state));
		}),
		on(ChatDetailStateAction.load, state => state),
		on(ChatDetailStateAction.loadMessageList, state => state),
		on(ChatDetailStateAction.closeConnection, state => state),
		on(ChatDetailStateAction.reset, (state, param) => {
			state[param.side] = null;
			return state;
		}),
		on(ChatDetailStateAction.error, (state, param) => {
			state[param.side] = null;
			state.type = param.type;
			return JSON.parse(JSON.stringify(state));
		})
	);
	export function reducer(
		state: ChatDetailStateModel,
		action: TypedAction<ChatDetailActionEnum>
	) {
		return _reducer(state, action);
	}

}
