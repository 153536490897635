import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AssetRequestModel } from "../../model/asset-request/request-center.model";
import { DialogRequestCenterAdvertisingMaterial } from "../../page/asset-request/widget/dialog/dialog-request-center-advertising-material/dialog-request-center-advertising-material.component";
import { DialogRequestCenterPrintProductionFlowComponent } from "../../page/asset-request/widget/dialog/dialog-request-center-print-production-flow/dialog-request-center-print-production-flow.component";
import { requestCenterConfiguration } from "../../constant/request-center/request-center-configuration.constant";
import { CategoryListAction } from "../../state/category-list/category-list.actions";
import { PouchAdapterEnum } from "../../enum/pouch-adapter.enum";

@Injectable()
export class AssetRequestCategoryResolver implements Resolve<void> {

	constructor(
		private store: Store<any>
	) { }

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> | Promise<any> | any {
		const requestType: string = route.paramMap.get('requestType');
		const requestConfiguration: AssetRequestModel.Configuration<
			DialogRequestCenterAdvertisingMaterial | DialogRequestCenterPrintProductionFlowComponent
		> = requestCenterConfiguration.find(i => i.type === requestType);
		this.store.dispatch(
			CategoryListAction.loadBrandAndLine({
				adapter: PouchAdapterEnum.ASSET_REQUEST,
				filter: { type: requestConfiguration.actionKey }
			})
		);
	}

}
