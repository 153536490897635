import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	IResourceMethodPromise,
	IResourceMethodPromiseStrict,
	ResourceAction,
	ResourceRequestMethod
} from '@ngx-resource/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CustomerAppConfig } from '../../customer-app.config';
import { TicketModel, TicketStateModel } from '../../model/asset-request/ticket.model';
import { LoaderService } from '../structure/loader.service';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class AssetRequestService extends BaseApiService {
	constructor(
		public loaderService: LoaderService,
			public router: Router,
			public localStorageService: LocalStorageService,
			public appConfig: CustomerAppConfig
	  ) {
		super(loaderService, router, localStorageService, appConfig);
		this.appConfig.config$.subscribe(config => {
			if (config && config.assetRequestCenter) {
				this.$setPathPrefix(config.assetRequestCenter.be_url);
			}
		});
	  }
	// TODO: sostituire con POST su couch e upload diretto (bucket manager), change feed:
	// - notifica email utenti coinvolti
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/ticket/create-update'
	})
	saveTicket: IResourceMethodPromise<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>, any>;
	// TODO: sostituire con POST su couch
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/ticket/delete'
	})
	deleteTicket: IResourceMethodPromise<TicketStateModel<TicketModel.AssetItem.AdvertisingMaterial | TicketModel.AssetItem.PrintProductionFlow>, any>;
	// TODO: sostituire con POST su couch e download diretto (bucket manager)
	@ResourceAction({
		path: '/file/download',
		method: ResourceRequestMethod.Post
	})
	downloadExportTicket: IResourceMethodPromiseStrict<
		{
			selector: PouchDB.Find.Selector;
			sort: {
				[key: string]: 'asc' | 'desc';
			}[];
		},
		{ type: string },
		void,
		string
	>;
}
