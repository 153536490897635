<mat-toolbar>
    <mat-toolbar-row>
        <button
            *ngIf="backButton"
            mat-icon-button
            (click)="onClickBackButton()"
        >
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="info-container toolbar-breadcrumbs">
            <div class="title-container">
                <span class="title">{{ title }}</span> &nbsp;
                <span *ngIf="badge" class="badge {{ badge | uppercase }}">{{ badge }}</span>
            </div>
            <div class="subtitle">
                <span>{{ subtitle }}</span>
            </div>
        </div>
        <span class="flex-span"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="options" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-content select="[actions]"></ng-content>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>