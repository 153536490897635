import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import * as ConfigurationCustomerPdfPrint from '../chat-pdf-print.constants';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import { ChatPdfDataModel } from '../../../service/print-chat-pdf/util-chat-pdf.service';
import { Store } from '@ngrx/store';
import { RequestCenterEnum } from '../../../enum/request-center/request-center.enum';

@Component({
	selector: 'chat-pdf',
	templateUrl: './chat-pdf.component.html',
	styleUrls: ['./chat-pdf.component.scss']
})
export class ChatPdfComponent implements OnInit {
	application_context: ContextApplicationItemCodeEnum;
	pdfData: ChatPdfDataModel;
	ticket: string;
	side: string;
	userType: string

	company_variables = ConfigurationCustomerPdfPrint.company_variables;
	requestCenterEnum = RequestCenterEnum;

	constructor(@Inject(MAT_DIALOG_DATA) public data, private store: Store,) {
		try {
			console.log(data)
			this.pdfData = data.chat;
			this.ticket = data.ticket;
			this.side = data.side;
			this.userType = data.userType;
		}
		catch(e) {
			console.log(e.status, e.error)
			throw new Error(e);
		}
	}

	ngOnInit(): void {}

	print() {
		let elementHTML = document.getElementById('print-section');
		var doc = new jsPDF('p', 'px', 'a4', false);
		let filename = `chat_${this.ticket}.pdf`;
		doc.html(elementHTML, {
			callback: function (doc) {
				doc.save(filename);
			},
			width: 405,
			windowWidth: 1000,
			margin: 20,
			autoPaging: 'text'
		});
	}
}
