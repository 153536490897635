import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { OrganizationListActionEnum, OrganizationListStateAction } from './organization-list.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrganizationStateModel } from '@saep-ict/angular-spin8-core';

export namespace OrganizationListStateReducer {
	export const initialState: BaseStateModel<OrganizationStateModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(OrganizationListStateAction.load, state => state),
		on(OrganizationListStateAction.loadDetail, state => state),
		on(OrganizationListStateAction.update, (state, param) => param),
		on(OrganizationListStateAction.reset, () => initialState),
		on(OrganizationListStateAction.error, (state, param) => param)
	);

	export function reducer(
		state: BaseStateModel<OrganizationStateModel[]>,
		action: TypedAction<OrganizationListActionEnum>
	) {
		return _reducer(state, action);
	}
}
