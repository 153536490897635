import { createAction, props } from '@ngrx/store';
import { RemoteChangeState } from '../../model/remote-change.model';

export enum RemoteChangeActionEnum {
	UPDATE = '[Remote Change] Update',
	RESET = '[Remote Change] Reset',
	ERROR = '[Remote Change] Error'
}
export namespace RemoteChangeStateAction {
	export const update = createAction(RemoteChangeActionEnum.UPDATE, props<RemoteChangeState>());
	export const reset = createAction(RemoteChangeActionEnum.RESET);
	export const error = createAction(RemoteChangeActionEnum.ERROR);
}
