import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
// model
import { BrandItemModel, CurrencyModel } from '../../../../../model/survey-center/survey.model';
// widget
import { ThousandPipe } from '../../../../../shared/pipes/thousand-separator.pipe';
import { AppAttachmentResolverService } from '../../../../../service/structure/app-attachment-resolver.service';
import { UtilService } from '../../../../../service/util/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Brand } from '../../../../../model/brand.model';
import { IconUtilService } from '../../../../../service/util/icon-util.service';

@Component({
	selector: 'survey-form',
	templateUrl: './survey-form.component.html',
	styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {
	private _currency: CurrencyModel = new CurrencyModel();
	@Input() set currency(c: CurrencyModel) {
		if (c) {
			this._currency = c;
		}
	}
	get currency(): CurrencyModel {
		return this._currency;
	}

	private _brand: BrandItemModel = new BrandItemModel();
	@Input() set brand(b: BrandItemModel) {
		if (b) {
			this._brand = b;
			this.createForm();
		}
	}
	get brand(): BrandItemModel {
		return this._brand;
	}

	private _disabled = false;
	@Input() set disabled(value: boolean) {
		if (typeof value === 'boolean') {
			this._disabled = value;
			this.checkDisableForm(this._disabled);
		}
	}
	get disabled(): boolean {
		return this._disabled;
	}

	@Output() onSaveSurveyForm = new EventEmitter();
	@Output() onDownloadTemplate = new EventEmitter<Brand>();
	@Output() onDownloadLatest = new EventEmitter<string>();

	@ViewChild('resetTag') formResetTag;

	form: FormGroup;
	fields = [];
	eurConvertedValues = {};
	formHasNumberField: boolean;

	constructor(
		private thousandPipe: ThousandPipe,
		private attachmentResolverService: AppAttachmentResolverService,
		public utilService: UtilService,
		public sanitization: DomSanitizer,
		public iconUtilSerice: IconUtilService
	) {}

	ngOnInit() {}
	/**
	 * Evendo associato all'emit per la selezione dei file in attachment-resolver
	 *
	 * @param field il campo del form associato al componente
	 * @param fileArray la lista di file selezionati dall'utente
	 */
	onFileChange(field, fileArray) {
		this.form.get(field.name).setValue(fileArray[0]);
	}
	/**
	 * Simula il click sul tag a che scatena a sua volta il download del file (template e latest)
	 */
	onDownloadFileTemplateButtonClick() {
		this.onDownloadTemplate.emit(this.brand.brand);
	}

	// form
	/**
	 * Invocato da @Input() set brand
	 *
	 * Sulla base delle proprietà contenute nell'oggetto brand.survey_form (vedere SurveyFieldModel)
	 *
	 * 1 popola fields: array con i nomi delle proprietà di brand.survey_form
	 * 2 popola form (FormGroup) con una serie di FormControl aventi:
	 *   - formControlName: nome della proprietà
	 *   - required: se previsto dall'oggetto di configurazione (vedere SurveyFieldModel)
	 *   - value: il valore passato da BE, se type=number (vedere SurveyFieldModel) trasformato dalla thousandPipe
	 * 3 genera l'oggetto eurConvertedValues con i valori convertiti in euro in base a exchange_rate
	 *
	 */
	createForm() {
		if (this.form) {
			// workaround per eliminare  gli errori di validazione generati dall'eventuale submit di un altro brand
			// in sostituzione degli'inefficaci .reset() .markeAs*() ecc
			this.formResetTag.resetForm();
		}
		this.formHasNumberField = false;
		const controls = {};
		this.fields = [];
		this.attachmentResolverService.empty();
		for (const field of this.brand.survey_form) {
			this.fields.push(field);
			if (field.type === 'number') {
				this.formHasNumberField = true;
				controls[field.name] = new FormControl(
					this.thousandPipe.transform(field.value),
					this.utilService.setRequired(field.required)
				);
				this.eurConvertedValues[field.name] = this.utilService.getEurConvertedValue(
					field.value,
					+this.currency.eurRate
				);
			} else {
				controls[field.name] = new FormControl(field.value, this.utilService.setRequired(field.required));
			}
		}
		this.form = new FormGroup(controls);
		this.checkDisableForm(this._disabled);
	}

	/**
	 * Handler per l'evento (input) sui mat-input associati a campi di tipo number (vedere SurveyFieldModel)
	 *
	 * 1 rimuove eventuali "." che impediscono di passare più di 4 valori alla thousandPipe
	 * 2 setta il valore restituito dalla thousandPipe al campo del form
	 * 3 aggiorna l'oggetto eurConvertedValues
	 *
	 * @param field la proprietà passata anche al formControlName
	 */
	onType(field) {
		if (field.type === 'number') {
			let value = this.form.value[field.name];
			value = value.replace(/\./g, '');
			this.form.patchValue({ [field.name]: this.thousandPipe.transform(value) });
			this.eurConvertedValues[field.name] = this.utilService.getEurConvertedValue(value, +this.currency.eurRate);
		}
	}

	/**
	 * Restituisce i valori del form in un oggetto identico a quello accettato in @Input() set brand. Per i campi aventi type=number
	 * (vedere SurveyFieldModel), manipolati in ingresso e in modifica dalla thousandPipe:
	 *
	 * 1 vengono eliminati eventuali "."
	 * 2 viene ritrasformata la stringa in un numero
	 *
	 */
	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = JSON.parse(JSON.stringify(this.brand.survey_form));
		for (const field of saveForm) {
			if (field.type === 'number') {
				field.value = parseInt(formModel[field.name].replace(/\./g, ''), null);
			} else if (field.type === 'file') {
				field.extra_file = [
					{
						name: formModel[field.name].name,
						data: formModel[field.name].data
					}
				];
				field.value = null;
			} else {
				field.value = formModel[field.name];
			}
		}
		return saveForm;
	}
	onFormSubmit() {
		if (this.form.valid) {
			const brand: BrandItemModel = JSON.parse(JSON.stringify(this.brand));
			brand.survey_form = this.prepareSaveForm();
			this.onSaveSurveyForm.emit(brand);
		}
	}
	// util
	checkDisableForm(disable: boolean) {
		if (this.form) {
			disable ? this.form.disable() : this.form.enable();
		}
	}
}
