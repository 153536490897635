import { Injectable } from '@angular/core';

// model
import { BaseStateModel } from '../../model/state/base-state.model';
import { ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';

// pouch instance
import { PouchDbCommonsOrganizationAdapter } from '../pouchdb/instance/commons/pouchdb-commons-organization.service';
import { PouchAdapterEnum } from '../../enum/pouch-adapter.enum';

@Injectable({
	providedIn: 'root'
})
export class PouchUtilService {
    constructor(
		private pouchDbCommonsOrganizationAdapter: PouchDbCommonsOrganizationAdapter
	) {

	}

	async allDocs<D>(opts, adapter: PouchAdapterEnum):
	Promise<BaseStateModel<D[]>> {
		try {
			const view: ListViewDataPouchModel<D> = await this[adapter].basePouch.allDocs<D>(opts);
			const actionReturn = <BaseStateModel<D[]>> {
				data: this.mapAllDocsResponse(view)
			};
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	// widget & utility

	// TODO: modificare tipizzazione any una volta generalizzato ListViewDataPouchModel
	// in modo possa restituire anche doc
	mapAllDocsResponse<D>(view: any): any[] {
		return view.rows.map(i => i.doc);
	}
}
