<breadcrumb-project-instance
	title="
		{{ requestConfiguration.name }}
		{{
		ticket && ticket.request && ticket.request.company && ticket.request.company.client_code
			? ' - ' + ticket.request.company.client_code
			: ''
	}}
		{{
		ticket && ticket.request && ticket.request.company && ticket.request.company.business_name
			? ' | ' + ticket.request.company.business_name
			: ''
	}}
	"
	subtitle="Home / {{ requestConfiguration.name }} / {{
		ticket && ticket._id ? (ticket._id | assetRequestIdFormatter | uppercase) : 'New'
	}}"
	[backButton]="true"
	[backButtonRoute]="backButtonRoute"
></breadcrumb-project-instance>

<!-- Header -->
<form [formGroup]="form">
	<!-- First row -->
	<div class="row header">
		<!-- Request user -->
		<div class="col-md-12" *ngIf="ticket.request?.user_owner?.last_name">
			<mat-form-field>
				<input matInput [disabled]="true" [value]="ticket.request.user_owner.last_name" placeholder="Owner" />
			</mat-form-field>
		</div>

		<!-- Request info -->
		<div class="col-md-3">
			<mat-form-field>
				<input
					matInput
					[matDatepicker]="pickerDateRequest"
					formControlName="date_request"
					placeholder="Requested date"
				/>
				<mat-datepicker-toggle matSuffix [for]="pickerDateRequest"></mat-datepicker-toggle>
				<mat-datepicker #pickerDateRequest></mat-datepicker>
			</mat-form-field>
		</div>

		<div class="col-md-3">
			<mat-form-field>
				<input
					matInput
					formControlName="date_due"
					placeholder="Due date"
					[matDatepicker]="pickerDueDate"
					[min]="requestConfiguration.pageDetail.headerField.date_due.minDate"
					[matDatepickerFilter]="requestConfiguration.pageDetail.headerField.date_due.filterDate"
				/>
				<mat-datepicker-toggle matSuffix [for]="pickerDueDate"></mat-datepicker-toggle>
				<mat-datepicker #pickerDueDate></mat-datepicker>
			</mat-form-field>
		</div>

		<div class="col-md-3" *ngIf="requestConfiguration.pageDetail.headerField.urgent.visible[userType]">
			<mat-checkbox formControlName="urgent" placeholder="Urgent">Tick here if the ticket is urgent</mat-checkbox>
		</div>

		<div class="col-md-3">
			<div
				class="chips-label mb-12"
				[ngClass]="
					assetRequestUtilService.getLabelClassfromStatusListRemap(
						requestConfiguration.status.filterRemap,
						userType,
						ticket.status
					)
				"
			>
				{{
					utilService.formatPlaceholder(
						assetRequestUtilService.getLabelfromStatusListRemap(
							requestConfiguration.status.filterRemap,
							userType,
							ticket.status
						)
					) | titlecase
				}}
			</div>
		</div>
	</div>

	<!-- Second row -->
	<div class="row header">
		<div class="col-md-3" *ngIf="requestType === requestCenterEnum.type.PRINT_PRODUCTION_FLOW">
			<mat-form-field>
				<mat-label>Subject</mat-label>
				<input
					matInput
					formControlName="subject"
					placeholder="Type here the request title"
					[maxlength]="subjectMaxLength"
				/>
			</mat-form-field>
		</div>

		<div
			class="col-md-3"
			*ngIf="
				user &&
				user.current_permission &&
				user.current_permission.context_application !== contextApplicationItemCodeEnum.B2B
			"
		>
			<mat-form-field>
				<input matInput formControlName="assignee" placeholder="Assignee" />
			</mat-form-field>
		</div>

		<!-- Created by -->
		<div
			class="col-md-3"
			*ngIf="
				ticket.request.user.username &&
				requestConfiguration.type === requestCenterEnum.type.IMAGE_VIDEO &&
				[contextApplicationItemCodeEnum.BACKOFFICE, contextApplicationItemCodeEnum.BACKOFFICE_ADMIN].includes(
					user.current_permission.context_application
				)
			"
		>
			<mat-form-field>
				<input matInput [disabled]="true" [value]="ticket.request.user.username" placeholder="Created by" />
			</mat-form-field>
		</div>
	</div>
</form>

<div class="row" *ngIf="ticket">
	<div *ngIf="statusChangeButtonListContainerVisible" class="col-md-3 order-md-2">
		<mat-card class="change-status">
			<button-list
				[configuration]="statusChangeButtonList"
				(onButtonClick)="changeTicketStatus($event)"
			></button-list>
		</mat-card>
	</div>
	<!-- Table -->
	<div
		[ngClass]="
			(userType === requestCenterEnum.User.type.COMPANY &&
				(ticket.status === requestCenterEnum.Status.base.DRAFT ||
					ticket.status === requestCenterEnum.Status.base.MORE_INFO_NEEDED)) ||
			userType === requestCenterEnum.User.type.EUROITALIA ||
			(userType === requestCenterEnum.User.type.AGENCY &&
				ticket.status === requestCenterEnum.Status.base.PENDING_AGENCY)
				? 'col-md-9'
				: 'col-md-12'
		"
		class="order-md-1"
	>
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					<button
						mat-raised-button
						color="accent"
						(click)="dialogDimensions()"
						*ngIf="
							(userType === requestCenterEnum.User.type.COMPANY &&
								(ticket.status === requestCenterEnum.Status.base.DRAFT ||
									ticket.status === requestCenterEnum.Status.base.MORE_INFO_NEEDED)) ||
							(userType === requestCenterEnum.User.type.EUROITALIA &&
								ticket.status === requestCenterEnum.Status.base.DRAFT)
						"
					>
						<mat-icon>add</mat-icon>
						Add asset to the ticket
					</button>
					<span class="flex-span"></span>
					<app-table-column-toggle
						[columns]="requestConfiguration.pageDetail.columnList[userType]"
						(emitter)="toggleColumn($event)"
					></app-table-column-toggle>
				</mat-toolbar-row>
			</mat-toolbar>

			<div *ngIf="ticket?.asset_list?.length > 0; else noDataFound">
				<td-data-table
					#dataTable
					[data]="ticket.asset_list"
					[columns]="requestConfiguration.pageDetail.columnList[userType]"
					[clickable]="true"
					(rowClick)="dialogDimensions($event)"
				>
					<ng-template tdDataTableTemplate="index" let-row="row">
						<span>{{ ticket.asset_list.indexOf(row) + 1 }}</span>
					</ng-template>

					<!-- <ng-template tdDataTableTemplate="note" let-row="row" let-value="value">
						<span [matTooltip]="value" *ngIf="value; else noComment">
							{{ value | shorten: 30 }}
						</span>
						<ng-template #noComment>
							<p class="placeholder">No comment</p>
						</ng-template>
					</ng-template> -->

					<!-- <ng-template tdDataTableTemplate="upload_frontoffice" let-row="row" let-value="value">
						<mat-icon *ngIf="value?.length">attach_file</mat-icon>
					</ng-template> -->

					<ng-template
						[tdDataTableTemplate]="requestCenterEnum.Asset.AttachmentType.AGENCY"
						let-row="row"
						let-value="value"
					>
						<mat-icon
							*ngIf="
								(userType === requestCenterEnum.User.type.COMPANY &&
									ticket.status === requestCenterEnum.Status.base.SENT_TO_CLIENT &&
									value?.length) ||
								((userType === requestCenterEnum.User.type.AGENCY ||
									userType === requestCenterEnum.User.type.EUROITALIA) &&
									value?.length)
							"
							class="txt-green"
							>check_circle</mat-icon
						>
					</ng-template>

					<ng-template tdDataTableTemplate="type" let-row="row" let-value="value">
						<span *ngIf="requestType === requestCenterEnum.type.PRINT_PRODUCTION_FLOW">
							{{
								requestCenterEnum.Asset.Type.PrintProductionFlow.ShortDescription[value.description]
									| titlecase
							}}
						</span>
						<mat-icon
							*ngIf="requestType === requestCenterEnum.type.IMAGE_VIDEO"
							[matTooltip]="utilService.formatPlaceholder(value.description) | titlecase"
						>
							{{ requestCenterEnum.Asset.icon[value.description] }}
						</mat-icon>
					</ng-template>
				</td-data-table>
			</div>
			<ng-template #noDataFound>
				<p class="placeholder">There are no assets</p>
			</ng-template>
		</mat-card>
	</div>
</div>

<!-- rocket chat iframe test -->
<div class="row" *ngIf="ticket.status !== requestCenterEnum.Status.base.DRAFT; else noThread">
	<div *ngFor="let item of chatList" class="col-md-6">
		<mat-card>
			<mat-toolbar>
				<span 
					class="spacer"
					>
					Message to
					{{ userType === requestCenterEnum.User.type.EUROITALIA ? item.side : ('Euroitalia' | titlecase) }}
				</span>
				<button
					mat-raised-button
					class="utility-button"
					(click)="generatePDF(item.side)"
			>
		<mat-icon class="download">file_download</mat-icon>
		<!-- <span>{{ 'general.export_xls' | translate | sentencecase }}</span> -->
		<span> {{'chat.export' | translate | sentencecase }}</span>
		</button>
			</mat-toolbar>
		
			<iframe
				*ngIf="item.chat"
				[attr.id]="item.chat._id"
				[src]="item.chat.url | safeDomSanitizer: 'resourceUrl'"
				title="myframe"
				width="100%"
				height="350px"
			>
			</iframe>
		</mat-card>
	</div>
</div>

<!-- <button (click)="loginWithKeycloak()">LOGIN</button>
<button (click)="goToGeneral()">GO TO GENERAL</button>
<button (click)="logout()">LOGOUT</button> -->

<ng-template #noThread>
	<p class="placeholder">
		You can send messages once the ticket has been sent
	</p>
</ng-template>

<!-- Chat OLD -->
<!-- <div class="row" *ngIf="ticket.status !== requestCenterEnum.Status.base.DRAFT; else noThread">
	<div *ngFor="let item of chatList" class="col-md-6">
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					Message to
					{{ userType === requestCenterEnum.User.type.EUROITALIA ? item.side : ('Euroitalia' | titlecase) }}
				</mat-toolbar-row>
			</mat-toolbar>
			<app-chat-thread
				[threadHeight]="300"
				[chat]="item.chat"
				(onReachTop)="loadChatPage(item, item.chat.thread.pagination.page_current + 1)"
				(onSendMessage)="onChatSendMessage($event, item)"
			></app-chat-thread>
		</mat-card>
	</div>
</div>
<ng-template #noThread>
	<p class="placeholder">
		You can send messages once the ticket has been sent
	</p>
</ng-template> -->