import { Injectable } from '@angular/core';

// model
import { ChatDetailStateFeatureSelectionParameterModel } from '../../../model/pouch/chat.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatDetailActionEnum, ChatDetailStateAction } from './chat-detail.actions';

// widget & utility
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChatFunctionService } from '../../../service/util/chat.service';

@Injectable()
export class ChatDetailEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatDetailActionEnum.LOAD),
			mergeMap((action: ChatDetailStateFeatureSelectionParameterModel) => from(this.chatFunctionService.load(action))),
			mergeMap((action: ChatDetailStateFeatureSelectionParameterModel) => from(this.chatFunctionService.loadMetaInfo(action))),
			map((action: ChatDetailStateFeatureSelectionParameterModel) => ChatDetailStateAction.loadMessageList(action)),
			catchError((error, caught) => {
				this.store.dispatch(
					ChatDetailStateAction.error({side: error.side})
				);
				return caught;
			})
		)
	);

	loadMessageList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatDetailActionEnum.LOAD_MESSAGE_LIST),
			mergeMap((action: ChatDetailStateFeatureSelectionParameterModel) => from(this.chatFunctionService.loadMessageFilteredList(action))),
			map((action: ChatDetailStateFeatureSelectionParameterModel) => ChatDetailStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(
					ChatDetailStateAction.error({side: error.side})
				);
				return caught;
			})
		)
	);

	updateRemoteLastAccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatDetailActionEnum.UPDATE_REMOTE_LAST_ACCESS),
			mergeMap((action: ChatDetailStateFeatureSelectionParameterModel) => from(this.chatFunctionService.putUserLastAccess(action))),
			map((action: ChatDetailStateFeatureSelectionParameterModel) => ChatDetailStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(
					ChatDetailStateAction.error({side: error.side})
				);
				return caught;
			})
		)
	);

	closeConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatDetailActionEnum.CLOSE_CONNECTION),
			map((action: ChatDetailStateFeatureSelectionParameterModel) => {
				this.chatFunctionService.cancelListener(action.chat._id);
				return ChatDetailStateAction.reset(action);
			}),
			catchError((error, caught) => {
				this.store.dispatch(
					ChatDetailStateAction.error({side: error.side})
				);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private chatFunctionService: ChatFunctionService,
		private store: Store<any>
	) {

	}

}
