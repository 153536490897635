import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CommercialAreasService } from "../util/commercial-areas.service";
import { CommercialAreaModel } from '../../model/commercial-area.model';

@Injectable()
export class CommercialAreasResolver implements Resolve<CommercialAreaModel[]> {
  constructor(private commercialAreasService: CommercialAreasService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<CommercialAreaModel[]> {
    return this.commercialAreasService.getCommercialAreas();
  }
}
