<div class="loading-overlay" [class.show]="isLoading">
  <ng-container *ngIf="type==='spinner'; else progress">
    <div fxFlex="100%" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  

  <ng-template #progress>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>
